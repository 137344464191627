<!--报警管理-->
<template>
  <div class="bg01">
    <Head title="报警管理" :notShowLeft="true" :showLeftArr="options" @handleHead="handleHead"></Head>
    <list-box ref="listBox" @load="load" class="fixed" :contentLength="list.length">
      <li class="h1 fixed">
        <el-button size="mini" @click="drawer = true" class="btn04">
          搜索
        </el-button>
      </li>
      <li v-for="(v, i) in list" :key="i">
        <div class="h1">
          <div style="width: 60%;"><b>{{ v.Name }}</b></div>
          <div>编号：{{ v.MN.indexOf('A') === -1 ? v.MN : v.MN.substr(16, 8) }}</div>
        </div>
        <div class="h1">
          <div style="width: 70%;">
            {{ v.Addr }}
            <a :href="`tel:${v.CustomerMobile}`" style="display: inline-block; margin-left: 5px;"><i
                class="el-icon-phone color009999"></i></a>
            <a style="display: inline-block; margin-left: 5px;" @click="handleMap(v)"><i
                class="el-icon-location color009999"></i></a>
          </div>
          <div>油烟健康码 <em class="icon01" :class="v.HealthCodeColor | dialogStatus"></em></div>
        </div>
        <el-row class="h2" v-if="type === 2">
          <el-col :span="6">超标天数
            <div>{{ v.ExcessNum }}</div>
          </el-col>
          <el-col :span="7">异常离线天数
            <div>{{ v.AbnormalOfflineDays }}</div>
          </el-col>
          <el-col :span="10">净化器不正常使用天数
            <div>{{ v.FilterAbnormallyUsedDays }}</div>
          </el-col>
          <el-col :span="24">
            <el-divider></el-divider>
          </el-col>
          <el-col :span="8">昨日油烟折算浓度
            <div>{{ v.CEmissions | dataStr }}</div>
          </el-col>
          <el-col :span="8">昨日颗粒物折算浓度
            <div>{{ v.CGranule | dataStr }}</div>
          </el-col>
          <el-col :span="8">昨日非甲烷总烃折算浓度
            <div>{{ v.CHydrocarbon | dataStr }}</div>
          </el-col>
        </el-row>
        <el-row class="h2" v-else>
          <el-col :span="7">故障处理次数
            <div>{{ v.FaultDone }}</div>
          </el-col>
          <el-col :span="10">净化器不正常使用次数
            <div>{{ v.DownNum }}</div>
          </el-col>
          <el-col :span="7">异常待处理次数
            <div>{{ v.DownDone }}</div>
          </el-col>
          <el-col :span="24">
            <el-divider></el-divider>
          </el-col>
          <el-col :span="7">歇业天数
            <div>{{ v.StoppedBusinessDays }}</div>
          </el-col>
          <el-col :span="9">监控仪故障次数
            <div>{{ v.FaultNum1 + v.FaultNum2 + v.FaultNum3 + v.FaultNum4 }}</div>
          </el-col>
        </el-row>
        <div class="btn-group">
          <el-button plain size="mini" class="btn04"
                     @click="handleListDialog({LocaleId: v.LocaleId, typ: 1 , type: 0})">报警处理
          </el-button>
          <el-button plain size="mini" class="btn04"
                     @click="handleListDialog({LocaleId: v.LocaleId, typ: 2, type: 1})">异常离线处理
          </el-button>
          <!--              <el-button plain size="mini" class="btn04" @click="handleClean(v.MN)">清空</el-button>-->
          <el-button plain size="mini" class="btn04"
                     @click="handleDensityDialog({MN: v.MN, AcquitAtBegin: parseInt(param.Param.StartAt), AcquitAtEnd: parseInt(param.Param.EndAt)})"
                     v-if="type === 2">油烟浓度
          </el-button>
        </div>
      </li>
    </list-box>
    <el-drawer
        title="我是标题"
        :visible.sync="drawer"
        direction="ttb"
        :with-header="false">
      <el-form label-width="0px" class="formDrawer">
        <el-form-item>
          <el-input v-model="param.Param.mn" placeholder="编码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-cascader
              v-model="param.Param.ownerArr"
              placeholder="所属单位"
              :options="customerTree"
              :props="props"
              clearable
              filterable
              :key="key"
              @change="handleCascaderChange"
          />
        </el-form-item>
        <el-form-item>
          <el-select
              style="width: 100%;"
              placeholder="设备状态"
              v-model="param.Param.Status"
              clearable
          >
            <el-option label="正常" value="1"/>
            <el-option label="超标" value="2"/>
            <el-option label="正常离线" value="3"/>
            <el-option label="异常离线" value="4"/>
          </el-select>
        </el-form-item>
        <el-form-item class="flex">
          <el-date-picker
              v-model="StartAt"
              type="date"
              @change="handleStartAt"
              placeholder="选择日期">
          </el-date-picker>
          <div style="width: 40px; text-align: center;">至</div>
          <el-date-picker
              v-model="EndAt"
              type="date"
              @change="handleEndAt"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" class="btn04" style="width: 100%;" @click="handleSearch">
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </el-drawer>

    <Foot></Foot>

    <div id="map" style="height: 0;"></div>
    <alarm-echarts
        :chart-data="densityData"
        :show-dialog.sync="densityVisible"/>
    <alarm-list
        :list-data="listData"
        :show-dialog.sync="listVisible"
        @openForm="openForm"/>
    <alarm-form
        :formData="formData"
        :show-dialog.sync="formVisible"/>
  </div>
</template>

<script>
import Head from '@/components/Head';
import Foot from '@/components/Foot';
import ListBox from '@/components/ListBox';
import {handleJump, treeFilter} from "../utils/utils";
import {listCurAlarm, delAlarmRecord, listBasicDataOfCustomer} from "../utils/api";
import AlarmEcharts from "@/views/AlarmEcharts.vue";
import AlarmList from "@/views/AlarmList.vue";
import AlarmForm from "@/views/AlarmForm.vue";

export default {
  components: {AlarmForm, AlarmList, AlarmEcharts, Head, Foot, ListBox},
  data() {
    return {
      options: [
        {
          value: 2,
          label: '监测仪'
        },
        {
          value: 1,
          label: '监控仪'
        }
      ],
      type: 2,
      param: {
        StartAt: 0,
        Size: 10,
        Typ: 2,
        SortBy: 'status',
        SortMode: 'asc',
        Param: {
          StartAt: 0,
          EndAt: 0,
          key: undefined,
          Status: undefined,
          LocaleWithDevice: true,
        }
      },
      list: [],
      page: 1,
      marker: [],
      drawer: false,
      customerTree: [],
      props: {label: 'Org', checkStrictly: true, value: 'Id'},
      StartAt: '',
      EndAt: '',
      key: 0,
      densityVisible: false,
      densityData: {},
      listVisible: false,
      listData: {},
      formData: {},
      formVisible: false
    }
  },
  mounted() {
    if (!sessionStorage.getItem('customerTree')){
      listBasicDataOfCustomer().then(res => {
        const customerTree = treeFilter(res.Customer);
        this.customerTree = customerTree
        sessionStorage.setItem('customerTree', JSON.stringify(customerTree));
      })
    }else{
      this.customerTree = JSON.parse(sessionStorage.getItem('customerTree'))
    }
    this.getDate();
  },
  filters: {
    short(v) {
      return v.substr(16, 8)
    },
    dialogStatus(v) {
      let obj = {
        Green: 'type01',
        Red: 'type03',
        Yellow: 'type04',
      };
      return obj[v]
    },
    dataStr(v) {
      return v ? v : '-'
    }
  },
  methods: {
    handleHead(data) {
      this.type = data;
      this.param.Typ = data;
      this.getList();
    },
    handleJump,
    getList() {
      listCurAlarm(this.param).then(res => {
        this.list = this.page === 1 ? res.content : this.list.concat(res.content);
        this.$refs.listBox.total = res.total;
        this.$refs.listBox.loading = false
      }).catch(err => {
        console.log(err)
        this.$message.error(err.data.Msg)
      });
    },
    load() {
      if (!this.$refs.listBox.loading) {
        this.$refs.listBox.loading = true;
        setTimeout(() => {
          this.page++;
          this.param.StartAt = this.page * this.param.Size;
          this.getList();
        }, 200)
      }
    },
    handleClean(str) {
      this.$dialog.confirm({
        title: '提示',
        message: '确定清空所有报警吗?',
        showCancelButton: true
      }).then(() => {
        delAlarmRecord({mn: str}).then(res => {
          this.page = 1;
          this.param.StartAt = 0;
          this.getList();
        }).catch(err => {
          console.log(err)
        });
      }).catch(() => {
      })
    },
    handleMap(obj) {
      let arr = [];
      arr[0] = parseFloat(obj.LocaleLng);
      arr[1] = parseFloat(obj.LocaleLat);
      let map = new AMap.Map('map', {
        resizeEnable: true,
        center: arr,
        zoom: 13
      });
      this.marker = new AMap.Marker({
        position: arr,
        content: '',
        map: map
      });
      this.marker.markOnAMAP({
        name: obj.Name,
        position: arr
      })
    },
    onSearch(val) {
      this.page = 1;
      this.param.StartAt = this.page * this.param.Size;
      this.getList();
    },
    onCancel() {
      this.param.Param.mn = undefined;
      this.page = 1;
      this.param.StartAt = this.page * this.param.Size;
      this.getList();
    },
    getDate() {
      let today = new Date();
      today.setDate(today.getDate() - 1);
      this.EndAt = today.getTime();
      this.param.Param.EndAt = parseInt(this.EndAt / 1000);
      today.setDate(today.getDate() - 30);
      this.StartAt = today.getTime();
      this.param.Param.StartAt = parseInt(this.StartAt / 1000);
      this.getList();
    },
    handleStartAt() {
      let d = this.StartAt;
      this.param.Param.StartAt = d.getTime() / 1000;
      this.getList();
    },
    handleEndAt() {
      let d = this.EndAt;
      this.param.Param.EndAt = d.getTime() / 1000;
      this.getList();
    },
    handleSearch() {
      if (this.param.Param.ownerArr && this.param.Param.ownerArr.length > 0) {
        this.param.Param.Owner = '/' + this.param.Param.ownerArr.join('/') + '/';
      } else {
        delete this.param.Param.Owner
      }
      if (!this.param.Param.mn) {
        delete this.param.Param.mn
      }
      this.page = 1;
      this.param.StartAt = 0;
      // this.param.StartAt = this.page * this.param.Size;
      this.param.Param.Status = this.param.Param.Status ? Number(this.param.Param.Status) : undefined;
      this.getList();
      this.drawer = false;
    },
    handleCascaderChange() {
      if (!this.param.Param.ownerArr || this.param.Param.ownerArr.length === 0) {
        this.key++;
      }
    },
    handleDensityDialog(data) {
      this.densityData = data
      this.densityVisible = true
    },
    handleListDialog(data) {
      this.listData = data
      this.listVisible = true
    },
    openForm(data) {
      this.formData = data
      this.formVisible = true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.mod03 .el-input__icon {
  line-height: 31px;
}

.weui-search-bar {
  padding: 0 !important;
}

.formDrawer .el-form-item {
  margin-bottom: 7px;
}

.flex .el-form-item__content {
  display: flex;
}

.el-drawer {
  height: 250px !important;
}
</style>
