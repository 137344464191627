<!--我的里面的账户安全-->
<template>
  <div class="bg01">
    <Head title="账户安全"></Head>
    <wv-group>
      <wv-cell title="修改手机号" is-link @click.native="handleJump('/changePhone')"></wv-cell>
      <wv-cell title="修改密码" is-link @click.native="handleJump('/changePwd')"></wv-cell>
    </wv-group>
    <wv-group>
      <el-button class="btn06" @click="handleOut">退出登录</el-button>
    </wv-group>
  </div>
</template>

<script>
import Head from '@/components/Head';
import { handleJump } from "../utils/utils";

export default {
    components: { Head },
    data () {
      return {
        msg: 'Welcome to Your Vue.js App'
      }
    },
    mounted() {},
    methods: {
      handleJump,
      //点击退出，移除浏览器缓存的信息
      handleOut() {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('login');
        sessionStorage.removeItem('role');
        sessionStorage.removeItem('name');
        sessionStorage.clear();
        this.$cookies.remove('login')
        this.$cookies.remove('token')
        if (!this.$cookies.isKey('login') && !this.$cookies.isKey('token')) {
          this.$message.info('已退出登录！')
          var url = "login?time=" + new Date().getTime().toString()
          this.handleJump(url)
        } else {
          this.$message.error('退出登录失败！')
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .weui-cells{
    margin-top: 5px;
  }
</style>
