<template>
  <div>
    <el-row class="mod03">
      <el-col :span="24">
        <ul>
          <li><div id="myChartBar" :style="{width: '100%', height: '80vh'}"></div></li>
<!--          <li v-if="!isWidth">-->
<!--            <div id="myChartBar" :style="{width: '100%', height: '80vh'}"></div>-->
<!--          </li>-->
<!--          <li v-if="isWidth">-->
<!--            <div id="myChartTwo" :style="{width: '100%', height: '80vh'}"></div>-->
<!--          </li>-->
        </ul>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  data () {
    return {
      isWidth: false,
    }
  },
  mounted() {
    // window.addEventListener("resize", this.renderResize, false);
  },
  beforeDestroy () {
    // window.removeEventListener("resize", this.renderResize, false);
  },
  watch: {
    isWidth (v) {
      if (!v) location.reload();
    }
  },
  methods: {
    drawLine(arr, arr02, arr03, arr04, arr05, arr06, arr07){
      console.log('drawLine', arr, arr02, arr03, arr04, arr05, arr06, arr07);
      // arr = ['00:00', '01:15', '02:30', '03:45', '05:00', '06:15', '07:30', '08:45', '10:00', '11:15', '12:30', '13:45', '15:00', '16:15', '17:30', '18:45', '20:00', '21:15', '22:30', '23:45'];
      // arr02 = [300, 280, 250, 260, 270, 300, 550, 500, 400, 390, 380, 390, 400, 500, 600, 750, 800, 700, 600, 400];
      // arr04 = [300, 280, 250, 260, 270, 300, 550, 500, 400, 390, 380, 390, 400, 500, 600, 750, 800, 700, 600, 400];
      // arr06 = [300, 280, 250, 260, 270, 300, 550, 500, 400, 390, 380, 390, 400, 500, 600, 750, 800, 700, 600, 400];
      // console.log('drawLine', arr, arr02, arr03, arr04, arr05, arr06, arr07);
      let myChart = this.$echarts.init(document.getElementById('myChartBar'));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['油烟浓度', '颗粒物', '非甲烷总烃']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'value',
          name: '油烟浓度',
        },
          {
            type: 'value',
            name: '油烟平均',
          }],
        yAxis: {
          type: 'category',
          data: arr,
          name: '',
        },
        series: [
          {
            name: '油烟浓度',
            type: 'bar',
            data: arr02,
            itemStyle: {
              normal: {
                color: '#00A1FF',
                lineStyle: {
                  // type: 'solid',
                  color: '#00A1FF'
                }
              }
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#00A1FF",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  xAxis: arr03,
                },
              ],
            },
          },
          {
            name: '颗粒物',
            type: 'bar',
            data: arr04,
            itemStyle: {
              normal: {
                color: '#F5B049',
                lineStyle: {
                  // type: 'solid',
                  color: '#F5B049'
                }
              }
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#F5B049",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  xAxis: arr05,
                },
              ],
            },
          },
          {
            name: '非甲烷总烃',
            type: 'bar',
            data: arr06,
            itemStyle: {
              normal: {
                color: '#009999',
                lineStyle: {
                  // type: 'solid',
                  color: '#009999'
                }
              }
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#009999",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  xAxis: arr07,
                },
              ],
            },
          },
        ]
      });
    },
    renderResize(arr, arr02, arr03, arr04, arr05, arr06, arr07) {
      let width = document.documentElement.clientWidth;
      let height = document.documentElement.clientHeight;
      this.isWidth = width > height;
      console.log(this.isWidth);
      if (this.isWidth) {
        this.drawLineWidth(arr, arr02, arr03, arr04, arr05, arr06, arr07)
      } else {
        this.drawLine(arr, arr02, arr03, arr04, arr05, arr06, arr07);
      }
    },
    drawLineWidth(arr, arr02, arr03, arr04, arr05, arr06, arr07) {
      let myChart = this.$echarts.init(document.getElementById('myChartTwo'));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['油烟浓度', '颗粒物', '非甲烷总烃']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        yAxis: [{
          type: 'value',
          name: '油烟浓度',
        },
          {
            type: 'value',
            name: '油烟平均',
          }],
        xAxis: {
          type: 'category',
          data: arr02,
        },
        series: [
          {
            name: '油烟浓度',
            type: 'line',
            data: arr,
            itemStyle: {
              normal: {
                color: '#00A1FF',
                lineStyle: {
                  type: 'solid',
                  color: '#00A1FF'
                }
              }
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#00A1FF",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  xAxis: arr03,
                },
              ],
            },
          },
          {
            name: '颗粒物',
            type: 'line',
            data: arr04,
            itemStyle: {
              normal: {
                color: '#F5B049',
                lineStyle: {
                  type: 'solid',
                  color: '#F5B049'
                }
              }
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#F5B049",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  xAxis: arr05,
                },
              ],
            },
          },
          {
            name: '非甲烷总烃',
            type: 'line',
            data: arr06,
            itemStyle: {
              normal: {
                color: '#009999',
                lineStyle: {
                  type: 'solid',
                  color: '#009999'
                }
              }
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#009999",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  xAxis: arr07,
                },
              ],
            },
          },
        ]
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .mod03{
    height: calc(80vh - 48px);
  }
</style>
