<!--数据统计-信息勘察-新增-->
<template>
  <div class="bg01">
<!--    绑定这个头部 根据-->
    <Head :title="getTitle" :notshowUpload="true"></Head>
    <el-form
      :model="formData"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      label-position="left"
      class="form02"
      :disabled="type === 'LOOK'"
    >
      <el-form-item label="餐饮企业名称" prop="Customer.Name">
        <el-input
          placeholder="请输入"
          v-model="formData.Customer.Name"
        ></el-input>
      </el-form-item>

      <el-form-item label="负责人" prop="Auditor">
        <el-input placeholder="请输入" v-model="formData.Auditor"></el-input>
      </el-form-item>

      <el-form-item label="餐饮企业地址" prop="Customer.Address">
        <el-input
          placeholder="请输入"
          v-model="formData.Customer.Address"
        ></el-input>
      </el-form-item>

      <el-form-item label="提交时间">
        <el-input v-model="formData.SubmitAt" disabled style="width: 85%;">
        </el-input>
      </el-form-item>

      <el-form-item label="上级单位">
        <el-cascader
          v-model="formData.Customer.Pid"
          :options="customerTree"
          :props="orgProps"
          @change="handlePidChange"
          clearable
          filterable
        />
      </el-form-item>

      <el-form-item label="维保人员" prop="Customer.MaintainerId">
        <el-input
          placeholder="请输入"
          v-model="formData.Customer.MaintainerId"
        ></el-input>
      </el-form-item>

      <el-form-item label="联系人" prop="Customer.Contact">
        <el-input
          placeholder="请输入"
          v-model="formData.Customer.Contact"
        ></el-input>
      </el-form-item>

      <el-form-item label="联系电话" prop="Customer.Mobile">
        <el-input
          placeholder="请输入"
          v-model="formData.Customer.Mobile"
        ></el-input>
      </el-form-item>

      <el-form-item label="固定电话" prop="Customer.Telephone">
        <el-input
          placeholder="请输入"
          v-model="formData.Customer.Telephone"
        ></el-input>
      </el-form-item>

      <el-form-item label="营业执照号" prop="Customer.BlNo">
        <el-input
          placeholder="请输入"
          v-model="formData.Customer.BlNo"
        ></el-input>
      </el-form-item>

      <el-form-item label="营业执照名称" prop="Customer.BlName">
        <el-input
          placeholder="请输入"
          v-model="formData.Customer.BlName"
        ></el-input>
      </el-form-item>

      <el-form-item label="风机功率" prop="FanPower">
        <el-input placeholder="请输入" v-model="formData.FanPower"></el-input>
      </el-form-item>

      <el-form-item label="净化器功率" prop="FilterPower">
        <el-input
          placeholder="请输入"
          v-model="formData.FilterPower"
        ></el-input>
      </el-form-item>

      <el-form-item label="是否有清洗检测位置及平台：" prop="HasCleanerMonitor">
        <el-radio v-model="formData.HasCleanerMonitor" :label="1">有</el-radio>
        <el-radio v-model="formData.HasCleanerMonitor" :label="2">无</el-radio>
      </el-form-item>

      <el-form-item label="风机数采仪接线电压：" prop="FanVoltage">
        <el-radio v-model="formData.FanVoltage" :label="220">220V</el-radio>
        <el-radio v-model="formData.FanVoltage" :label="380">380V</el-radio>
      </el-form-item>

      <el-form-item label="是否安装油烟净化器设备：" prop="HasFilter">
        <el-radio v-model="formData.HasFilter" :label="1">是</el-radio>
        <el-radio v-model="formData.HasFilter" :label="2">否</el-radio>
      </el-form-item>

      <el-form-item label="净化器是否失效：" prop="FilterDisable">
        <el-radio v-model="formData.FilterDisable" :label="1">是</el-radio>
        <el-radio v-model="formData.FilterDisable" :label="2">否</el-radio>
      </el-form-item>

      <el-form-item
        label="净化器处理风量是否与实际需求匹配："
        prop="IsFilterVolumeMatched"
      >
        <el-radio v-model="formData.IsFilterVolumeMatched" :label="1"
          >是</el-radio
        >
        <el-radio v-model="formData.IsFilterVolumeMatched" :label="2"
          >否</el-radio
        >
      </el-form-item>

      <el-form-item
        label-width="110px"
        label="是否存在其他问题"
        prop="Question"
      >
        <el-input
          type="textarea"
          autosize
          placeholder="请输入内容"
          v-model="formData.Question"
        >
        </el-input>
      </el-form-item>

      <el-form-item label-width="0">
        <el-button
          class="btn01"
          type="primary"
          @click="submitForm()"
          v-if="type !== 'LOOK'"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Head from "@/components/Head";
import { getPname, join, toIntArray } from "../utils/utils";
import { listSubmitter, saveCustomerDetail } from "../utils/api";
import { mapState } from "vuex";

export default {
  name: "InfoInvestigationForm",
  components: {
    Head
  },
  data() {
    return {
      type: "",
      formData: {
        Customer: {}
      },
      rules: {
        "Customer.Name": [
          { required: true, message: "该项是必填项", trigger: "blur" }
        ],
        "Customer.Org": [
          { required: true, message: "该项是必填项", trigger: "blur" }
        ],
        "Customer.Address": [
          { required: true, message: "该项是必填项", trigger: "blur" }
        ],
        "Customer.Pid": [
          { required: true, message: "该项是必填项", trigger: "blur" }
        ],
        "Customer.MaintainerId": [
          { required: true, message: "该项是必填项", trigger: "blur" }
        ],
        FanPower: [
          { required: true, message: "该项是必填项", trigger: "blur" }
        ],
        FilterPower: [
          { required: true, message: "该项是必填项", trigger: "blur" }
        ],
        HasCleanerMonitor: [
          { required: true, message: "该项是必填项", trigger: "blur" }
        ],
        FanVoltage: [
          { required: true, message: "该项是必填项", trigger: "blur" }
        ],
        HasFilter: [],
        FilterDisable: [],
        IsFilterVolumeMatched: [],
        Question: []
      },
      errorMsg: "",
      customerTree: [],
      changeState: false,
      scrollY: 0,
      formIndex: 0,
      ownerTabs: false,
      userList: []
    };
  },
  mounted() {
    listSubmitter().then(res => {
      this.userList = res.content.slice();//将结果的内容通过slice()方法截取深拷贝给userList属性
    });
    this.customerTree = JSON.parse(sessionStorage.getItem("customerTree"));
    this.type = this.$route.params["type"];//从路由参数中获取type，根据type类型不同，执行不同的操作
    if (this.type === "ADD") {
      this.formData = { ...this.defaultFormData };
      this.getToday();
    } else {
      this.formData = { ...this.$route.params["formData"] };
      this.formData.SubmitAt = this.formData.SubmitAt.slice(0, 10);
    }
  },
  computed: {
    ...mapState(["props"]),//引入vuex中的mapState方法，通过数组形式引入props属性，通过this.props获取
    //以对象形式使用mapState，每个键值对表示一个计算属性及其生成逻辑。键是计算属性名（如multiProps、selAreaProps、orgProps），值是一个函数，该函数接收store的state作为参数，并返回一个新的对象
    ...mapState({
      multiProps: state =>
        Object.assign({}, state.props, { multiple: true, checkStrictly: true }),
      selAreaProps: state =>
        Object.assign({}, state.props, { checkStrictly: true }),
      orgProps: state =>
        Object.assign({}, state.props, { label: "Org", checkStrictly: true })
    }),
    //获取默认的表单数据时
    defaultFormData() {
      return {
        Customer: {}
      };
    },
    //头部的方法  根据类型返回标题
    getTitle() {
      switch (this.type) {
        case "ADD":
          return "新增信息勘查表";
        case "EDIT":
          return "修改信息勘查表";
        default:
          return "信息勘查表表单";
      }
    }
  },
  methods: {
    handlePidChange(v) {
      if (Array.isArray(v) && this.formData.Id === v[v.length - 1]) {
        this.$message.warning(`选择错误，请重新选择`);
        this.formData.Customer.Pid = [];
      }
    },
    //将当前的日期设置为表单中提交的日期
    getToday() {
      this.formData.SubmitAt = new Date()//获取当前的日期
        .toLocaleDateString()//这样获取到的是 年/月/日
        .replaceAll("/", "-");//将其中的/替换成-
    },

    subtypeFormatter(subtype) {
      switch (subtype) {
        case 1:
          return "风机设备";
        case 2:
          return "净化设备";
        case 3:
          return "检测仪设备";
        default:
          return "未知类型";
      }
    },
    customerFormatter(val) {
      return getPname(this.customerTree, val, "Org").join("");
    },
    //提交表单
    submitForm() {
      this.$refs["ruleForm"].validate(valid => {//this.$refs 是 Vue 中用于访问模板中已注册 ref 名称的元素或组件的引用对象
//validate 方法接受一个回调函数作为参数，该函数接收一个参数 valid，表示表单验证的结果（布尔值）。当所有表单规则通过验证时，valid 为 true；否则为 false
        if (valid) {
          const payload = { ...this.formData };//通过...语法将 formData 对象的属性拷贝到一个新的对象中
          payload.Customer.Pid = join(payload.Customer.Pid || []);
          saveCustomerDetail(payload)
            .then(res => {
              this.$message({
                type: "success",
                message: res
              });
              this.$router.back();
            })
            .catch(e => {
              this.errorMsg = e;
            });
        } else {
          this.$message({
            type: "warning",
            message: "输入有误，请重新输入"
          });
          return false;
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.bgc-anime {
  animation-name: box-bgc;
  animation-duration: 2s;
  animation-timing-function: linear;
}
@keyframes box-bgc {
  0% {
    background-color: #fff;
  }
  25% {
    background-color: #d7d7d7;
  }
  50% {
    background-color: #fff;
  }
  75% {
    background-color: #d7d7d7;
  }
  100% {
    background-color: #fff;
  }
}
.tabs-wrapper {
  padding: 20px 10px;
}
.tabs-content {
  margin-top: 20px;
  height: 240px;
  overflow: scroll;
  .item {
    line-height: 40px;
    font-size: 14px;
  }
  .select {
    color: #009999;
    font-weight: bold;
  }
}
</style>
