<!--我的-油烟健康码-->
<template>
  <div class="bg01">
    <Head title="油烟健康码"></Head>
    <el-row class="mod09" type="flex" justify="center">
      <el-col :span="12" class="box">
<!--        生成二维码的组件vue-qr -->
        <vue-qr :text="downloadData.url" :margin="0" :size="200"></vue-qr>
      </el-col>
    </el-row>
<!--    <el-button @click="handleJump(url)">默认按钮</el-button>-->
  </div>
</template>

<script>
  import Head from '@/components/Head'
  import vueQr from 'vue-qr'
  import { handleJump } from "../utils/utils";

  export default {
    components: { Head, vueQr },
    data () {
      return {
        msg: 'Welcome to Your Vue.js App',
        name: sessionStorage.getItem('name'),
        downloadData: {
          url: 'https://m.hzmingrui.cn',
        },
        url: '',
      }
    },
    mounted() {
      this.url = 'qrcodeList/'+sessionStorage.getItem('Owner');
      this.downloadData.url = this.downloadData.url + '/#/' + this.url
      console.log(this.downloadData.url)
    },
    methods: {
      handleJump
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .mod09{
    height: calc(100vh - 48px);
  }
  .mod09 .box{
    display: flex;
    align-items: center;
  }
</style>
