<template>
  <div class="bg01">
    <Head title="分钟采集数据" v-if="!isDialog"></Head>
    <el-row class="mod03">
      <el-col :span="24">
        <ul>
          <li v-if="!isWidth">
            <div class="h3" v-if="!isDialog">
              <el-date-picker
                v-model="date"
                type="date"
                @change="handleChange"
                placeholder="选择日期">
              </el-date-picker>
            </div>
            <div class="h3" v-else>时间：{{formatDate(titleDate)}}</div>
          </li>
          <li v-if="!isWidth">
            <div id="myChart" :style="{width: '100%', height: '100vh'}"></div>
          </li>
          <li v-if="isWidth">
            <div id="myChartTwo" :style="{width: '100%', height: '100vh'}"></div>
          </li>
        </ul>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Head from '@/components/Head';
import { listTenMinData } from "../utils/api";
import { formatDate } from "../utils/utils";
import { echartsPubSub } from "@/service/pubsub/index";

export default {
  components: { Head },
  data () {
    return {
      msg: 'Welcome to Your Vue.js App',
      titleDate: 0,
      emissionsSill: 0,
      granuleSill: 0,
      hydrocarbonSill: 0,
      date: '',
      param: {},
      isWidth: false,
      isDialog: false,
    }
  },
  mounted() {
    window.addEventListener("resize", this.renderResize, false);
    if (this.$route.params.data){
      let obj = JSON.parse(this.$route.params.data);
      this.titleDate = obj.at;
      this.emissionsSill = obj.emissionsSill;
      this.granuleSill = obj.granuleSill;
      this.hydrocarbonSill = obj.hydrocarbonSill;
      this.date = formatDate(obj.at);
      this.param = {MN: obj.mn, At: obj.at};
      // this.getData();
      // this.getData({MN: '010000A8900016F018010116', At: 1577751000});
      this.renderResize()
    } else {
      echartsPubSub.unsubscribe();
      echartsPubSub.subscribe(this.renderResize);
      this.isDialog = true;
    }

  },
  beforeDestroy () {
    window.removeEventListener("resize", this.renderResize, false);
  },
  watch: {
    isWidth (v) {
      if (!v) location.reload();
    }
  },
  methods: {
    formatDate,
    getData() {
      listTenMinData(this.param).then(res => {
        let arr = [], arr02 = [], arr03, arr04 = [], arr05, arr06 = [], arr07;
        const endTime = Math.floor(this.param.At / 600) + 143;
        arr03 = this.emissionsSill;
        arr05 = this.granuleSill;
        arr07 = this.hydrocarbonSill;
        for (var i = Math.floor(this.param.At / 600); i <= endTime; i++) {
          let obj = {
            emissions_conc: 0,
            granule_conc: 0,
            hydrocarbon_conc: 0,
            counter: 1,
            acquit_at: i,
          };
          res.forEach(v=>{
            if (v.acquit_at === i) {
              obj = v;
            }
            let date = formatDate(obj.acquit_at * 600);
            date = date.slice(11, date.length);
            arr02.push(date);
            arr.push((obj.emissions_conc).toFixed(2));
            arr04.push((obj.granule_conc).toFixed(2));
            arr06.push((obj.hydrocarbon_conc).toFixed(2));
          });
        }

        if (this.isWidth) {
          this.drawLineWidth(arr,arr02, arr03, arr04, arr05, arr06, arr07)
        } else {
          this.drawLine(arr,arr02, arr03, arr04, arr05, arr06, arr07);
        }
      }).catch(err => {
        console.log(err);
      });
    },
    drawLine(arr, arr02, arr03, arr04, arr05, arr06, arr07){
      // console.log(arr, arr02, arr03, arr04, arr05, arr06, arr07);
      let myChart = this.$echarts.init(document.getElementById('myChart'));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['油烟浓度', '颗粒物', '非甲烷总烃']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'value',
          name: '油烟浓度',
        },
          {
            type: 'value',
            name: '油烟平均',
          }],
        yAxis: {
          type: 'category',
          data: arr02,
        },
        series: [
          {
            name: '油烟浓度',
            type: 'line',
            data: arr,
            itemStyle: {
              normal: {
                color: '#00A1FF',
                lineStyle: {
                  type: 'solid',
                  color: '#00A1FF'
                }
              }
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#00A1FF",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  xAxis: arr03,
                },
              ],
            },
          },
          {
            name: '颗粒物',
            type: 'line',
            data: arr04,
            itemStyle: {
              normal: {
                color: '#F5B049',
                lineStyle: {
                  type: 'solid',
                  color: '#F5B049'
                }
              }
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#F5B049",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  xAxis: arr05,
                },
              ],
            },
          },
          {
            name: '非甲烷总烃',
            type: 'line',
            data: arr06,
            itemStyle: {
              normal: {
                color: '#009999',
                lineStyle: {
                  type: 'solid',
                  color: '#009999'
                }
              }
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#009999",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  xAxis: arr07,
                },
              ],
            },
          },
        ]
      });
    },
    handleChange() {
      this.param.At = this.date.getTime() / 1000;
      this.getData();
    },
    renderResize(obj) {
      if (obj) {
        this.titleDate = obj.at;
        this.emissionsSill = obj.emissionsSill;
        this.granuleSill = obj.granuleSill;
        this.hydrocarbonSill = obj.hydrocarbonSill;
        this.index = obj.i;
        this.date = formatDate(obj.at);
        this.param = {MN: obj.mn, At: obj.at};
      }
      let width = document.documentElement.clientWidth;
      let height = document.documentElement.clientHeight;
      this.isWidth = width > height;
      this.getData()
      // console.log(width > height);
    },
    drawLineWidth(arr, arr02, arr03, arr04, arr05, arr06, arr07) {
      let myChart = this.$echarts.init(document.getElementById('myChartTwo'));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['油烟浓度', '颗粒物', '非甲烷总烃']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        yAxis: [{
          type: 'value',
          name: '油烟浓度',
        },
          {
            type: 'value',
            name: '油烟平均',
          }],
        xAxis: {
          type: 'category',
          data: arr02,
        },
        series: [
          {
            name: '油烟浓度',
            type: 'line',
            data: arr,
            itemStyle: {
              normal: {
                color: '#00A1FF',
                lineStyle: {
                  type: 'solid',
                  color: '#00A1FF'
                }
              }
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#00A1FF",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  xAxis: arr03,
                },
              ],
            },
          },
          {
            name: '颗粒物',
            type: 'line',
            data: arr04,
            itemStyle: {
              normal: {
                color: '#F5B049',
                lineStyle: {
                  type: 'solid',
                  color: '#F5B049'
                }
              }
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#F5B049",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  xAxis: arr05,
                },
              ],
            },
          },
          {
            name: '非甲烷总烃',
            type: 'line',
            data: arr06,
            itemStyle: {
              normal: {
                color: '#009999',
                lineStyle: {
                  type: 'solid',
                  color: '#009999'
                }
              }
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#009999",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  xAxis: arr07,
                },
              ],
            },
          },
        ]
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .mod03{
    height: calc(100vh - 48px);
  }
</style>
