<template>
  <div>
    <el-dialog
      title="实时"
      :visible.sync="dialogVisible"
      width="100%"
      top="0px"
      @close="handleClose"
      center>
      <realTimeMinute></realTimeMinute>
    </el-dialog>
  </div>
</template>

<script>
import showDialog from '@/minxs/showDialog';
import RealTimeMinute from '../views/RealTimeMinute';
import { minutePubSub } from "@/service/pubsub/index";

export default {
  components: { RealTimeMinute },
  data () {
    return {
      msg: 'Welcome to Your Vue.js App',
      value: ''
    }
  },
  mounted() {
    minutePubSub.unsubscribe();
    minutePubSub.subscribe(this.getList);
  },
  mixins:[showDialog],
  methods: {
    getList(data) {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
