<!--数据统计-油烟减排统计-->
<template>
  <div class="bg01">
    <Head title="油烟减排统计"></Head>
    <list-box ref="listBox" @load="load">
      <li class="h1">
        <el-date-picker
          v-model="StartAt"
          type="date"
          @change="handleStartAt"
          placeholder="选择日期">
        </el-date-picker>
        <div style="width: 40px; text-align: center;">至</div>
        <el-date-picker
          v-model="EndAt"
          type="date"
          @change="handleEndAt"
          placeholder="选择日期">
        </el-date-picker>
      </li>
      <li v-for="(v, i) in list" :key="i">
        <div class="h1">
          <div>设备编码：{{v.Name | short}}</div>
        </div>
        <el-row class="h2">
          <el-col :span="12">PM2.5减排量</el-col>
          <el-col :span="12">PM10减排量</el-col>
          <el-col :span="12">{{v.RedPm25}}</el-col>
          <el-col :span="12">{{v.RedPm10}}</el-col>
          <el-col :span="24"><el-divider></el-divider></el-col>
          <el-col :span="12">油烟减排量</el-col>
          <el-col :span="12">VOCS减排量</el-col>
          <el-col :span="12">{{v.RedEmissions}}</el-col>
          <el-col :span="12">{{v.RedVocs}}</el-col>
        </el-row>
      </li>
    </list-box>
  </div>
</template>

<script>
import Head from '@/components/Head';
import ListBox from '@/components/ListBox';
import { listDetectorDaily } from "../utils/api";

export default {
  components: { Head, ListBox },
  data () {
    return {
      msg: 'Welcome to Your Vue.js App',
      StartAt: '',
      EndAt: '',
      param: {
        StartAt: 0,
        Size: 10,
        Param: {
          startAt: '',
          endAt: '',
          // Status: 'Normal'
        },
      },
      list: [],
      page: 1,
    }
  },
  mounted() {
    this.getDate();
  },
  filters: {
    short(v) {
      return v.substr(16, 8)
    },
  },
  methods: {
    getDate() {
      let today = new Date();
      today.setDate(today.getDate() - 1);
      this.EndAt = today.getTime();
      this.param.Param.endAt = parseInt(this.EndAt / 1000);
      today.setDate(today.getDate() - 30);
      this.StartAt = today.getTime();
      this.param.Param.startAt = parseInt(this.StartAt / 1000);
      this.getList();
    },
    getList() {
      listDetectorDaily(this.param).then(res => {
        this.list = this.page === 1 ? res.content : this.list.concat(res.content);
        this.$refs.listBox.total = res.total;
      }).catch(err => {
        console.log(err)
      });
    },
    load() {
      this.$refs.listBox.loading = true;
      setTimeout(() => {
        this.page++;
        this.param.StartAt = this.page * this.param.Size;
        this.getList();
        this.$refs.listBox.loading = false
      }, 2000)
    },
    handleStartAt() {
      let d = this.StartAt;
      this.param.Param.startAt = d.getTime() / 1000;
      this.getList();
    },
    handleEndAt() {
      let d = this.EndAt;
      this.param.Param.endAt = d.getTime() / 1000;
      this.getList();
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .mod03{
    height: calc(100vh - 48px);
  }
</style>
