import pubsub from "pubsub-js";

const indexDetailKey = "indexDetail";// 首页弹窗详情
const indexMinuteKey = "indexMinute";// 首页弹窗分钟
const indexEchartsKey = "indexEcharts";// 首页弹窗曲线
const indexEchartsMonthKey = "indexEchartsMonth";// 首页弹窗曲线30
const messageKey = "message";// 消息通知

// 首页弹窗详情
export const detailPubSub = {
  publish: function(info) {
    return pubsub.publish(indexDetailKey, info);
  },
  subscribe: function(callback) {
    return pubsub.subscribe(indexDetailKey, function(key, msg) {
      // console.log("通知事件--" + key + JSON.stringify(msg));
      callback(msg);
    });
  },
  unsubscribe: function () {
    return pubsub.unsubscribe(indexDetailKey)
  }
};

// 首页弹窗分钟
export const minutePubSub = {
  publish: function(info) {
    return pubsub.publish(indexMinuteKey, info);
  },
  subscribe: function(callback) {
    return pubsub.subscribe(indexMinuteKey, function(key, msg) {
      // console.log("通知事件--" + key + JSON.stringify(msg));
      callback(msg);
    });
  },
  unsubscribe: function () {
    return pubsub.unsubscribe(indexMinuteKey)
  }
};

// 首页弹窗曲线
export const echartsPubSub = {
  publish: function(info) {
    return pubsub.publish(indexEchartsKey, info);
  },
  subscribe: function(callback) {
    return pubsub.subscribe(indexEchartsKey, function(key, msg) {
      // console.log("通知事件--" + key + JSON.stringify(msg));
      callback(msg);
    });
  },
  unsubscribe: function () {
    return pubsub.unsubscribe(indexEchartsKey)
  }
};

// 首页弹窗曲线30
export const echartsMonthPubSub = {
  publish: function(info) {
    console.log("通知事件--publish" + indexEchartsMonthKey + JSON.stringify(info));
    return pubsub.publish(indexEchartsMonthKey, info);
  },
  subscribe: function(callback) {
    return pubsub.subscribe(indexEchartsMonthKey, function(key, msg) {
      console.log("通知事件--subscribe" + key + JSON.stringify(msg));
      callback(msg);
    });
  },
  unsubscribe: function () {
    return pubsub.unsubscribe(indexEchartsMonthKey)
  }
};

// 消息通知
export const messagePubSub = {
  publish: function(info) {
    return pubsub.publish(messageKey, info);
  },
  subscribe: function(callback) {
    return pubsub.subscribe(messageKey, function(key, msg) {
      // console.log("通知事件--" + key + JSON.stringify(msg));
      callback(msg);
    });
  },
  unsubscribe: function () {
    return pubsub.unsubscribe(messageKey)
  }
};
