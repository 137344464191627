<template>
  <div>
    <el-dialog
      title="历史曲线"
      :visible.sync="dialogVisible"
      width="100%"
      top="0px"
      @close="handleClose"
      center>
      <realTimeEcharts ref="realTimeEcharts"></realTimeEcharts>
    </el-dialog>
  </div>
</template>

<script>
import showDialog from '@/minxs/showDialog';
import { queryDataDetectorDaily } from "../utils/api";
import { formatDate } from "../utils/utils";
import RealTimeEcharts from './RealTimeEchartsBar';
import { echartsMonthPubSub } from "@/service/pubsub/index";

export default {
  components: { RealTimeEcharts },
  data () {
    return {
      arr: [],
      arr02: [],
      arr03: undefined,
      arr04: [],
      arr05: undefined,
      arr06: [],
      arr07: undefined,
    }
  },
  mounted() {
    echartsMonthPubSub.unsubscribe();
    echartsMonthPubSub.subscribe(this.getList);
  },
  beforeDestroy() {
    this.arr = [];
    this.arr02 = [];
    this.arr03 = undefined;
    this.arr04 = [];
    this.arr05 = undefined;
    this.arr06 = [];
    this.arr07 = undefined;
  },
  mixins:[showDialog],
  methods: {
    getList(data) {
      // console.log('echarts30PubSub', data);
      let param = {};
      param.mn = data.mn;
      let today = new Date();
      today.setDate(today.getDate() - 1);
      let EndAt = today.getTime();
      param.AcquitAtEnd = parseInt(EndAt / 1000);
      today.setDate(today.getDate() - 30);
      let StartAt = today.getTime();
      param.AcquitAtBegin = parseInt(StartAt / 1000);
      this.arr = [];
      this.arr02 = [];
      this.arr03 = data.emissionsSill;
      this.arr04 = [];
      this.arr05 = data.granuleSill;
      this.arr06 = [];
      this.arr07 = data.hydrocarbonSill;
      queryDataDetectorDaily(param).then(res => {
        // console.log(res[0].AcquitAt)
        for(let i = 0; i < 30 ; i++){
          let date = res[0].AcquitAt - 24*60*60*i;
          let array = res.filter(v => {
            return v.AcquitAt === date
          });
          let day = formatDate(date);
          this.arr.push(day.slice(0, 10));
          if (array.length > 0){
            this.arr02.push((array[0].Emissions).toFixed(2));
            this.arr04.push((array[0].Granule).toFixed(2));
            this.arr06.push((array[0].Hydrocarbon).toFixed(2));
          } else {
            this.arr02.push('0.00');
            this.arr04.push('0.00');
            this.arr06.push('0.00');
          }
        }
        this.$refs.realTimeEcharts.drawLine(this.arr, this.arr02, this.arr03, this.arr04, this.arr05, this.arr06, this.arr07);
      })
      // this.$refs.realTimeEcharts.drawLine(this.arr, this.arr02, this.arr03, this.arr04, this.arr05, this.arr06, this.arr07);
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
