<!--数据统计-信息勘察-->
<template>
  <div class="bg01">
    <Head title="信息勘查表上传" :notShowLeft="true"></Head>
    <list-box
      ref="listBox"
      @load="load"
      class="fixed"
      :contentLength="list.length"
    >
      <li class="h1 fixed">
        <el-button size="mini" @click="drawer = true" class="btn04">
          搜索
        </el-button>
        <el-button size="mini" class="btn04" @click="handleAdd">
          新增
        </el-button>
      </li>
      <li v-for="(v, i) in list" :key="i">
        <div class="h1">
          <div style="width: 100%;">
            <b>餐饮企业名称：{{ v.Customer.Name }}</b>
          </div>
        </div>
        <div class="h1">
          <div>监测点地址：{{ v.Customer.Address }}</div>
          <div>
            上传日期：{{ formatDate(v.Customer.CreateAt, true, false) }}
          </div>
        </div>
        <el-row class="h2">
          <el-col :span="12">联系人： {{ v.Customer.Contact }}</el-col>
          <el-col :span="12">联系电话： {{ v.Customer.Mobile }}</el-col>
          <el-col :span="24"
            >是否有清洗检测位置及平台：
            {{ cleanFormatter(v.HasCleanerMonitor) }}</el-col
          >
          <el-col :span="12"
            >上级单位： {{ customerFormatter(v.Owner) }}</el-col
          >
          <el-col :span="12">勘查人员： {{ v.Submitter }}</el-col>
          <el-col :span="24">审核状态： {{ resultFormatter(v.Result) }}</el-col>
        </el-row>

        <div class="h1">
          <el-button plain size="mini" class="btn04" @click="handleEdit(v, i)"
            >修改</el-button
          >
          <el-button plain size="mini" class="btn04" @click="handleLook(v, i)"
            >查看</el-button
          >
        </div>
      </li>
    </list-box>

    <el-drawer
      title="搜索"
      :visible.sync="drawer"
      direction="ttb"
      :with-header="false"
    >
      <el-form class="formDrawer">
        <el-row type="flex" justify="space-between">
          <el-col :span="8">
            <el-form-item>
              <el-select
                placeholder="审核状态"
                v-model="filter.param.Result"
                clearable
              >
                <el-option label="待审核" value="WAIT_TO_QUALIFY" />
                <el-option label="通过" value="QUALIFIED" />
                <el-option label="未通过" value="UNQUALIFIED" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item>
<!--            使用el组件选择日期  指定日期格式为"yyyy-MM-dd" -->
              <el-date-picker
                v-model="filter.param.SubmitDate"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                clearable
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button
            size="mini"
            class="btn04"
            style="width: 100%;"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
    <Foot></Foot>
    <div id="map" style="height: 0;"></div>
  </div>
</template>

<script>
import Head from "@/components/Head";
import Foot from "@/components/Foot";
import ListBox from "@/components/ListBox";
import {
  formatDate,
  join,
  getPname,
  handleJump,
  toIntArray
} from "../utils/utils";
import { listCustomerDetail, listMaintainer } from "../utils/api";
import { mapState } from "vuex";
import { Tab, Tabs, Cell, CellGroup, Popup, Icon } from "vant";

export default {
  name: "InfoInvestigation",
  components: {
    Head,
    Foot,
    ListBox,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Cell.name]: Cell,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [CellGroup.name]: CellGroup
  },
  data() {
    return {
      tabsData: [
        {
          Name: "请选择"
        }
      ],
      active: 0,
      tabList: [],
      levelCollect: [],
      drawer: false,
      filter: {
        StartAt: 0,
        Size: 10,
        Page: 1,
        param: {
          Result: "", //审核状态
          SubmitDate: null //日期
        }
      },
      //list:[],
      list: [],
      customerTree: [],
      props: { label: "Org", checkStrictly: true, value: "Id" },
      changeState: false,
      scrollY: 0,
      formIndex: 0,
      ownerTabs: false,
      maintainerList: []
    };
  },
  mounted() {
    this.customerTree = JSON.parse(sessionStorage.getItem("customerTree"));
    this.$store.commit(
      "setLocale",
      JSON.parse(sessionStorage.getItem("locale"))
    );
    this.getList();
  },
  computed: {
    ...mapState(["locale"]),
    ...mapState({
      customerProps: state =>
        Object.assign({}, state.props, { label: "Org", checkStrictly: true })
    })
  },
  methods: {
    handleJump,
    formatDate,
    getList(newSearch = false) {
      const payload = {
        StartAt: (this.filter.Page - 1) * this.filter.Size,
        Size: this.filter.Size,
        Param: {}
      };
      if (this.filter.param.Result) {
        payload.Param["result"] = { S: 0, V: `'${this.filter.param.Result}'` };
      }
      if (this.filter.param.SubmitDate) {
        payload.Param["submit_at"] = {
          S: 11,
          V: `${this.filter.param.SubmitDate / 1000},${this.filter.param
            .SubmitDate /
            1000 +
            24 * 3600}`
        };
      }
      Promise.all([listCustomerDetail(payload), listMaintainer()])
        .then(([res1, res2]) => {
          if (newSearch) {
            this.list = res1.content;
          } else {
            this.list = this.list.concat(res1.content);
          }
          this.$refs.listBox.total = res1.total;
          this.maintainerList = { ...res2.content };
        })
        .catch(err => {
          console.log(err);
        });
    },
    handleAdd() {
      this.$router.push({
        name: "info-investigation-form",
        params: { type: "ADD" }
      });
    },

    handleEdit(data, index) {
      this.$router.push({
        name: "info-investigation-form",
        params: {
          formData: {
            ...data,
            Customer:
              { ...data.Customer, Pid: toIntArray(data.Customer.Pid) } || {},
            Pid: toIntArray(data.Customer.Pid)
          },
          type: "EDIT"
        }
      });
    },
    handleLook(data, index) {
      this.$router.push({
        name: "info-investigation-form",
        params: {
          formData: {
            ...data,
            Customer:
              { ...data.Customer, Pid: toIntArray(data.Customer.Pid) } || {},
            Pid: toIntArray(data.Customer.Pid)
          },
          type: "LOOK"
        }
      });
    },

    resultFormatter(result) {
      switch (result) {
        case "QUALIFIED":
          return "通过";
        case "UNQUALIFIED":
          return "未通过";
        case "WAIT_TO_QUALIFY":
          return "待审核";
        default:
          return "";
      }
    },

    cleanFormatter(hasCleand) {
      switch (hasCleand) {
        case 1:
          return "是";
        case 2:
          return "否";
        default:
          return "";
      }
    },
//搜索功能
    handleSearch() {
      this.filter = {
        ...this.filter,
        timeAt: 0,
        Page: 1
      };
      this.getList(true);

      // this.list = this.list.filter((value) => value.Result === this.filter.Param.Result);

      // console.log(this.list)
      this.drawer = false;
    },

    load() {
      this.$refs.listBox.loading = true;
      setTimeout(() => {
        this.filter.StartAt += this.filter.Size;
        this.filter.Page++;
        this.getList();
        this.$refs.listBox.loading = false;
      }, 2000);
    },

    customerFormatter(val) {
      return getPname(this.customerTree, val, "Org").join("");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.bgc-anime {
  animation-name: box-bgc;
  animation-duration: 2s;
  animation-timing-function: linear;
}
@keyframes box-bgc {
  0% {
    background-color: #fff;
  }
  25% {
    background-color: #d7d7d7;
  }
  50% {
    background-color: #fff;
  }
  75% {
    background-color: #d7d7d7;
  }
  100% {
    background-color: #fff;
  }
}
.tabs-wrapper {
  padding: 20px 10px;
}
.tabs-content {
  margin-top: 20px;
  height: 240px;
  overflow: scroll;
  .item {
    line-height: 40px;
    font-size: 14px;
  }
  .select {
    color: #009999;
    font-weight: bold;
  }
}
</style>
