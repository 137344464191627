<!--数据统计-运维-新增-->
<template>
  <div>
    <Head title="新增运维单"></Head>
    <el-form
        style="width: 90%;margin: auto"
        ref="maintenanceForm"
        :model="formData"
        :rules="rules"
        label-width="150px"
        size="medium"
        label-position="left">
      <el-form-item label="所属单位"  prop="Owner">
        <el-cascader
            filterable
            clearable
            style="width: 100%"
            v-model="formData.Owner"
            :options="customerTree"
            :props="{label:'Name',key: 'Id',value: 'Id', expandTrigger: 'click'}"
            placeholder="请选择所属单位"
            @change="onOwnerConfirm"/>
      </el-form-item>
      <el-form-item label="监测点" prop="LocaleId">
        <el-select
            filterable
            clearable
            style="width: 100%"
            v-model="formData.LocaleId"
            placeholder="请选择监测点"
            @change="onLocaleConfirm"

        >
          <el-option
              v-for="item in localeList"
              :value="item.Id"
              :label="item.Name"
              :key="item.Id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="地址">
        <el-input style="width: 100%" v-model="formData.Addr" placeholder="请输入地址"/>
      </el-form-item>
      <el-form-item label="联系人">
        <el-input style="width: 100%" v-model="formData.Contact" placeholder="请输入联系人"/>
      </el-form-item>
      <el-form-item label="联系电话">
        <el-input style="width: 100%" v-model="formData.Mobile" placeholder="请输入联系电话"/>
      </el-form-item>
      <el-form-item label="维护设备" prop="DeviceMN">
        <el-select
            clearable
            style="width: 100%"
            v-model="formData.DeviceMN"
            placeholder="请选择维护设备"
            @change="onDeviceConfirm">
          <el-option
              v-for="item in devices"
              :value="item"
              :key="item.Id"
              :label="item.MN"/>
        </el-select>
      </el-form-item>
      <el-form-item label="维护类型" prop="Typ">
        <el-select
            clearable
            style="width: 100%"
            v-model="formData.Typ"
            placeholder="请选择维护类型">
          <el-option
              v-for="item in Typ"
              :value="item.value"
              :key="item.value"
              :label="item.label"/>
        </el-select>
      </el-form-item>
      <el-form-item label="新所属单位" v-if="formData.Typ === 2" prop="NewOwner">
        <el-cascader
            filterable
            clearable
            style="width: 100%"
            v-model="formData.NewOwner"
            :options="customerTree"
            :props="{label:'Name',key: 'Id',value: 'Id', expandTrigger: 'click'}"
            placeholder="请选择新所属单位"
            @change="onNewOwnerConfirm"/>
      </el-form-item>
      <el-form-item label="新监测点" v-if="formData.Typ === 2" prop="NewLocaleId">
        <el-select
            filterable
            clearable
            style="width: 100%"
            v-model="formData.NewLocaleId"
            placeholder="请选择新监测点">
          <el-option
              v-for="item in newLocaleList"
              :value="item.Id"
              :label="item.Name"
              :key="item.Id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="更换设备" v-if="formData.Typ === 9" prop="MN">
        <el-input style="width: 100%" v-model="formData.MN" placeholder="请输入新设备编号"/>
      </el-form-item>
      <el-form-item label="派单说明">
        <el-input style="width: 100%" v-model="formData.Remark" placeholder="请输入派单说明"/>
      </el-form-item>
      <el-form-item label="运维人员" prop="MaintainerName">
        <el-select
            style="width: 100%"
            v-model="formData.MaintainerName"
            placeholder="请选择运维人员"
            @change="onNewMaintainerConfirm">
          <el-option
              v-for="item in MaintainerList"
              :value="item"
              :key="item.Id"
              :label="item.Name"/>
        </el-select>
      </el-form-item>
      <el-form-item label="处理状态" prop="Status">
        <el-select
            style="width: 100%"
            v-model="formData.Status"
            placeholder="请选择处理状态">
          <el-option
              v-for="item in StatusList"
              :value="item.value"
              :key="item.value"
              :label="item.status"/>
        </el-select>
      </el-form-item>
      <el-form-item label="处理措施" v-if="formData.Status ===1" prop="Resolution">
        <el-input style="width: 100%" v-model="formData.Resolution" placeholder="请填写处理措施"/>
      </el-form-item>
      <el-form-item label="获取定位" v-if="formData.Status ===1" prop="Position">
        <div @click="getLocation" v-if="formData.Position ===''">点击获取定位</div>
        <div
            v-else
            @click="getLocation">
          {{ formData.Position }}
        </div>
      </el-form-item>
      <el-form-item label="文件上传" v-if="formData.Status ===1" prop="files">
        <el-upload
            ref="upload"
            action="https://m.yhtianmu.cn/uploadToOss"
            list-type="picture-card"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeImageChange"
        >
          <i class="el-icon-plus"></i>
          <div slot="file" slot-scope="{file}">
            <img
                :src="file.response.Data" alt=""
            >
            <span class="el-upload-list__item-actions">
        <span
            class="el-upload-list__item-delete"
            @click="deleteFile(file)">
          <i class="el-icon-delete"></i>
        </span>
      </span>
          </div>
        </el-upload>
      </el-form-item>
    </el-form>
    <van-form
        label-width="100">

      <van-row>
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit" @click="submitForm()">提交</van-button>
        </div>
      </van-row>
    </van-form>
  </div>
</template>

<script>
import Head from '@/components/Head';
import {listMaintenance, updateMaintenance, uploadImages} from "../utils/api";
import {formatDate, join, toIntArray, treeFilter} from "../utils/utils";
import {mapState} from 'vuex'
import {CellGroup, Cell, Toast} from 'vant'
import {Get, Post} from "@/utils/http";
import dayjs from "dayjs";

export default {
  name: 'MaintenanceForm',
  components: {
    Head,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup
  },
  data() {
    return {
      formData: {
        Contact: "",
        Mobile: "",
        Addr: "",
        Position: "",
        DeviceMN: "",
        MaintenancePicS: [],
        Status: "",
        Typ: "",
        NewLocaleId: ""
      },
      localeList: [],
      newLocaleList: [],
      devices: [],
      customerTree: [],
      Typ: [
        {
          value: 2,
          label: '移机'
        },
        {
          value: 6,
          label: '维保'
        },
        {
          value: 3,
          label: '拆机'
        },
        {
          value: 9,
          label: '换机'
        },
        {
          value: 7,
          label: '超标巡查'
        },
        {
          value: 8,
          label: '异常离线巡查'
        }],
      StatusList: [
        {
          status: '已完成',
          value: 1
        },
        {
          status: '未完成',
          value: 2
        },
        {
          status: '待处理',
          value: 3
        },
      ],
      MaintainerList: [],
      token: undefined,
      fileList: [],
      rules: {
        Owner: {required: true, message: '请选择所属单位', trigger: 'blur'},
        LocaleId: {required: true, message: '请选择监测点', trigger: 'blur'},
        DeviceMN: {required: true, message: '请选择维护设备', trigger: 'blur'},
        Typ: {required: true, message: '请选择维护类型', trigger: 'blur'},
        NewOwner: {required: true, message: '请选择新所属单位', trigger: 'blur'},
        NewLocaleId: {required: true, message: '请选择新监测点', trigger: 'blur'},
        MN: {required: true, message: '请输入新设备编号', trigger: 'blur'},
        MaintainerName: {required: true, message: '请选择运维人员', trigger: 'blur'},
        Status: {required: true, message: '请选择处理状态', trigger: 'blur'},
        Resolution: {required: true, message: '请填写处理措施', trigger: 'blur'},
        Position: {required: true, message: '请获取定位', trigger: 'blur'},
        files: {required: true, message: '请上传文件', trigger: 'blur'},
      }
    }
  },
  mounted() {
    this.token = sessionStorage.getItem('token')
    this.customerTree = JSON.parse(sessionStorage.getItem("customerTree"));
    Get('admin/listMaintainer').then(res => {
      this.MaintainerList = res
    })
    if (this.customerTree === null) {
      Get('admin/listBasicDataOfCustomer').then(res => {
        this.customerTree = treeFilter(res.Customer)
      })
    }
  },
  beforeUnmount() {
    this.ruleForm = {}
  },
  computed: {
    ...mapState(['locale'])
  },
  methods: {
    onOwnerConfirm(val) {
      this.formData.LocaleId = ""
      this.formData.LocaleName = ""
      this.formData.Addr = ""
      this.formData.Contact = ""
      this.formData.Mobile = ""
      this.formData.DeviceId = ""
      this.formData.DeviceMN = ""
      let param = {
        StartAt: 0,
        Size: 20,
        Param: {
          Owner: join(this.formData.Owner)
        }
      }
      Post('admin/listLocale', param).then(res => {
        this.localeList = res.content
      })
      this.showOwner = false
    },
    onNewOwnerConfirm() {
      this.formData.NewLocaleId = ""
      this.formData.NewLocaleName = ""
      let param = {
        StartAt: 0,
        Size: 20,
        Param: {
          Owner: join(this.formData.NewOwner)
        }
      }
      Post('admin/listLocale', param).then(res => {
        this.newLocaleList = res.content
      })
    },
    onLocaleConfirm() {
      Get('admin/listCustomerOfLocale', {LocaleId: this.formData.LocaleId}).then(res => {
        this.formData.Addr = res.Address
        this.formData.Contact = res.Contact
        this.formData.Mobile = res.Mobile
      })
      Post('admin/getDevicesOfLocale', {LocaleId: this.formData.LocaleId}).then((res) => {
        this.devices = res
      })
    },
    onDeviceConfirm(val) {
      this.formData.DeviceId = val.Id
      this.formData.DeviceMN = val.MN
    },
    onNewMaintainerConfirm(val) {
      this.formData.MaintainerName = val.Name
      this.formData.MaintainerId = val.Id
    },

    //将base64转换为blob
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {type: mime});
    },
    submitForm() {
      this.formData.CreateAt = dayjs().unix()
      this.formData.MaintenancePicS = this.fileList
      this.formData.FormType = 1
      let param = {...this.formData}
      if (this.formData.Typ === 2) {
        param.NewOwner = join(this.formData.NewOwner)
      }
      param.Owner = join(this.formData.Owner)
      Post("admin/saveMaintenance", param).then(res => {
        console.log(res)
        this.$message({
          message: '提交成功',
          type: 'success'
        })
        this.$router.go(-1);
      }).catch(e => {
        //console.log(e.data.Data)
        if (e.data.Data !== "") {
          this.$message({
            message: e.data.Data,
            type: 'error'
          })
        }else {
          this.$message({
            message: e.data.Msg,
            type: 'error'
          })
        }
      })
    },
    getLocation() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      const _this = this;
      AMap.plugin('AMap.Geolocation', function () {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
        })
        geolocation.getCurrentPosition()
        AMap.event.addListener(geolocation, 'complete', onComplete);
        AMap.event.addListener(geolocation, 'error', onError);


        function onComplete(data) {
          // data是具体的定位信息
          console.log(data)
          _this.formData.Lng = data.position.lng.toString()
          _this.formData.Lat = data.position.lat.toString()
          _this.formData.Position = data.formattedAddress
          Toast.success("获取定位成功")
          loading.close();
        }

        function onError(data) {
          // 定位出错
          console.log('定位失败错误：', data);
          // 调用ip定位
          _this.getLngLatLocation()
          loading.close();

        }
      })
    },
    getLngLatLocation() {
      const _this = this
      AMap.plugin('AMap.CitySearch', function () {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity(function (status, result) {
          if (status === 'complete' && result.info === 'OK') {
            // 查询成功，result即为当前所在城市信息
            console.log('通过ip获取当前城市：', result);
            _this.formData.Position = result.province + result.city;
            //逆向地理编码
            AMap.plugin('AMap.Geocoder', function () {
              var geocoder = new AMap.Geocoder({
                // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                city: result.adcode
              })

              var lnglat = result.rectangle.split(';')[0].split(',');
              geocoder.getAddress(lnglat, function (status, data) {
                if (status === 'complete' && data.info === 'OK') {
                  // result为对应的地理位置详细信息
                  _this.formData.Position = data.regeocode.formattedAddress
                }
              })
            })
          }
        })
      })
    },
    handleAvatarSuccess(res) {
      this.fileList.push(res.Data)
    },
    deleteFile(file) {
      const url = file.response.Data
      let i = this.fileList.findIndex(e => e === url)
      if (i !== -1) {
        this.fileList.splice(i, 1)
        this.$refs.upload.uploadFiles.splice(i, 1)
      } else {
        this.$message.error('删除失败！')
      }
    },
    beforeImageChange(file) {
      this.changeState = true
      const imageType = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
      if (!imageType) {
        this.$message.error('请上传jpg、jpeg或png格式的图片！');
      }
      return imageType;
    },
    handlePreview(url) {
      this.showImg = true
      this.dialogImageUrl = url
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-dialog__body {
  padding: 0
}

.el-form-item__label ::v-deep {
  padding-left: 20px;
}

.el-form-item ::v-deep {
  margin-bottom: 12px;
}
</style>
