<!--我的账户安全修改密码-->
<template>
  <div class="bg01">
    <Head title="修改密码"></Head>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-position="left" class="form02">
      <el-form-item label="旧密码" prop="p0">
        <el-input v-model="ruleForm.p0"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="p1">
        <el-input v-model="ruleForm.p1"></el-input>
      </el-form-item>
      <el-form-item label-width="0">
        <el-button class="btn01" type="primary" @click="submitForm('ruleForm')">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Head from '@/components/Head';
import { changepasswd } from "../utils/api";

export default {
  components: { Head },
  data () {
    return {
      msg: 'Welcome to Your Vue.js App',
      ruleForm: {
        p0: '',
        p1: '',
      },
      rules: {
        p0: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ],
        p1: [
          { required: true, message: '请输入新密码', trigger: 'blur' }
        ],
      },
    }
  },
  mounted() {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          changepasswd(this.ruleForm).then(res => {
            this.$toast.text({
              duration: 1000,
              message: '操作成功'
            });
            this.$router.go(-1);
          }).catch(err => {
            console.log(err)
            this.$message.error(err.data.Msg)
          });
        } else {

          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
