<!--数据统计-监测点信息上传-新增-->
<template>
  <div class="bg01">
    <Head :title="getTitle" :notshowUpload="true"></Head>
    <van-form
        :disabled="type === 'LOOK'"
        label-width="100">
      <van-field
          label="监测点名称"
          name="监测点名称"
          required
          placeholder="请输入"
          v-model="formData.name"
          :rules="[{ required: true, message: '请填写用户名' }]"/>
      <van-field
          readonly
          is-link
          required
          label="所属地区"
          placeholder="请选择所属地区"
          v-model="formData.area_name"
          @click="showArea = true && type !=='LOOK'"/>
<!--加上这个代码是 && type !=='LOOK'因为查看的时候还能点击-->
      <van-popup v-model="showArea" position="bottom">
        <van-cascader
            :options="areaTree"
            :field-names="{text:'Name',children:'children',value:'Id'}"
            @finish="onAreaConfirm"
            @close="showArea=false"
        />
      </van-popup>
      <van-field
          label="联系人"
          name="联系人"
          required
          placeholder="请输入联系人"
          v-model="formData.contact"
          :rules="[{ required: true, message: '请填写联系人' }]"/>
      <van-field
          label="联系电话"
          name="联系电话"
          placeholder="请输入联系电话"
          required
          v-model="formData.mobile"
          :rules="[{ required: true, message: '请填写联系电话' }]"/>
      <van-field
          readonly
          is-link
          label="上级单位"
          placeholder="请选择上级单位"
          required
          v-model="formData.owner_name"
          @click="showOwner = true && type !=='LOOK'"/>
      <!--加上这个代码是 && type !=='LOOK'因为查看的时候还能点击-->
      <van-popup v-model="showOwner" position="bottom">
        <van-cascader
            v-model="formData.owner"
            :options="customerTree"
            :field-names="{text:'Name',children:'children',value:'Id'}"
            :value="selectedValue"
            @change="handleCascaderChange"
            @finish="onOwnerConfirm"
            @close="handleClose"
        />
      </van-popup>
      <van-field
          label="地址"
          placeholder="请输入地址"
          required
          v-model="formData.addr"
          :rules="[{ required: true, message: '请填写地址' }]"/>
      <van-field
          label="经度"
          placeholder="请输入经度"
          required
          v-model="formData.lng"
          :rules="[{ required: true, message: '请填写经度' }]"/>
      <van-field
          label="纬度"
          placeholder="请输入纬度"
          required
          v-model="formData.lat"
          :rules="[{ required: true, message: '请填写纬度' }]"/>
      <van-field
          v-show="type!=='LOOK'"
          label="获取定位">
        <template #input>
          <div
              type="text"
              icon="el-icon-location-outline"
              v-if="type !== 'LOOK'"
              @click="getLocation">点击获取定位
          </div>
        </template>
      </van-field>
      <van-field
          label="集气罩面积"
          placeholder="请填写集气罩面积"
          required
          v-model="formData.stove_area"
          :rules="[{ required: true, message: '请填写集气罩面积' }]">
        <template #button>
          <div>m²</div>
        </template>
      </van-field>
      <van-field
          label="菜系"
          placeholder="请选择菜系"
          readonly
          is-link
          required
          v-model="formData.cuisine_name"
          @click="showCuisine = true"
          :rules="[{ required: true, message: '请选择菜系' }]"/>
      <van-popup v-model="showCuisine" position="bottom">
        <van-picker
            show-toolbar
            value-key="Name"
            :columns="cuisine"
            @confirm="onCuisineConfirm"
            @cancel="showCuisine = false"
        />
      </van-popup>
      <van-field
          label="灶头数量"
          type="number"
          placeholder="请填写灶头数量"
          required
          v-model="formData.stove_num"
          :rules="[{ required: true, message: '请填写灶头数量' }]">
        <template #button>
          <div>个</div>
        </template>
      </van-field>
      <van-field
          label="日均排烟"
          type="number"
          placeholder="请填写日均排烟"
          required
          v-model="formData.exhaust_time"
          :rules="[{ required: true, message: '请填写日均排烟' }]">
        <template #button>
          <div>小时</div>
        </template>
      </van-field>
      <van-field
          label="企业类型"
          required
          v-model="formData.scale"
          :rules="[{ required: true, message: '请选择企业类型' }]">
        <template #input>
          <van-radio-group
              :disabled="type==='LOOK'"
              v-model="formData.scale"
              direction="horizontal">
            <van-radio name="大">大</van-radio>
            <van-radio name="中">中</van-radio>
            <van-radio name="小">小</van-radio>
          </van-radio-group>
        </template>
      </van-field>
<!--      2024/9/18修改经营类型接口获取-->
      <van-field
          label="经营类型"
          placeholder="请选择经营类型"
          readonly
          is-link
          required
          v-model="formData.managementName"
          @click="management = true"
          :rules="[{ required: true, message: '请选择经营类型' }]"/>
      <van-popup v-model="management" position="bottom">
        <van-picker
            show-toolbar
            :columns-field-names="{text:'Name',children:'children',value:'Id'}"
            value-key="Name"
            :columns="managementData"
            @confirm="onmanagementDataConfirm"
            @cancel="management = false"
        />
      </van-popup>
<!--      <van-field-->
<!--          label="经营类型"-->
<!--          required-->
<!--          :rules="[{ required: true, message: '请选择企业类型' }]">-->
<!--        <template #input>-->
<!--          <van-radio-group-->
<!--              :disabled="type==='LOOK'"-->
<!--              v-model="formData.management_types"-->
<!--              direction="horizontal">-->
<!--            <van-radio name="经营型">经营型</van-radio>-->
<!--            <van-radio name="非经营型">非经营型</van-radio>-->
<!--          </van-radio-group>-->
<!--        </template>-->
<!--      </van-field>-->
      <van-field
          label="风机标准电流"
          type="number"
          placeholder="请填写风机标准电流"
          required
          v-model="formData.fan_standard_current"
          :rules="[{ required: true, message: '请填写风机标准电流' }]">
        <template #button>
          <div>A</div>
        </template>
      </van-field>
      <van-field
          label="净化器标准电流"
          type="number"
          placeholder="请填写净化器标准电流"
          required
          v-model="formData.filter_standard_current"
          :rules="[{ required: true, message: '请填写净化器标准电流' }]">
        <template #button>
          <div>A</div>
        </template>
      </van-field>
      <van-field
          label="监测仪品牌"
          readonly
          is-link
          placeholder="请选择监测仪品牌"
          required
          v-model="formData.voc_factory_name"
          @click="onFactoryClick"
          :rules="[{ required: true, message: '请填写监测仪品牌' }]"/>
      <van-popup v-model="showFactory" position="bottom">
        <van-picker
            show-toolbar
            value-key="factory_name"
            :columns="factoryList"
            @confirm="onFactoryConfirm"
            @cancel="showFactory = false"
        />
      </van-popup>
      <van-field
          label="监测仪型号"
          is-link
          readonly
          placeholder="请选择监测仪型号"
          required
          v-model="formData.voc_factory_model_name"
          @click="showModel = true"
          :rules="[{ required: true, message: '请选择监测仪型号' }]"/>
      <van-popup v-model="showModel" position="bottom">
        <van-picker
            show-toolbar
            value-key="model_name"
            :columns="factoryModelList"
            @confirm="onModelConfirm"
            @cancel="showModel = false"
        />
      </van-popup>

      <van-field
          label="检测原理"
          required
          :rules="[{ required: true, message: '请选择检测原理' }]">
        <template #input>
          <van-radio-group
              :disabled="type==='LOOK'"
              v-model="formData.principle"
              direction="horizontal">
            <van-radio name="光散射">光散射</van-radio>
            <van-radio name="电化学">电化学</van-radio>
            <van-radio name="其他">其他</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field
          label="接电方式"
          required
          :rules="[{ required: true, message: '请选择接电方式' }]">
        <template #input>
          <van-radio-group
              :disabled="type==='LOOK'"
              v-model="formData.power_supply_mode"
              direction="horizontal">
            <van-radio name="风机取电">风机取电</van-radio>
            <van-radio name="净化器取电">净化器取电</van-radio>
            <van-radio name="市电">市电</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field
          label="传输方式"
          required
          :rules="[{ required: true, message: '请选择传输方式' }]">
        <template #input>
          <van-radio-group
              :disabled="type==='LOOK'"
              v-model="formData.send_mode"
              direction="horizontal">
            <van-radio :name="1">合并传输</van-radio>
            <van-radio :name="2">独立传输</van-radio>
            <van-radio :name="3">两路传输</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field
          label="监测仪编码"
          placeholder="请输入监测仪编码"
          required
          v-model="formData.voc_mn"
          :rules="[{ required: true, message: '请输入监测仪编码' }]"/>
      <van-field
          label="风机监控编码"
          v-if="formData.send_mode === 2 || formData.send_mode === 3&& formData.power_supply_mode === '净化器取电'"
          placeholder="请输入风机监控编码"
          required
          v-model="formData.fan_mn"
          :rules="[{ required: true, message: '请输入风机监控编码' }]"/>
      <van-field
          label="净化器监控编码"
          placeholder="请输入净化器监控编码"
          v-if="formData.send_mode === 2 || formData.send_mode === 3&& (formData.power_supply_mode === '风机取电' ||formData.power_supply_mode === '市电')"
          required
          v-model="formData.filter_mn"
          :rules="[{ required: true, message: '请输入净化器监控编码' }]"/>

      <van-field v-if="this.formData.pics && type !== 'ADD' " label="照片浏览">
        <template #input>
          <van-image
              style="flex-wrap: wrap"
              v-for="(item,index) in formData.pics"
              width="100"
              height="100"
              :key="index"
              :src="item"
              @click="showImg(item,index)"
          />
        </template>
      </van-field>
      <van-field v-if="type!== 'LOOK'"
                 name="uploader" label="照片上传">
        <template #input>
          <van-uploader
              :after-read="afterRead"
              @delete="deleteFile"
              v-model="formData.files"/>
        </template>
      </van-field>
      <van-row>
        <van-col :span="12">
          <van-field
              label="集气罩长"
              type="number"
              placeholder="请输入"
              v-model="formData.stove_length">
            <template #button>
              <div>m</div>
            </template>
          </van-field>
          <van-field
              label="风机品牌"
              placeholder="请输入"
              v-model="formData.fan_factory_name"/>
          <van-field
              label="风机功率"
              type="number"
              placeholder="请输入"
              v-model="formData.fan_power"/>
          <van-field
              label="风机流速"
              type="number"
              placeholder="请输入"
              v-model="formData.fan_speed"/>
          <van-field
              label="净化器品牌"
              placeholder="请输入"
              v-model="formData.filter_factory_name"/>
          <van-field
              label="净化率"
              type="number"
              placeholder="请输入"
              v-model="formData.purifying_rate"/>
          <van-field
              label="净化器功率"
              type="number"
              placeholder="请输入"
              v-model="formData.filter_power"/>
        </van-col>
        <van-col :span="12">
          <van-field
              label="集气罩宽"
              type="number"
              placeholder="请输入"
              v-model="formData.stove_width">
            <template #button>
              <div>m</div>
            </template>
          </van-field>
          <van-field
              label="风机型号"
              placeholder="请输入"
              v-model="formData.fan_factory_model_id"/>
          <van-field
              style="visibility: hidden"
              placeholder="请输入"/>
          <van-field
              label="管道截面积"
              placeholder="请输入"
              v-model="formData.pipe_area"/>
          <van-field
              label="净化器型号"
              placeholder="请输入"
              v-model="formData.filter_factory_model_id"/>
          <van-field
              label="净化器风量"
              placeholder="请输入"
              v-model="formData.fan_quantity"/>
        </van-col>
        </van-row>
      <van-row>
        <div style="height:16px;"></div>
        <div v-if="type !== 'LOOK'">
          <van-button round block type="info" native-type="submit" @click="submitForm()">提交</van-button>
        </div>
      </van-row>
    </van-form>
    <van-image-preview
        v-model="imgVisible"
        :images="fileList"
        :show-indicators="true"
        :closeable="true"
        :start-position="startPosition">
    </van-image-preview>
  </div>
</template>

<script>
import Head from "@/components/Head";
import {
  uploadLocaleInfo,
  listSubmitter,
  listAllCuisine,
  listApprovedBusiness,
  listCascadeReferParam, listBasicDataOfCustomer, listBasicDataOfArea
} from "@/utils/api";
import {getPname, deviceTreeFilter, join, treeFilter} from "@/utils/utils";
import {mapState} from "vuex";
import {Get, Post} from "@/utils/http";
import {Notify, Toast} from "vant";

export default {
  name: "LocaleInfoForm",
  components: {
    Head
  },

  data() {
    return {
      loading: '',
      type: "",
      value: "",
      selectedValue: [],
      ExhaustTimeAll: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18
      ],
      formData: {
        lng: '',
        lat: '',
        addr: '',
        management_types: ''
      },
      errorMsg: "",
      userList: [],
      managementName:'',
      managementData:[],//经营类型的数据
      cuisine: [],
      approvedBusiness: [],
      deviceType: [],
      factoryList: [],
      factoryModelList: [],
      fileList: [],
      showArea: false,
      showOwner: false,
      showCuisine: false,
      management:false,
      showFactory: false,
      showModel: false,
      imgVisible: false,
      startPosition: 1
    };
  },

  mounted() {
    this.getmanagementData()
    // 在组件挂载后打印状态值
    listSubmitter().then(res => {
      this.userList = res.content.slice();
    });

    listApprovedBusiness().then(res => {
      this.approvedBusiness = res.content;
    });
    listCascadeReferParam({
      pcode: "DEVICE_TYPE",
      code: "DEVICE_SUBTYPE",
      c_code: "DEVICE_MODEL"
    }).then(res => {
      res.forEach(e => {
        if (e.Pid !== "") {
          e.Pid = "/" + e.Pid + "/";
        }
      });
      this.deviceType = deviceTreeFilter(res);
    });
    this.type = this.$route.params["type"];
    this.type = sessionStorage.getItem("type")

    listBasicDataOfCustomer().then(res => {
      const customerTree = treeFilter(res.Customer);
      this.customerTree = customerTree
      this.$store.commit('setCustomerTree', customerTree)
    })
    listBasicDataOfArea().then(res => {
      const areaTree = treeFilter(res.Area);
      this.areaTree = areaTree
      this.$store.commit('setAreaTree', areaTree)
    })


    if (this.type === "ADD" ) {
      this.getToday();
      listAllCuisine().then(res => {
        this.cuisine = res;
      });
      let info = JSON.parse(sessionStorage.getItem("info"));
      var owner = ""
      if (info) {
        owner = "/"
        if (info.pid) {
          owner = info.pid
        }
        if (info.id) {
          owner = owner + info.id + "/"
        }
        this.formData.owner_name = info.name
        this.formData.owner = owner
      }
    } else {
      this.formData = this.$route.params["formData"];
      if(this.formData == undefined){
        this.formData = JSON.parse(sessionStorage.getItem("formData"));
      }
      listAllCuisine().then(res => {
        this.cuisine = res;
        this.formData.cuisine_name = this.cuisine.find(item => item.Id === this.formData.cuisine).Name
        console.log('this.formData.cuisine_name',this.formData.cuisine_name)

      });
      let param = {
        StartAt: 0,
        Size: 20,
        Param:
            {
              FactoryName: this.formData.voc_factory_name,
              ModelType: 1
            }
      }
      Post('admin/listFactoryModel', param).then(res => {
        this.formData.voc_factory_model_name = res.content.find(item => item.id === this.formData.voc_factory_model_id).model_name
      })
      if (this.formData !== "") {
        this.formData.pics = this.formData.pics.split(',')
        this.fileList = Array.from(this.formData.pics)
      }
      this.formData.SubmitAt *= 1000;
    }
    this.loading = true;
  },
  computed: {
    ...mapState(["props", 'customerTree', 'areaTree']),
    ...mapState({
      orgProps: state =>
          Object.assign({}, state.props, {label: "Org", checkStrictly: true}),
      areaProps: state => Object.assign({}, state.props)
    }),
    detectorTypes() {
      if (this.deviceType.length === 0) return [];
      return [
        {
          id: "1",
          label: "监控设备",
          options: this.deviceType[0].children.map((type, index) => {
            return {
              id: index,
              label: type.Name,
              value: type.Name
            };
          })
        },
        {
          id: "2",
          label: "监测仪设备",
          options: this.deviceType[1].children[2].children.map(
              (type, index) => {
                return {
                  id: index,
                  label: type.Name,
                  value: type.Name
                };
              }
          )
        }
      ];
    },
    getTitle() {
      switch (this.type) {
        case "ADD":
          return "上传监测点信息";
        case "EDIT":
          return "修改监测点信息";
        default:
          return "查看监测点信息";
      }
    }
  },
  methods: {
    getmanagementData(){//修改经营类型的选择
      let param = {
        page: 1,
        Size: 20
      }
      Get('https://m.yhtianmu.cn/prod-api/admin/management', param).then(res => {
        const range = res.list
        // 创建一个空数组来存储分组后的数据
        const groupedDataArray = [];
        // 使用一个临时对象来分组数据
        const groupedData = {};
        range.forEach(item => {
          if (!groupedData[item.type]) {
            groupedData[item.type] = {
              Name: item.type === 1 ? '经营性' : '非经营性',
              Id: item.type,
              children: []
            };
          }
          groupedData[item.type].children.push({
            Name: item.name,
            Id: item.id
          });
        });
        // 将分组后的数据从对象转换为数组
        Object.values(groupedData).forEach(value => {
          groupedDataArray.push(value);
        });
        this.managementData = groupedDataArray;
        // this.managementData = range.map(item => ({
        //   Name: item.name,
        //   Id: item.id,
        //   type:item.type,
        //   created_at:item.created_at,
        //   time_settings:item.time_settings,
        //   setting_ids0:item.setting_ids[0],
        //   setting_ids1:item.setting_ids[1]
        // }));
        if (this.managementData && Array.isArray(this.managementData)) {
          let foundParent = null; // 存储找到的父节点
          // 遍历每个父节点
          this.managementData.forEach(parent => {
            if (parent.children && Array.isArray(parent.children)) {
              const foundChild = parent.children.find(child => child.Id === this.formData.management_types);
              if (foundChild) {
                foundParent = parent; // 记录找到的父节点
                // console.log('反向的名称', `${parent.Name} / ${foundChild.Name}`);
                this.formData.managementName = `${parent.Name} / ${foundChild.Name}`;
              }
            }
          });
          // 如果找到了父节点和子节点
          if (foundParent) {
            // console.log('父节点名称:', foundParent.Name);
            // console.log('子节点名称:', foundChild.Name);
          }
        }
        // this.formData.managementName= this.managementData.find(item => item.Id === this.formData.management_types).Name
      })
    },
    handleDetectorBrandChange(brand) {
      this.formData.Detector.Brand = brand.Id;
      this.formData.Detector.BrandName = brand.Name;
    },
    getToday() {
      let d = new Date();
      this.formData.SubmitAt = d.getTime();
    },

    subtypeFormatter(subtype) {
      switch (subtype) {
        case 1:
          return "风机设备";
        case 2:
          return "净化设备";
        case 3:
          return "检测仪设备";
        default:
          return "未知类型";
      }
    },
    handleAreaIdChange(v) {
      if (Array.isArray(v) && this.formData.Locale.AreaId === v[v.length - 1]) {
        this.$message.warning(`选择错误，请重新选择`);
        this.formData.Locale.AreaId = [];
      }
    },

    customerFormatter(val) {
      return getPname(this.customerTree, val, "Org").join("");
    },

    submitForm() {
      this.formData.pics = this.fileList.join(',')
      let param = new URLSearchParams()
      Object.entries(this.formData).forEach(([key, val]) => {
        param.append(key, val)
      })
      if(this.formData.voc_factory_model_name == ''){
        return
      }
      if (this.type === 'ADD') {
        Post('admin/locale_exam/add', param).then(res => {
          Notify({type: 'success', message: '添加成功'});
          this.$router.go(-1)
        }).catch(e => {
          var message = JSON.stringify(e.data.Data)
          if (message) {
            Notify({type: 'warning', message: message});
          } else {
            Notify({type: 'warning', message: e.data.Msg});
          }
        })
      } else if (this.type === 'EDIT') {
        Post('admin/locale_exam/edit/' + this.formData.id, param).then(res => {
          // 成功通知
          Notify({type: 'success', message: '修改成功'});
          this.$router.go(-1)
        }).catch(e => {
          Notify({type: 'warning', message: e.data.Data});
        })
      }

    },
    getLocation() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      const _this = this;
      AMap.plugin("AMap.Geolocation", function () {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000
        });
        geolocation.getCurrentPosition();
        AMap.event.addListener(geolocation, "complete", onComplete);
        AMap.event.addListener(geolocation, "error", onError);

        function onComplete(data) {
          console.log(data)
          // data是具体的定位信息
          _this.formData.addr = data.formattedAddress
          _this.formData.lng = data.position.lng.toString();
          _this.formData.lat = data.position.lat.toString();
          Toast.success("获取定位成功")
          loading.close();
        }

        function onError(data) {
          // 定位出错
          console.log("定位失败错误：", data);
          loading.close();
          // 调用ip定位
          _this.getLngLatLocation();
        }
      });
    },

    getLngLatLocation() {
      const _this = this;
      AMap.plugin("AMap.CitySearch", function () {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity(function (status, result) {
          if (status === "complete" && result.info === "OK") {
            // 查询成功，result即为当前所在城市信息
            console.log("通过ip获取当前城市：", result);
            // let point = result.rectangle.split(';')[1].split(',');
            _this.formData.addr = result.province + '/' + result.city;
            loading.close();
            // _this.ruleForm.position[0] = parseFloat(point[0]);
            // _this.ruleForm.position[1] = parseFloat(point[1]);
            //逆向地理编码
            AMap.plugin("AMap.Geocoder", function () {
              var geocoder = new AMap.Geocoder({
                // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                city: result.adcode
              });

              var lnglat = result.rectangle.split(";")[0].split(",");
              geocoder.getAddress(lnglat, function (status, data) {
                if (status === "complete" && data.info === "OK") {
                  // result为对应的地理位置详细信息
                  console.log(data.regeocode.formattedAddress);
                }
              });
            });
          }
        });
      });
    },
    onAreaConfirm(val) {
      this.formData.area_id = '/' + val.selectedOptions.map(item => item.Id).join('/') + '/'
      this.formData.area_name = val.selectedOptions.map(item => item.Name).join('/')
      this.showArea = false
    },
    handleCascaderChange(value) {
      this.selectedValue = value;
    },
    handleClose() {
      var ids = []
      if (this.selectedValue.selectedOptions) {
        for (var i = 0; i < this.selectedValue.selectedOptions.length; i++) {
          ids.push( this.selectedValue.selectedOptions[i].Id)
        }
        this.formData.owner = join(ids)
        this.formData.owner_name = this.selectedValue.selectedOptions.map(item => item.Name).join('/')
      }
      this.showOwner = false
    },
    onOwnerConfirm(val) {
      var ids = []
      for (var i = 0; i < this.selectedValue.selectedOptions.length; i++) {
        ids.push( this.selectedValue.selectedOptions[i].Id)
      }
      this.formData.owner = join(ids)
      this.formData.owner_name = val.selectedOptions.map(item => item.Name).join('/')
      this.showOwner = false
    },
    onmanagementDataConfirm(val){//选择经营类型
      // console.log('val',val)
      // this.formData.management_types = val.Id
      // this.formData.managementName = val.Name
      if (this.managementData && Array.isArray(this.managementData)) {
        this.managementData.forEach(parent => {
          if (parent.children && Array.isArray(parent.children)) {
            const foundChild = parent.children.find(child => child.Name === val[1]);
            if (foundChild) {
              // console.log('用于请求的Id', foundChild.Id);
              this.formData.management_types = foundChild.Id
              this.formData.managementName = val[0] + '/' + val[1]
            }
          }
        });
      }
      this.management = false
    },
    onCuisineConfirm(val) {
      this.formData.cuisine_name = val.Name
      this.formData.cuisine = val.Id
      this.showCuisine = false
    },
    onFactoryClick() {
      Post("admin/listFactory", {}).then(res => {
        this.factoryList = res.content
        this.showFactory = true
      })
    },
    onFactoryConfirm(val) {
      this.formData.voc_factory_name = val.factory_name
      let param = {
        StartAt: 0,
        Size: 20,
        Param:
            {
              FactoryName: val.factory_name,
              ModelType: 1
            }
      }
      Post('admin/listFactoryModel', param).then(res => {
        this.factoryModelList = []
        this.factoryModelList = res.content
      })
      // 清空之前选择的监测仪型号

      this.formData.voc_factory_model_name = ''

      this.showFactory = false
    },
    onModelConfirm(val) {
      this.formData.voc_factory_model_id = val.id
      this.formData.voc_factory_model_name = val.model_name
      this.showModel = false
    },
    afterRead(file) {
      const fileN = file.content;
      const blob = this.dataURLtoBlob(fileN)
      let formData = new FormData()
      formData.set('file', blob)
      Post('https://m.yhtianmu.cn/prod-api/uploadToOss', formData).then(res => {
        this.fileList.push(res)
      })
    },
    deleteFile(file, detail) {
      this.fileList.splice(detail.index, 1)
    },
    //将base64转换为blob
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {type: mime});
    },
    showImg(item, index) {
      this.startPosition = index
      this.imgVisible = true
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.bgc-anime {
  animation-name: box-bgc;
  animation-duration: 2s;
  animation-timing-function: linear;
}

@keyframes box-bgc {
  0% {
    background-color: #fff;
  }
  25% {
    background-color: #d7d7d7;
  }
  50% {
    background-color: #fff;
  }
  75% {
    background-color: #d7d7d7;
  }
  100% {
    background-color: #fff;
  }
}

.tabs-wrapper {
  padding: 20px 10px;
}

.tabs-content {
  margin-top: 20px;
  height: 240px;
  overflow: scroll;

  .item {
    line-height: 40px;
    font-size: 14px;
  }

  .select {
    color: #009999;
    font-weight: bold;
  }
}

::v-deep .van-field__control--custom {
  flex-wrap: wrap;
}
</style>
