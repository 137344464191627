<template>
  <div>
    <el-dialog
      title="详情"
      :visible.sync="dialogVisible"
      width="100%"
      top="10px"
      @close="handleClose"
      center>
      <wv-group>
        <wv-cell title="设备编号" :value="dialogObj.MN"></wv-cell>
        <wv-cell title="设备状态" :value="dialogObj.Status | dialogStatus"></wv-cell>
        <wv-cell title="餐馆名称" :value="dialogObj.name"></wv-cell>
        <wv-cell title="地址" :value="dialogObj.Addr"></wv-cell>
        <wv-cell title="地图坐标" :value="dialogObj.Lng+','+dialogObj.Lat"></wv-cell>
        <wv-cell title="管道平均风速" :value="dialogObj.FanSpeed"></wv-cell>
        <wv-cell title="管道截面积" :value="dialogObj.PipeArea"></wv-cell>
        <wv-cell title="灶头数" :value="dialogObj.StoveNum"></wv-cell>
        <wv-cell title="菜系" :value="dialogObj.StoveArea"></wv-cell>
        <wv-cell title="风机状态" :value="dialogObj.FanStatus | type"></wv-cell>
        <wv-cell title="净化器状态" :value="dialogObj.LinkStatus | type"></wv-cell>
      </wv-group>
    </el-dialog>
  </div>
</template>

<script>
import { detailPubSub } from "@/service/pubsub/index";
import showDialog from '@/minxs/showDialog';

export default {
  data () {
    return {
      msg: 'Welcome to Your Vue.js App',
      dialogObj: {
        MN: '',
        Status: '',
        name: '',
        Addr: '',
        Lng: '',
        Lat: '',
        FanSpeed: '',
        PipeArea: '',
        StoveNum: '',
        StoveArea: '',
        FanStatus: '',
        LinkStatus: '',
      },
    }
  },
  mounted() {
    detailPubSub.unsubscribe();
    detailPubSub.subscribe(this.getOrder);
  },
  filters: {
    dialogStatus(v) {
      let obj = {
        NORMAL: '达标',
        EXCESS: '超标',
        DOWN: '异常',
        OFF: '离线',
      };
      return obj[v]
    },
    type(v) {
      return v === 1 ? '开' : '-'
    }
  },
  mixins:[showDialog],
  methods: {
    getOrder(data) {
      this.dialogObj = data
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
