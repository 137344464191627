<!--数据统计-处理/查看-->
<template>
  <div class="bg01">
    <Head title="处理"></Head>
    <el-form :model="ruleForm" :rules="rules" :hide-required-asterisk="true" ref="ruleForm" label-width="100px"
             label-position="left" class="form02" :disabled="isLook">
      <el-form-item label="监测点名称">
        <el-input v-model="ruleForm.LocaleName" disabled></el-input>
      </el-form-item>
      <el-form-item label="监测点地址">
        <el-input v-model="ruleForm.Addr" disabled></el-input>
      </el-form-item>
      <el-form-item :label="ruleForm.FormType === 1 ? '维护设备' : '设备编号'">
        <el-input v-model="ruleForm.MN" disabled></el-input>
      </el-form-item>
      <el-form-item label="报警日期" v-if="ruleForm.FormType !== 1">
        <el-date-picker
            disabled
            type="date"
            value-format="timestamp"
            format="yyyy-MM-dd"
            v-model="ruleForm.AlarmAt"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="设备类型" v-if="ruleForm.FormType === 1">
        <el-select v-model="ruleForm.Subtype" disabled>
          <el-option
              v-for="item in subtypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="异常状态" v-if="ruleForm.FormType === 2">
        <el-select v-model="ruleForm.AbnormalStatus" disabled>
          <el-option
              v-for="item in AbnormalOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="异常状态" v-if="ruleForm.FormType === 3">
        <el-input v-model="ruleForm.ErrorDesc" disabled>
        </el-input>
      </el-form-item>
      <el-form-item label="运维人员">
        <el-input v-model="ruleForm.MaintainerName" disabled></el-input>
      </el-form-item>
      <el-form-item label="处理日期" v-if="(isLook && ruleForm.MaintainAt !== 0) || !isLook">
        <el-date-picker
            disabled
            type="date"
            value-format="timestamp"
            format="yyyy-MM-dd"
            v-model="ruleForm.MaintainAt"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="处理日期" v-else>
        <el-input
            disabled
            placeholder="无"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="ruleForm.Typ === 2" :label="isDeal ? '选择新监测点' : '新监测点名称'">
        <el-select
            @change="newLocaleChange"
            v-model="ruleForm.NewLocaleName"
            filterable
            clearable
            :placeholder="isDeal ? '请选择新监测点' : '新监测点名称'"
        >
          <el-option
              v-for="item in locale"
              :key="item.Id"
              :label="item.Name"
              :value="{ LocaleId: item.Id, LocaleName: item.Name, Addr: item.Addr }"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
          v-if="ruleForm.FormType === 1"
          prop="Status"
          label="处理结果"
      >
        <el-select
            @change="hasChange"
            v-model.trim="ruleForm.Status"
            clearable
            placeholder="处理结果"
        >
          <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="异常原因" prop="DeviceFailureCause" v-if="ruleForm.FormType === 2">
        <el-select
            v-model="ruleForm.DeviceFailureCause"
            @change="hasChange"
        >
          <el-option
              v-for="item in DeviceFailureCauseOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="异常原因" prop="AbnormalCause" v-if="ruleForm.FormType === 3">
        <el-select
            v-model="ruleForm.AbnormalCause"
            @change="hasChange"
            v-if="ruleForm.ErrorDesc === '异常离线'"
        >
          <el-option
              v-for="item in AbnormalCauseOptionsTypeTwo"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
        <el-select
            v-model="ruleForm.AbnormalCause"
            @change="hasChange"
            v-else
        >
          <el-option
              v-for="item in AbnormalCauseOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="持续天数" v-if="ruleForm.FormType === 3">
        <el-select
            v-model="ruleForm.MaintenanceTimeSpan"

            @change="hasChange"
        >
          <el-option
              v-for="item in TimeSpanOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="处理措施" prop="Resolution">
        <el-input
            type="textarea"
            placeholder="输入处理措施"
            v-model="ruleForm.Resolution"
            @change="hasChange"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="定位">
        <van-cell v-if="ruleForm.Position" :title="ruleForm.Position" :border="false"
                  @click="getLocation"></van-cell>
        <el-button v-else type="text" icon="el-icon-location-outline" @click="getLocation">获取定位</el-button>
      </el-form-item>
      <el-form-item label="图片">
        <el-image
            class="view-img"
            fit="contain"
            v-for="(item, i) in fileList"
            :key="i"
            :src="item"
            @click="handlePreview(item)"
            v-if="!isLook"
        >
          <div slot="error" class="image-slot">
            暂无图片
          </div>
        </el-image>
        <el-image
            class="view-img"
            fit="contain"
            v-for="(item, i) in fileList"
            :key="i"
            :src="item"
            v-else
        >
          <div slot="error" class="image-slot">
            暂无图片
          </div>
        </el-image>
        <el-upload
            ref="upload"
            action="https://m.yhtianmu.cn/uploadToOss"
            list-type="picture-card"
            :on-success="handleAvatarSuccess"
            :limit="4"
            :before-upload="beforeImageChange"
            :on-exceed="handleExceed"
            v-if="!isLook"
        >
          <i class="el-icon-plus"></i>
          <div slot="tip">（最多4张，支持jpg、jpeg、png）</div>
          <div slot="file" slot-scope="{file}">
            <img
                class="el-upload-list__item-thumbnail"
                :src="file.response.Data" alt=""
                @click="handlePreview(file.response.Data)"
            >
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item v-if="!isLook" label-width="0">
        <el-button class="btn01" type="primary" @click="submitForm('ruleForm')">提交</el-button>
      </el-form-item>
    </el-form>
    <el-dialog :visible.sync="showImg" width="100%" :show-close="false" class="image-dialog">
      <el-image :src="dialogImageUrl" alt="" fit="contain"></el-image>
      <el-button type="danger" @click="handleRemove(dialogImageUrl)">删除</el-button>
    </el-dialog>
  </div>
</template>

<script>
import Head from '@/components/Head';
import {listMaintenance, updateMaintenance, uploadImages} from "../utils/api";
import {formatDate} from "../utils/utils";
import {mapState} from 'vuex'
import {CellGroup, Cell} from 'vant'

export default {
  name: 'TroubleTicketForm',
  components: {
    Head,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup
  },
  data() {
    return {
      isLook: false,
      isDeal: false,
      ruleForm: {},
      rules: {
        AbnormalCause: [
          {required: true, message: '请选择异常原因', trigger: 'blur'}
        ],
        DeviceFailureCause: [
          {required: true, message: '请选择异常原因', trigger: 'blur'}
        ],
        Status: [
          {required: true, message: '请选择处理结果', trigger: 'blur'}
        ],
        Resolution: [
          {required: true, message: '请输入处理措施', trigger: 'blur'}
        ]
      },
      dialogVisible: false,
      pickerShow: false,
      statusOptions: [
        {
          value: 1,
          label: '已完成'
        },
        {
          value: 2,
          label: '未完成'
        },
        {
          value: 3,
          label: '待处理',
          disabled: true
        }
      ],
      subtypeOptions: [
        {
          value: 1,
          label: '风机设备'
        },
        {
          value: 2,
          label: '净化设备'
        },
        {
          value: 3,
          label: '检测仪设备'
        }
      ],
      options: [
        {
          value: 1,
          label: '维修'
        },
        {
          value: 6,
          label: '维保'
        },
        {
          value: 2,
          label: '移机'
        },
        {
          value: 3,
          label: '拆机'
        }
      ],
      AbnormalOptions: [
        {
          value: 1,
          label: '风机监控异常'
        },
        {
          value: 2,
          label: '净化器监控异常'
        },
        {
          value: 3,
          label: '油烟检测传感异常'
        }
      ],
      AbnormalCauseOptionsTypeTwo: [
        {
          value: '店家歇业',
          label: '店家歇业'
        },
        {
          value: '店家原因',
          label: '店家原因'
        },
        {
          value: '设备故障',
          label: '设备故障'
        }
      ],
      AbnormalCauseOptions: [
        {
          value: '数据正常',
          label: '数据正常'
        },
        {
          value: '店家原因',
          label: '店家原因'
        },
        {
          value: '设备原因',
          label: '设备原因'
        },
        {
          value: '其他原因',
          label: '其他原因'
        }
      ],
      DeviceFailureCauseOptions: [
        {
          value: '风机监控损坏',
          label: '风机监控损坏'
        },
        {
          value: '净化机监控损坏',
          label: '净化机监控损坏'
        },
        {
          value: '油烟检测传感损坏',
          label: '油烟检测传感损坏'
        },
        {
          value: '店家原因',
          label: '店家原因'
        },
        {
          value: '其他原因',
          label: '其他原因'
        }
      ],
      TimeSpanOptions: [
        {
          label: '0',
          value: 0
        },
        {
          label: '1',
          value: 1
        },
        {
          label: '2',
          value: 2
        },
        {
          label: '3',
          value: 3
        },
        {
          label: '4',
          value: 4
        },
        {
          label: '5',
          value: 5
        },
        {
          label: '6',
          value: 6
        },
        {
          label: '7',
          value: 7
        },
        {
          label: '8',
          value: 8
        },
        {
          label: '9',
          value: 9
        },
        {
          label: '10',
          value: 10
        }
      ],
      pickerShow02: false,
      columns02: [
        {
          values: [
            {
              name: '监测设备',
              values: 'DetectorProblem'
            },
            {
              name: '监控设备',
              values: 'MonitorProblem'
            },
            {
              name: '净化器不正常使用',
              values: 'PurifierProblem'
            },
            {
              name: '歇业',
              values: 'StoppedBusiness'
            }
          ]
        }
      ],
      token: undefined,
      changeState: false,
      fileList: [],
      showImg: false,
      dialogImageUrl: ''
    }
  },
  mounted() {
    this.token = sessionStorage.getItem('token')
    this.ruleForm = Object.assign({}, this.$route.params['formData'])
    this.isLook = this.$route.params['isLook'] === 1
    this.isDeal = this.$route.params['isDeal'] === 1
    this.fileList = Object.assign([], this.ruleForm.MaintenancePicS)
    this.ruleForm.MaintenancePics = []
  },
  beforeUnmount() {
    this.ruleForm = {}
  },
  computed: {
    ...mapState(['locale'])
  },
  methods: {
    newLocaleChange(val) {
      this.ruleForm.NewLocaleId = val.LocaleId
      this.ruleForm.NewLocaleName = val.LocaleName
    },
    hasChange() {
      this.ruleForm.MaintenancePicS = this.fileList
      this.changeState = true
    },
    beforeImageChange(file) {
      this.changeState = true
      const imageType = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
      if (!imageType) {
        this.$message.error('请上传jpg、jpeg或png格式的图片！');
      }
      return imageType;
    },
    getToDay() {
      let day = new Date();
      day.setTime(day.getTime());
      let m = day.getMonth() + 1;
      m = m < 10 ? '0' + m : m;
      return `${day.getFullYear()}-${m}-${day.getDate()}`
    },
    getData(obj) {
      let param = {};
      param.StartAt = 0;
      param.Size = 1;
      param.Param = obj;
      listMaintenance(param).then(res => {
        this.ruleForm = res.content[0];
        this.ruleForm.acquitAt = formatDate(this.ruleForm.CreateAt);
        this.ruleForm.maintainAt = formatDate(this.ruleForm.MaintainAt);
        this.columns[0].values.forEach(v => {
          if (v.values === this.ruleForm.Typ) {
            this.ruleForm.typ = v.name;
          }
        });
        this.columns02[0].values.forEach(v => {
          if (v.values === this.ruleForm.FailureCause) {
            this.ruleForm.failureCause = v.name;
          }
        });
        this.ruleForm.name = sessionStorage.getItem('name');
        // this.$forceUpdate();
        // console.log(this.ruleForm)
      }).catch(err => {
        console.log(err)
      });
    },
    submitForm(formName) {
      // if (!this.changeState) {
      //   this.$router.go(-1);
      //   return
      // }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const para = Object.assign({}, this.ruleForm)

          if (this.isDeal || para.MaintainAt) {
            para.MaintainAt = (0 | para.MaintainAt / 1000) // 小数转换为整数
          }
          if (this.isDeal || para.AlarmAt) {
            para.AlarmAt = (0 | para.AlarmAt / 1000) // 小数转换为整数
          }
          if (this.isDeal && para.FormType !== 1) { // 不是运维单则提交时Status改为1
            para.Status = 1
          } else if (this.isDeal && para.Status === 3) { // 若处理时Status未手动更改，则将默认状态的 3 改为 2
            para.Status = 2
          }
          para.CreateAt = (0 | this.ruleForm.CreateAt / 1000)
          updateMaintenance(para).then(res => {
            this.$message({
              message: '提交成功',
              type: 'success'
            })
            this.$router.go(-1);
          }).catch(err => {
            this.$message.error(err)
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handlePreview(url) {
      this.showImg = true
      this.dialogImageUrl = url
    },
    handleAvatarSuccess(res) {
      this.ruleForm.MaintenancePics.push({MaintenanceId: this.ruleForm.Id, PicUrl: res.Data})
    },
    handleExceed() {
      this.$message.warning(`照片数量限制在4张`)
    },
    handleRemove(url) {
      console.log(this.ruleForm)
      let i = this.ruleForm.MaintenancePicS.findIndex(e => e.PicUrl === url)
      let j = this.fileList.findIndex(e => e === url)
      if (i !== -1) {
        this.ruleForm.MaintenancePics.splice(i, 1)
        this.$refs.upload.uploadFiles.splice(i, 1)
      } else if (j !== -1) {
        this.fileList.splice(j, 1)
      } else {
        this.$message.error('删除失败！')
      }
      this.showImg = false
      this.hasChange()
    },
    //获取定位
    getLocation() {
      //正在获取定位信息
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      //AMap.Geolocation插件获取用户的当前位置，并根据结果更新表单中的位置信息
      const _this = this;
      AMap.plugin('AMap.Geolocation', function () {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
        })
        geolocation.getCurrentPosition()
        AMap.event.addListener(geolocation, 'complete', onComplete);
        AMap.event.addListener(geolocation, 'error', onError);


        function onComplete(data) {
          // data是具体的定位信息
          console.log(data)
          _this.ruleForm.Lng = data.position.lng.toString()
          _this.ruleForm.Lat = data.position.lat.toString()
          _this.ruleForm.Position = data.formattedAddress
          loading.close();
        }

        function onError(data) {
          // 定位出错
          console.log('定位失败错误：', data);
          // 调用ip定位
          _this.getLngLatLocation()
          loading.close();

        }
      })
    },
    getLngLatLocation() {
      const _this = this
      AMap.plugin('AMap.CitySearch', function () {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity(function (status, result) {
          if (status === 'complete' && result.info === 'OK') {
            // 查询成功，result即为当前所在城市信息
            console.log('通过ip获取当前城市：', result);
            _this.ruleForm.Position = result.province + result.city;
            //逆向地理编码
            AMap.plugin('AMap.Geocoder', function () {
              var geocoder = new AMap.Geocoder({
                // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                city: result.adcode
              })

              var lnglat = result.rectangle.split(';')[0].split(',');
              geocoder.getAddress(lnglat, function (status, data) {
                if (status === 'complete' && data.info === 'OK') {
                  // result为对应的地理位置详细信息
                  _this.ruleForm.Position = data.regeocode.formattedAddress
                }
              })
            })
          }
        })
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-dialog__body {
  padding: 0
}

.van-cell {
  padding: 0;
  height: 100%;
}
</style>
