<!--数据统计主界面-->
<template>
  <div class="bg01">
    <Head title="数据统计"></Head>
    <el-row class="mod04">
<!--      背景图-->
      <el-col :span="24">
        <img src="../assets/images/banner01.png" alt=""/>
      </el-col>
<!--Maintainer维保人员-->
      <el-col :span="8" v-if="role !== 'Maintainer'">
        <el-button type="text" @click="handleJump('/statisticsOnLine')">
          <img src="../assets/images/icon07.png" alt=""/>
          <h1>在线达标率统计</h1>
        </el-button>
      </el-col>
      <el-col :span="8" v-if="role !== 'Maintainer'">
        <el-button
            type="text"
            @click="handleJump('/statisticsRealTime', { type: 1 })"
        >
          <img src="../assets/images/icon06.png" alt=""/>
          <h1>实时浓度</h1>
        </el-button>
      </el-col>
      <el-col :span="8" v-if="role !== 'Maintainer'">
        <el-button type="text" @click="handleJump('/statisticsReduce')">
          <img src="../assets/images/icon05.png" alt=""/>
          <h1>油烟减排统计</h1>
        </el-button>
      </el-col>
      <el-col :span="8">
        <el-button type="text" @click="handleJump('/troubleTicket')">
          <img src="../assets/images/icon09.png" alt=""/>
          <h1>运维</h1>
        </el-button>
      </el-col>
      <el-col :span="8">
        <el-button type="text" @click="handleJump('/statisticsSim')">
          <img src="../assets/images/icon12.png" alt=""/>
          <h1>SIM卡号变更</h1>
        </el-button>
      </el-col>
      <el-col :span="8">
        <el-button type="text" @click="handleJump('/locale-info')">
          <img src="../assets/images/icon11.png" alt=""/>
          <h1>监测点信息上传</h1>
        </el-button>
      </el-col>
<!--      2024/8月29隐藏掉这个按钮-->
<!--      <el-col :span="8">-->
<!--        <el-button type="text" @click="handleJump('/info-investigation')">-->
<!--          <img src="../assets/images/icon10.png" alt=""/>-->
<!--          <h1>信息勘察</h1>-->
<!--        </el-button>-->
<!--      </el-col>-->
    </el-row>
<!--    底部公共区域-->
    <Foot></Foot>
  </div>
</template>

<script>
import Head from "@/components/Head";
import Foot from "@/components/Foot";
import {handleJump} from "../utils/utils";

export default {
  components: {Head, Foot},
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      role: ''
    };
  },
  //挂载完成取出这个本地的role
  mounted() {
    this.role = sessionStorage.getItem('role');
  },
  methods: {
    handleJump
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
