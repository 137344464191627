<!---->
<template>
  <div class="bg01">
    <Head title="处理"></Head>
    <el-form
        :model="ruleForm"
        :rules="rules"
        :hide-required-asterisk="true"
        ref="ruleForm"
        label-width="100px"
        label-position="left"
        class="form02"
        :disabled="isLook">
      <el-form-item label="监测点名称">
        <el-input v-model="ruleForm.LocaleName" disabled></el-input>
      </el-form-item>
      <el-form-item label="监测点地址">
        <el-input v-model="ruleForm.Locale.Addr" disabled></el-input>
      </el-form-item>
      <el-form-item label="设备编号">
        <el-input v-model="ruleForm.MN" disabled></el-input>
      </el-form-item>
      <el-form-item label="派单日期">
        <el-date-picker
            disabled
            type="date"
            value-format="timestamp"
            format="yyyy-MM-dd"
            v-model="ruleForm.CreateAt"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="核实日期">
        <el-date-picker
            disabled
            type="date"
            value-format="timestamp"
            format="yyyy-MM-dd"
            v-model="ruleForm.VerifyAt"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="处理人员">
        <el-input v-model="ruleForm.MaintainerName" disabled></el-input>
      </el-form-item>

      <el-form-item label="核实情况" prop="Cause">
        <el-input
            :disabled="isLook"
            placeholder="输入核实情况"
            type="textarea"
            autosize
            v-model.trim="ruleForm.Cause"
        />
      </el-form-item>
      <el-form-item label="核实结果" prop="isTrue" v-if="isDeal">
        <el-radio-group v-model="ruleForm.isTrue">
          <el-radio :label="true">属实</el-radio>
          <el-radio :label="false">不属实</el-radio>
        </el-radio-group>
      </el-form-item>

      <!--      2024/7/10 新增红码原因和整改期限-->
      <el-form-item label="红码原因" prop="redCause">
        <el-select
            clearable
            style="width: 100%"
            v-model="ruleForm.redCause"
            placeholder="请选择红码原因">
          <el-option label="净化器需清洗" value="净化器需清洗"></el-option>
          <el-option label="净化器故障" value="净化器故障"></el-option>
          <el-option label="净化器效率低" value="净化器效率低"></el-option>
          <el-option label="净化器未使用" value="净化器未使用"></el-option>
          <el-option label="店家歇业" value="店家歇业"></el-option>
          <el-option label="店家停业" value="店家停业"></el-option>
          <el-option label="店铺线路故障" value="店铺线路故障"></el-option>
          <el-option label="设备原因" value="设备原因"></el-option>

        </el-select>
      </el-form-item>
      <el-form-item label="整改期限" >
        <el-select
            clearable
            style="width: 100%"
            v-model="value"
            placeholder="请选择整改期限">
          <el-option
              v-for="item in timeLimit"
              :value="item.value"
              :key="item.value"
              :label="item.label+'天'"/>
        </el-select>
      </el-form-item>
      <el-form-item label="照片浏览" v-if="!isDeal">
        <van-image
            width="100"
            height="100"
            fit="contain"
            v-for="(item,index) of this.fileList"
            :key="index"
            :src="item"/>
      </el-form-item>
      <el-form-item label="添加照片" prop="files" v-if="isDeal">
        <van-uploader
            :after-read="afterRead"
            @delete="deleteFile"
            v-model="ruleForm.files"/>
      </el-form-item>
      <el-form-item label="定位信息" prop="Position">
        <el-button
            v-if="isDeal && ruleForm.Position === ''"
            type="text"
            icon="el-icon-map-location"
            @click="getLocation">获取定位
        </el-button>
        <div v-else>
          {{ ruleForm.Position }}
        </div>
      </el-form-item>

      <el-form-item v-if="!isLook" label-width="0">
        <el-row style="margin: 20px 10px">
          <van-button class="btn01" style="width: 20%;margin-right: 20px" type="primary"
                      @click.prevent="submitForm('ruleForm')">
            提交
          </van-button>
          <van-button class="btn01" style="width: 20%" type="primary" @click.prevent="goOff()">取消</van-button>
        </el-row>

      </el-form-item>
    </el-form>

  </div>
</template>

<script>
import Head from '@/components/Head';
import {listMaintenance, updateHealthCodeRecord, updateMaintenance, uploadImages} from "../utils/api";
import {formatDate} from "../utils/utils";
import {mapState} from 'vuex'
import {CellGroup, Cell, Toast} from 'vant'
import {Post} from "@/utils/http";

export default {
  name: 'RedCodeForm',
  components: {
    Head,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup
  },
  data() {
    return {
      value:3,
      timeLimit: [
        {
          value: 3,
          label: '3'
        },
        {
          value: 4,
          label: '4'
        },
        {
          value: 5,
          label: '5'
        },
        {
          value: 6,
          label: '6'
        },
        {
          value: 7,
          label: '7'
        },
      ],
      isLook: false,
      isDeal: false,
      ruleForm: {
      },
      dialogVisible: false,
      pickerShow: false,
      pickerShow02: false,
      token: undefined,
      changeState: false,
      showImg: false,
      dialogImageUrl: '',
      formData: {},
      fileList: [],
      rules: {
        Cause: [
          {required: true, message: '请填写核实情况', trigger: 'blur'}
        ],
        files: [
          {required: true, message: '请上传照片', trigger: 'blur'}
        ],
        isTrue: [
          {required: true, message: '请选择核实结果', trigger: 'blur'}
        ],
        Position: [
          {required: true, message: '请上传定位信息', trigger: 'blur'}
        ],
        redCause: [
          {required: true, message: '请选择红码原因', trigger: 'blur'}
        ]
      },
    }
  },
  mounted() {
    this.token = sessionStorage.getItem('token')
    this.ruleForm = Object.assign({}, this.$route.params['formData'])
    this.isLook = this.$route.params['isLook'] === 1
    this.isDeal = this.$route.params['isDeal'] === 1
    if(this.ruleForm.VerifyAt == 0) {
      this.ruleForm.VerifyAt = Date.parse(new Date())
    }
    this.$set(this.ruleForm, 'Position', '')
  },
  beforeUnmount() {
    this.ruleForm = {}
  },
  computed: {
    ...mapState(['locale'])
  },
  methods: {
    newLocaleChange(val) {
      this.ruleForm.NewLocaleId = val.LocaleId
      this.ruleForm.NewLocaleName = val.LocaleName
    },
    hasChange() {
      this.changeState = true
    },
    beforeImageChange(file) {
      this.changeState = true
      const imageType = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
      if (!imageType) {
        this.$message.error('请上传jpg、jpeg或png格式的图片！');
      }
      return imageType;
    },
    getToDay() {
      let day = new Date();
      day.setTime(day.getTime());
      let m = day.getMonth() + 1;
      m = m < 10 ? '0' + m : m;
      return `${day.getFullYear()}-${m}-${day.getDate()}`
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let param = {
            Id: this.ruleForm.Id,
            Resolution: this.ruleForm.Resolution,
            Cause: this.ruleForm.Cause,
            Addr: this.ruleForm.Position,
            MaintenancePicS: this.fileList,
            Status: this.ruleForm.isTrue ? 'WAIT_TO_RECTIFY' : 'WAIT_TO_CHECK',
            RectifyReason:this.ruleForm.redCause,
            RectifyDate:this.value,
          }
          updateHealthCodeRecord(param).then(res => {
            this.$message({
              message: '处理成功',
              type: 'success'
            })
            this.$router.go(-1);
          }).catch(e => {
            this.$message({
              message: e.data.Msg,
              type: 'error'
            })
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handlePreview(url) {
      this.showImg = true
      this.dialogImageUrl = url
    },

    handleExceed() {
      this.$message.warning(`照片数量限制在4张`)
    },

    getLocation() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      const _this = this;
      AMap.plugin('AMap.Geolocation', function () {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
        })
        geolocation.getCurrentPosition()
        AMap.event.addListener(geolocation, 'complete', onComplete);
        AMap.event.addListener(geolocation, 'error', onError);

        function onComplete(data) {
          // data是具体的定位信息
          console.log(data)
          _this.ruleForm.Lng = data.position.lng.toString()
          _this.ruleForm.Lat = data.position.lat.toString()
          _this.ruleForm.Position = data.formattedAddress;
          loading.close();
        }

        function onError(data) {
          // 定位出错
          console.log('定位失败错误：', data);
          loading.close();
          // 调用ip定位
          _this.getLngLatLocation();
        }
      })
    },
    afterRead(file) {
      const fileN = file.content;
      const blob = this.dataURLtoBlob(fileN)
      let formData = new FormData()
      formData.set('file', blob)
      Post('http://116.62.234.187:8088/uploadToOss', formData).then(res => {
        this.fileList.push(res)
      })
    },
    //将base64转换为blob
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {type: mime});
    },
    deleteFile(file, detail) {
      this.fileList.splice(detail.index, 1)
    },
    getLngLatLocation() {
      const _this = this
      AMap.plugin('AMap.CitySearch', function () {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity(function (status, result) {
          if (status === 'complete' && result.info === 'OK') {
            // 查询成功，result即为当前所在城市信息
            console.log('通过ip获取当前城市：', result);
            // let point = result.rectangle.split(';')[1].split(',');
            _this.ruleForm.Position = result.province + result.city;
            loading.close();
            // _this.ruleForm.position[0] = parseFloat(point[0]);
            // _this.ruleForm.position[1] = parseFloat(point[1]);
            //逆向地理编码
            AMap.plugin('AMap.Geocoder', function () {
              var geocoder = new AMap.Geocoder({
                // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                city: result.adcode
              })

              var lnglat = result.rectangle.split(';')[0].split(',');
              geocoder.getAddress(lnglat, function (status, data) {
                if (status === 'complete' && data.info === 'OK') {
                  // result为对应的地理位置详细信息
                  // console.log(data.regeocode.formattedAddress)
                }
              })
            })
          }
        })
      })
    },
    goOff() {
      this.$router.go(-1)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-dialog__body {
  padding: 0
}

.van-cell {
  padding: 0;
  height: 100%;
}

.red-code-form-btn {
  width: 30%;
}

</style>
