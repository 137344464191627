import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        pagePosition: {},
        props: {
            label: 'Name',
            key: 'Id',
            value: 'Id',
            expandTrigger: 'hover',
            children: 'children'
        },
        locale: [],
        maintainerId: '',
        customer: [],
        customerTree: [],
        areaTree: []
    },
    mutations: {
        setLocale(state, payload) {
            state.locale = payload
        },
        setPagePosition(state, payload) {
            state.pagePosition = {
                FormType: payload.FormType,
                StartAt: 0,
                Size: parseInt(payload.formIndex / 10 + 1) * 10,
                Page: payload.Page,
                Index: payload.formIndex,
                Id: payload.Id
            }
        },
        clearPagePosition(state) {
            state.pagePosition = {}
        },
        setMaintainerId(state, data) {
            state.maintainerId = data
        },
        setCustomer: (state, data) => {
            state.customer = data
        },
        setCustomerTree: (state, data) => {
            state.customerTree = data
        },
        setAreaTree: (state, data) => {
            state.areaTree = data
        },
    },
    actions: {
        setMaintainerId({commit, context}) {
            console.log(context)
            commit('setMaintainerId', context)
        },
        setCustomer: ({commit}, data) => {
            commit('setCustomer', data)
        },
        setCustomerTree: ({commit}, data) => {
            commit('setCustomerTree', data)
        },
        setAreaTree: ({commit}, data) => {
            commit('setAreaTree', data)
        },
    },
    modules: {}
})
