<!--综合管理-->
<template>
  <div>
<!--    引入组件头部-->
    <Head title="综合管理" :notShowLeft="true" :showLeftArr="options" @handleHead="handleHead"></Head>
    <div id="map"></div>
<!--    引入组件的数据-->
    <index-dropdown :mapType="mapType" :locale="locale" :dTotal="dTotal" :mTotal="mTotal" :daily="daily" :newData="newData"></index-dropdown>
    <dialog-index-map v-if="dialogVisible" :dataObj="obj" @handleMapClose="handleMapClose" @handleMapDialog="handleMapDialog"></dialog-index-map>
    <dialog-detail :showDialog.sync="showDialogDetails"></dialog-detail>
    <dialog-realTime :showDialog.sync="showDialogRealTime"></dialog-realTime>
    <dialog-echarts :showDialog.sync="showDialogEcharts"></dialog-echarts>
    <dialog-echarts30 :showDialog.sync="showDialogEcharts30"></dialog-echarts30>
    <el-button round class="btn03 btn07" @click="addressPickerShow = true" :class="{fixed : addressPickerShow}">{{pickerCity}}</el-button>
    <wv-picker
      :visible.sync="addressPickerShow"
      v-model="address"
      ref="addressPicker"
      :columns="addressColumns"
      :visible-item-count="5"
      @change="onAddressChange"
      @confirm="confirmAddress"
      @cancel="cancelAddress"
    />
    <Foot :indexRole="role" v-show="true"></Foot>
  </div>
</template>

<script>
import Head from '@/components/Head';
import Foot from '@/components/Foot';

import NORMAL from "@/assets/images/icon04.png";
import EXCESS from "@/assets/images/icon02.png";
import DOWN from "@/assets/images/icon03.png";
import OFF from "@/assets/images/icon01.png";

import { listMapDevice, listBasicData, login, listMonitorDevice } from "../utils/api";
import { formatDate, codeData, getCities, getAreas, treeFilter } from "../utils/utils";
import {
  detailPubSub,
  minutePubSub,
  echartsPubSub,
  echartsMonthPubSub,
} from "@/service/pubsub/index";

import IndexDropdown from '@/components/IndexDropdown';
import DialogIndexMap from '@/components/DialogIndexMap';
import DialogDetail from '@/components/DialogDetail';
import DialogRealTime from '@/components/DialogRealTime';
import DialogEcharts from '@/components/DialogEcharts';
import DialogEcharts30 from '@/components/DialogEcharts30';

export default {
  components: {
    Head,
    Foot,
    IndexDropdown,
    DialogIndexMap,
    DialogDetail,
    DialogRealTime,
    DialogEcharts,
    DialogEcharts30,
  },
  data () {
    return {
      newData:{},//2024年6月24修改首页数据
      msg: 'Welcome to Your Vue.js App',
      options: [
        {
          value: 2,
          label: '监测仪'
        },
        {
          value: 1,
          label: '监控仪'
        }
      ],
      value: '',
      obj: {},
      markerPos: [],
      dialogVisible: false,
      slideUp: false,
      areaCode: '',
      addressPickerShow: false,
      address: [],
      id: -1,
      provinces: {},
      addressColumns: [
        {
          values: []
        },
        {
          values: getCities('北京市')
        },
        {
          values: getAreas('北京市', '市辖区')
        }
      ],
      param: {},
      customer: [],
      daily: {},
      device: [],
      area: [],
      locale: [],
      user: [],
      dTotal: 0,
      mTotal: 0,
      mapType: 2,
      chinaAreaData: {},
      pickerCity: undefined,
      mapPosition: [119.9807, 30.27386],
      role: undefined,
      showDialogDetails: false,
      showDialogRealTime: false,
      showDialogEcharts: false,
      showDialogEcharts30: false,
      loading: true,
      startAt: 0,
      size: 200
    }
  },
  mounted() {
    // this.areaCode = areaData.Nodes;
    this.loading = true;
    if (this.mapType === 2){
      this.getData();
    } else {
      this.getDataThree();
    }
    this.getDataTwo();
    if(sessionStorage.getItem('login') && !sessionStorage.length < 6){
      this.getLogin(JSON.parse(sessionStorage.getItem('login')))
    }
  },
  filters: {
    dialogStatus(v) {
      let obj = {
        NORMAL: '达标',
        EXCESS: '超标',
        DOWN: '异常',
        OFF: '离线',
      };
      return obj[v]
    },
  },
  methods: {
    getData() {
      listMapDevice().then(res => {
        this.daily = res.Daily;
        this.newData=res
        this.device = res.Device;
        res.Device.forEach(v=>{
          if (v.Typ === 1) {
            this.mTotal++
          } else {
            this.dTotal++
          }
          // console.log(this.mTotal, this.dTotal)
        });
      }).catch(err => {
        console.log(err)
        this.$message.error(JSON.stringify(err));
      });
    },
    getDataTwo() {
      listBasicData({StartAt: this.startAt++ * this.size, Size: this.size}).then(res => {
        if (res.Area.length === 0 && res.Locale.length === 0 && res.Customer.length === 0 && res.User.length === 0) {
          this.aMapInit();
          //return this.processBasicData()
        }
        setTimeout(this.getDataTwo, 100)
        this.locale = res.Locale.length > 0 ? this.locale.concat(res.Locale) : this.locale
        this.area = res.Area.length > 0 ? this.area.concat(res.Area) : this.area
        this.customer = res.Customer.length > 0 ? this.customer.concat(res.Customer) : this.customer
        this.user = res.User.length > 0 ? this.user.concat(res.User) : this.user
      }).catch(err => {
        console.log(err)
      });
    },
    processBasicData () {
      console.log('process')
      const tmp = {}, tree = [];
      for (let i = 0, len = this.area.length; i < len; i++) {
        tmp[`${this.area[i]['Id']}`] = this.area[i]
      }
      let arr;
      this.area.forEach(item => {
        if (item['Pid'] !== '') {
          arr = item['Pid'].substring(1, item['Pid'].length - 1).split('/');
          const tid = arr[arr.length - 1];
          if (tmp[tid]) {
            if (tmp[tid].Nodes === undefined) {
              tmp[tid].Nodes = []
            }
            tmp[tid].Nodes.push(item)
          } else {
            tree.push(item)
          }
        } else {
          tree.push(item)
        }
      });
      this.areaCode = tree[0];
      this.chinaAreaData = codeData(this.areaCode, '0');
      this.addressColumns[0].values = Object.values(this.chinaAreaData[0]);
      this.addressColumns[1].values = getCities(this.addressColumns[0].values[0]);
      this.addressColumns[2].values = getAreas(this.addressColumns[0].values[0], this.addressColumns[1].values[0]);
      this.getCode(this.addressColumns[0].values[0], this.addressColumns[1].values[0], this.addressColumns[2].values[0]);
      let customerTree = treeFilter(this.customer);
      sessionStorage.setItem('customerTree', JSON.stringify(customerTree));
      this.loading = false;
    },
    getLogin(param) {
      login(param).then(res => {
        sessionStorage.setItem('token', res.Token);
        sessionStorage.setItem('role', res.Role);
        sessionStorage.setItem('name', res.Name);
        sessionStorage.setItem('Owner', res.Owner.replace(/\//ig, 'n'));
        this.role = res.Role;
      }).catch(err => {
        console.log(err)
      });
    },
    handleHead(data) {
      this.mapType = data;
      if (data === 2){
        this.getData();
      } else {
        this.getDataThree();
      }
      this.getDataTwo();
      // this.aMapInit();
    },
    //加载地图
    aMapInit() {
      let _this = this;
      let map = new AMap.Map('map', {
        resizeEnable: true,
        center: _this.mapPosition,
        zoom: 13
      });
      AMap.plugin([
        'AMap.ToolBar',
        'AMap.Geolocation',
      ], function(){
        map.addControl(new AMap.ToolBar());
        // map.addControl(new AMap.Geolocation());
      });
      let lnglats = [];
      this.device.forEach(v=>{
        if (v.Typ === this.mapType && v.Lng !== ''){
          this.locale.forEach(vv=>{
            if(v.Id === vv.Id){
              v.name = vv.Name;
              v.FanSpeed = vv.FanSpeed;
              v.PipeArea = vv.PipeArea;
              v.StoveNum = vv.StoveNum;
              v.StoveArea = vv.StoveArea;
              v.LinkStatus = vv.LinkStatus;
              v.FanStatus = vv.FanStatus;
            }
          });
          v.position = [];
          v.position[0] = parseFloat(v.Lng);
          v.position[1] = parseFloat(v.Lat);
          // console.log(v.position)
          v.date = formatDate(v.AcquitAt);
          if (v.position[0] > 0 && v.position[1] > 0) lnglats.push(v);
        }
      });
      let markerContent = {
        NORMAL: `<img src=${NORMAL}>`,
        EXCESS: `<img src=${EXCESS}>`,
        DOWN:  `<img src=${DOWN}>`,
        OFF: `<img src=${OFF}>`,
      };
      for (let i = 0; i < lnglats.length; i++) {
        this.marker = new AMap.Marker({
          position: lnglats[i].position,
          content: markerContent[lnglats[i].Status],
          map: map
        });
        this.marker.content = JSON.stringify(lnglats[i]);
        this.marker.id = lnglats[i].Id;
        this.marker.on('click', markerClick);
      }
      this.processBasicData()
      function markerClick(e) {
        _this.obj = JSON.parse(e.target.content);
        _this.markerPos = e.target.getPosition();
        _this.dialogVisible = true;
        // console.log(_this.obj)
        // infoWindow.setContent(e.target.content);
        // infoWindow.open(map, e.target.getPosition());
      }
      map.setFitView(_this.mapPosition);
    },
    //每当用户滚动地址选项时触发
    onAddressChange (picker, addressValues, slotIndex) {
      if (slotIndex === 0) {// 省份发生变化，获取该省份下的数据
        const cities = getCities(addressValues[0]);//传入当前的省份信息，调用函数获取当前省份下的城市列表
        picker.setColumnValues(1, cities);// 设置当前省份下的市区数据设置市区列表
        //传入当前省份下的市区列表，调用getAreas函数，拿到当前市下的县信息，调用setColumnValues，根据索引设置县的信息
        picker.setColumnValues(2, getAreas(addressValues[0], cities[0]))
      } else if (slotIndex === 1) {//市改变时，获取该市下的数据
        //传入当前市下的县信息，调用getAreas函数，拿到当前市下的县 传入当前市下的县信息作为第二个参数，调用setColumnValues设置数据
        picker.setColumnValues(2, getAreas(addressValues[0], addressValues[1]))
      }
    },
    //当用户确认选择一个地址后触发
    confirmAddress (picker) {
      this.getCode(picker.getValues()[0], picker.getValues()[1], picker.getValues()[2], true);
    },
    //当用户取消地址选择时触发
    cancelAddress() {
      this.getCode(this.addressColumns[0].values[0], this.addressColumns[1].values[0], '', true);
    },
    //pro提供省份，city为市，area为县，bool为是否触发事件
    getCode(pro, city, area, bool) {
      this.pickerCity = city;
      let proCode, cityCode, areaCode;
      this.area.forEach(v=>{
        if(v.Name === pro){
          proCode = v.Id
        }
        if(v.Name === city){
          cityCode = v.Id
        }
        if(v.Name === area){
          areaCode = v.Id;
          if (v.Lng === '') {
            this.$toast.text({
              duration: 1000,
              message: '经纬度没有设置'
            });
          } else {
            this.mapPosition[0] = parseFloat(v.Lng);
            this.mapPosition[1] = parseFloat(v.Lat);
          }
        }
      });
      let key = '/1/' + proCode + '/' + cityCode  + '/' + areaCode + '/';
      let locales  = this.locale.filter(v =>v.AreaId === key);
      locales = locales.map(v=>{
        return v.Id;
      });
      if (bool) {
        this.mTotal = 0;
        this.dTotal = 0;
        listMapDevice({locales: locales}).then(res => {
          this.daily = res.Daily;
          this.device = res.Device;
          res.Device.forEach(v=>{
            if (v.Typ === 1) {
              this.mTotal++
            } else {
              this.dTotal++
            }
          });
          this.aMapInit();
        }).catch(err => {
          console.log(err)
        });
      }
    },
    getDataThree() {
      listMonitorDevice({}).then(res => {
        this.daily.onlineCount = res.onlineCount;
        this.daily.offCount = res.offCount;
        this.daily.offPercent = res.offPercent;
        this.daily.onPercent = res.onPercent;

        this.device = res.device.map((v) => {
          return v.Locale;
        });

        res.device.forEach((e) => {
          if (e.Typ === 1) {
            this.mTotal += 1;
          } else {
            this.dTotal += 1;
          }
        });
      }).catch(err => {
        console.log(err)
        this.$message.error(JSON.stringify(err));
      });
    },
    handleMapClose() {
      this.dialogVisible = false;
    },
    //地图缩放、标记、移动触发这个函数
    handleMapDialog(obj) {
      // console.log(obj);
      switch (obj.key) {
        case 'showDialogDetails':
          detailPubSub.publish(obj.data);
          this[obj.key] = true;
          break;
        case 'showDialogRealTime':
          minutePubSub.publish(obj.data);
          this[obj.key] = true;
          break;
        case 'showDialogEcharts':
          echartsPubSub.publish(obj.data);
          this[obj.key] = true;
          obj.data.i = 2;
          break;
        case 'showDialogEcharts30':
          echartsMonthPubSub.publish(obj.data);
          this[obj.key] = true;
          break;
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  #map{
    height: calc(100vh - 106px);
  }
  .amap-container img{
    width: 16px;
  }
</style>
