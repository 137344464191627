<!--报警处理的异常离线处理-->
<template>
  <div class="bg01">
    <el-dialog
        :title="param.Typ ===1? '报警处理':'异常离线处理'"
        :visible.sync="dialogVisible"
        v-if="dialogVisible"
        width="100%"
        top="0px"
        @close="handleClose"
        center>
      <list-box ref="listBox" @load="load">
        <li v-for="(v, i) in list" :key="i">
          <div class="h1">
            <div style="width: 60%;"><b>编号：{{ v.MN | short }}</b></div>
          </div>
          <el-row class="h2">
            <el-col :span="8">异常日期
              <div>{{ formatDate(v.AcquitAt) }}</div>
            </el-col>
            <el-col :span="8">处理日期
              <div>{{ v.ProcessAt === 0 ? '' : formatDate(v.ProcessAt) }}</div>
            </el-col>
            <el-col :span="8">处理人
              <div>{{ v.ProcessUserName }}</div>
            </el-col>
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">原因及处理结果
              <div>{{ v.ProcessReason | reason }}</div>
            </el-col>
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">处理地点
              <div>{{ v.Addr }}</div>
            </el-col>
          </el-row>
          <div>
            <el-button plain size="mini" class="btn04"
                       @click="handleFormDialog({id: v.Id, typ: param.Typ , type: type})"
                       v-if="v.ProcessAt === 0">处理
            </el-button>
          </div>
        </li>
      </list-box>

    </el-dialog>
  </div>
</template>

<script>
import Head from '@/components/Head';
import Foot from '@/components/Foot';
import ListBox from '@/components/ListBox';

import {handleJump, formatDate} from "../utils/utils";
import {listAlarmRecord} from "../utils/api";
import showDialog from "@/minxs/showDialog";
import AlarmForm from "@/views/AlarmForm.vue";

export default {
  components: {AlarmForm, Head, Foot, ListBox},
  data() {
    return {
      type: 2,
      param: {
        StartAt: 0,
        Size: 10,
        Typ: undefined,
        Param: {
          mn: undefined,
        }
      },
      list: [],
      total: 0,
      page: 1,
      loading: false,
      marker: [],
      formVisible: false,
      formData: {}
    }
  },
  props: ['listData'],
  mixins: [showDialog],
  mounted() {
  },
  watch: {
    listData: {
      handler(val) {
        let obj = val
        this.type = obj.type;
        this.param.Typ = obj.typ;
        this.param.Param.LocaleId = obj.LocaleId;
        this.getList();
      }
    }
  },
  filters: {
    short(v) {
      return v.substr(16, 8)
    },
    dialogStatus(v) {
      let obj = {
        Green: 'type01',
        Red: 'type03',
        Yellow: 'type04',
      };
      return obj[v]
    },
    reason(v) {
      const obj = {
        ShopClosed: '店家歇业(装修)',
        DetectorFailure: '监测仪故障',
        OfflineCausedByShop: '店家原因导致离线',
      };
      return obj[v];
    },
  },
  methods: {
    formatDate,
    handleJump,
    getList() {
      listAlarmRecord(this.param).then(res => {
        this.list = this.page === 1 ? res.content : this.list.concat(res.content);
        this.$refs.listBox.total = res.total;
      }).catch(err => {
        console.error(err)
      });
    },
    load() {
      this.$refs.listBox.loading = true;
      setTimeout(() => {
        this.page++;
        this.param.StartAt = this.page * this.param.Size;
        this.getList();
        this.$refs.listBox.loading = false
      }, 2000)
    },
    handleFormDialog(data) {
      this.$emit('openForm',data)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
