<!--我的主界面-->
<template>
  <div class="bg01">
    <Head title="个人中心" :notShowLeft="true"></Head>
    <el-row class="mod05">
      <el-col :span="24">
<!--        消息通知-->
        <el-badge :is-dot="hasNew" class="item">
          <el-button type="text" @click="handleJump('/message')"><span class="iconfont">&#xe63f;</span></el-button>
        </el-badge>
<!--        头像展示-->
        <el-avatar :size="60" src="../assets/images/icon08.png">
          <img src="../assets/images/icon08.png"/>
        </el-avatar>
<!--        展示名称-->
        <h1>{{name}}</h1>
      </el-col>
    </el-row>
<!--  功能区域展示  -->
    <el-row class="mod06">
      <el-col :span="24">
        <wv-group>
          <wv-cell title="店铺状态" value="正常营业中" is-link></wv-cell>
          <wv-cell title="个人资料" is-link @click.native="handleJump('/info')"></wv-cell>
        </wv-group>
        <wv-group>
          <wv-cell title="账户安全" is-link @click.native="handleJump('/account')"></wv-cell>
          <wv-cell title="客服电话" is-link>
            <span slot="ft"><a href="tel:0571-86222911">0571-86222911</a></span>
          </wv-cell>
          <wv-cell title="油烟健康码" is-link @click.native="handleJump('/qrcode')"></wv-cell>
        </wv-group>
      </el-col>
    </el-row>
<!--    底部组件-->
    <Foot></Foot>
  </div>
</template>

<script>
import Head from '@/components/Head';
import Foot from '@/components/Foot';
import { handleJump } from "../utils/utils";
import { listAlarmMsg } from "../utils/api";

export default {
  components: { Head, Foot },
  data () {
    return {
      msg: 'Welcome to Your Vue.js App',
      name: sessionStorage.getItem('name'),
      hasNew: false,
      param: {
        StartAt: 0,
        Size: 10,
        Param: {},
      },
    }
  },
  //挂载完成执行getData
  mounted() {
    this.getData();
  },
  methods: {
    handleJump,
    getData() {
      listAlarmMsg(this.param).then(res => {
        if (!sessionStorage.getItem('msgTotal')) {
          sessionStorage.setItem('msgTotal', res.total)
        } else if(res.total > parseInt(sessionStorage.getItem('msgTotal'))) {
          this.hasNew = true;
          sessionStorage.setItem('msgTotal', res.total)
        }
      }).catch(err => {
        console.log(err)
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
