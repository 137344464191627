import Vue from "vue";
import VueRouter from "vue-router";
import Index from "@/views/Index";
import Login from "@/views/Login";
import RealTime from "@/views/RealTime";
import RealTimeMinute from "@/views/RealTimeMinute";
import RealTimeEcharts from "@/views/RealTimeEcharts";
import Alarm from "@/views/Alarm";
import AlarmList from "@/views/AlarmList";
import AlarmEcharts from "@/views/AlarmEcharts";
import AlarmForm from "@/views/AlarmForm";
import Statistics from "@/views/Statistics";
import StatisticsOnLine from "@/views/StatisticsOnLine";
import StatisticsReduce from "@/views/StatisticsReduce";
import Center from "@/views/Center";
import TroubleTicket from "@/views/TroubleTicket";
import TroubleTicketForm from "@/views/TroubleTicketForm";
import Info from "@/views/Info";
import Account from "@/views/Account";
import ChangePhone from "@/views/ChangePhone";
import ChangePwd from "@/views/ChangePwd";
import Message from "@/views/Message";
import Show from "@/views/Show";
import Qrcode from "@/views/Qrcode";
import QrcodeList from "@/views/QrcodeList";
import OnePointToFiles from "@/views/OnePointToFiles";
import Sim from "@/views/Sim";
import SimForm from "@/views/SimForm";
import InfoInvestigation from "@/views/InfoInvestigation";
import InfoInvestigationFrom from "@/views/InfoInvestigationForm";
import LocaleInfo from "@/views/LocaleInfo";
import LocaleInfoForm from "@/views/LocaleInfoForm";
import RedCodeForm from "@/views/RedCodeForm.vue";
import ComplaintForm from "@/views/RealTime/ComplaintForm.vue";
import MaintenanceForm from "@/views/MaintenanceForm.vue";

Vue.use(VueRouter);

const routes = [
    {
        name: "locale-info-form",
        path: "/locale-info/form",
        component: LocaleInfoForm
    },
    {
        name: "locale-info",
        path: "/locale-info",
        component: LocaleInfo
    },
    {
        name: "info-investigation-form",
        path: "/info-investigation/form",
        component: InfoInvestigationFrom
    },
    {
        name: "locale-investigation",
        path: "/info-investigation",
        component: InfoInvestigation
    },
    {
        path: "/",
        redirect: "/login"
    },
    {
        path: "/login",
        component: Login
    },
    {
        path: "/index",
        component: Index
    },
    {
        path: "/realTime/:data?",//实施检测的路由
        component: RealTime
    },
    {
        path: "/realTimeMinute",
        component: RealTimeMinute
    },
    {
        path: "/realTimeEcharts/:data",
        component: RealTimeEcharts
    },
    {
        name: 'complaint-form',
        path: "/realTime/Complaint",
        component: ComplaintForm
    },
    {
        path: "/alarm",
        component: Alarm
    },
    {
        path: "/alarmList/:data",
        component: AlarmList
    },
    {
        path: "/alarmEcharts/:data",
        component: AlarmEcharts
    },
    {
        path: "/alarmForm/:data",
        component: AlarmForm
    },
    {
        path: "/statistics",
        component: Statistics
    },
    {
        path: "/statisticsOnLine",
        component: StatisticsOnLine
    },
    {
        path: "/statisticsRealTime/:data",
        component: RealTime
    },
    {
        path: "/statisticsReduce",
        component: StatisticsReduce
    },
    {
        path: "/center",
        component: Center
    },
    {
        path: "/troubleTicket",
        component: TroubleTicket,
        meta: {
            keepAlive: true // 需要缓存
        }
    },
    {
        name: "troubleTicketForm",
        path: "/troubleTicketForm",
        component: TroubleTicketForm
    },
    {
        name: "redCodeForm",
        path: "/redCodeForm",
        component: RedCodeForm,
    },
    {
        name:'MaintenanceForm',
        path: "/MaintenanceForm",
        component: MaintenanceForm
    },
    {
        path: "/info",
        component: Info
    },
    {
        path: "/account",
        component: Account
    },
    {
        path: "/changePhone",
        component: ChangePhone
    },
    {
        path: "/changePwd",
        component: ChangePwd
    },
    {
        path: "/message",
        component: Message
    },
    {
        path: "/show",
        component: Show
    },
    {
        path: "/qrcode",
        component: Qrcode
    },
    {
        path: "/qrcodeList/:data",
        component: QrcodeList
    },
    {
        path: "/onePointToFiles/:data",
        component: OnePointToFiles
    },
    {
        path: "/statisticsSim",
        component: Sim
    },
    {
        path: "/SimForm/:data",
        component: SimForm
    }
    // {
    //   path: '/about',
    //   name: 'About',
    //   component: () => import('../views/About.vue')
    // }
];

const router = new VueRouter({
    routes
});

export default router;
