<!--实时监测-曲线-->
<template>
  <div>
    <el-dialog
      title="曲线"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      width="100%"
      top="0px"
      @close="handleClose"
      center>
      <el-row class="mod03">
        <el-col :span="24">
          <ul>
            <li v-if="isDialog">
              <div class="h3">
                <el-date-picker
                  v-model="date"
                  type="date"
                  @change="handleChange"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy/MM/dd"
                  placeholder="选择日期">
                </el-date-picker>
              </div>
            </li>
            <li>
              <realTimeEcharts ref="realTimeEcharts"></realTimeEcharts>
            </li>
          </ul>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import showDialog from '@/minxs/showDialog';
import RealTimeEcharts from './RealTimeEcharts';
import { echartsPubSub } from "@/service/pubsub/index";
import { listTenMinData } from "../utils/api";
import { formatDate, dateParse } from "../utils/utils";

export default {
  components: { RealTimeEcharts },
  data () {
    return {
      param: {},
      arr: [],
      arr02: [],
      arr03: undefined,
      arr04: [],
      arr05: undefined,
      arr06: [],
      arr07: undefined,
      isDialog: false,
      date: undefined,
    }
  },
  mounted() {
    echartsPubSub.unsubscribe();
    echartsPubSub.subscribe(this.getList);
  },
  beforeDestroy() {
    this.arr = [];
    this.arr02 = [];
    this.arr03 = undefined;
    this.arr04 = [];
    this.arr05 = undefined;
    this.arr06 = [];
    this.arr07 = undefined;
  },
  mixins:[showDialog],
  methods: {
    getList(data) {
      console.log(data);
      if (data) {
        this.param.MN = data.mn;
        this.param.At = data.at;
        this.arr03 = data.emissionsSill;
        this.arr05 = data.granuleSill;
        this.arr07 = data.hydrocarbonSill;
        this.isDialog = data.i === 2;
        // this.date = formatDate(data.at);
      }
      this.arr = [];
      this.arr02 = [];
      this.arr04 = [];
      this.arr06 = [];
      listTenMinData(this.param).then(res => {
        if (res && res.length > 0){
          this.date = formatDate(res[0].acquit_at * 600);
          // let midNight = Date.parse(formatDate(res[0].acquit_at * 600).slice(0, 10) + ' 00:00') / (1000 * 60 * 10);
          let midNight = dateParse(formatDate(res[0].acquit_at * 600).slice(0, 10) + ' 00:00').valueOf() / (1000 * 60 * 10);
          for (var i = 0, j = 0; i <= 143; i++) {
            // let obj = {
            //   emissions_conc: 0,
            //   granule_conc: 0,
            //   hydrocarbon_conc: 0,
            //   counter: 1,
            //   acquit_at: res[0].acquit_at - i,
            // };
            // if (midNight + i < res[0].acquit_at || midNight + i > res[res.length - 1].acquit_at) {
            //   let obj = {
            //     emissions_conc: 0,
            //     granule_conc: 0,
            //     hydrocarbon_conc: 0,
            //     counter: 1,
            //     acquit_at: midNight + i,
            //   };
            let obj = {
              emissions_conc: 0,
              granule_conc: 0,
              hydrocarbon_conc: 0,
              counter: 1,
              acquit_at: midNight + i,
            };
            let array = res.filter(v => {
              return v.acquit_at === midNight + i;
            });
            if (array.length > 0) {
              obj = array[0];
            }
            let date = formatDate(obj.acquit_at * 600);
            this.arr02.unshift(date.slice(5, date.length));
            this.arr.unshift((obj.emissions_conc).toFixed(2));
            this.arr04.unshift((obj.granule_conc).toFixed(2));
            this.arr06.unshift((obj.hydrocarbon_conc).toFixed(2));
          }
          this.$refs.realTimeEcharts.drawLine(this.arr, this.arr02, this.arr03, this.arr04, this.arr05, this.arr06, this.arr07);
        } else {
          this.$message.error('暂无数据');
        }
        // console.log(this.arr, this.arr02, this.arr03, this.arr04, this.arr05, this.arr06, this.arr07)
        // console.log(this.$refs.realTimeEcharts)

      }).catch(err => {
        console.log(err);
      });
    },
    handleChange(val) {
      // let date = formatDate(dateParse(this.date).getTime() / 1000);
      // console.log(date);
      let date = val.replace(/\//g, '-');
      this.param.AcquitDate = date.slice(0, 10);
      this.getList();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
