<!--数据统计-运维-->
<template>
  <div class="bg01">
    <Head title="运维处理" :notShowLeft="true" :showLeftArr="options" @handleHead="handleTypChange"></Head>
    <list-box ref="listBox" class="fixed" @load="load" :contentLength="list.length">
      <li class="h1 fixed">
        <van-button size="mini" @click.prevent="drawer = true" class="btn04">
          搜索
        </van-button>
        <van-button size="mini" @click.prevent="handleAdd" class="btn04">
          新增
        </van-button>
      </li>
      <li v-for="(v, i) in list" :key="i" :id="v.Id">
        <div class="h1">
          <div style="width: 60%; text-align: left"><b>{{ v.LocaleName }}</b></div>
        </div>
        <div class="h1">
          <div style="width: 60%">
            {{ v.Addr }}
            <a style="display: inline-block; margin-left: 5px" :href="`tel:${v.Mobile}`"><i
                class="el-icon-phone color009999"></i></a>
            <a style="display: inline-block; margin-left: 5px" @click="handleMap(v)"><i
                class="el-icon-location-outline color009999"></i></a>
          </div>
          <div v-if="v.FormType === 1">派单日期：{{ formatDate(v.CreateAt, false, false) }}</div>
          <div v-else-if="param.FormType === 4">派单日期：{{ formatDate(v.CreateAt, false, false) }}</div>
          <div v-else>报警日期：{{ formatDate(v.AlarmAt, false, false) }}</div>
        </div>
        <div class="h2">
          <el-row>
            <el-col :span="16">
              运维单号：{{ v.Id }}
            </el-col>
            <el-col :span="8" v-if="v.FormType === 1 && param.FormType!== 4">
              设备类型：{{ subtypeFormatter(v.Subtype) }}
            </el-col>
            <el-col :span="8" v-if="v.FormType === 2 && param.FormType!== 4">
              异常状态：{{ AbnormalStatusFormatter(v.AbnormalStatus) }}
            </el-col>
            <el-col :span="8" v-if="v.FormType === 3&& param.FormType!== 4">
              异常状态：{{ v.ErrorDesc }}
            </el-col>
            <!--            红码核实界面显示内容-->
            <el-col :span="8" v-if="param.FormType=== 4">
              处理状态：{{ verifyStatusFormatter(v.Status) }}
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              设备编号：{{ v.MN }}
            </el-col>
            <el-col :span="8" v-if="param.FormType!== 4">
              处理状态：{{ statusFormatter(v.Status) }}
            </el-col>
            <!--            红码核实界面内容-->
            <el-col :span="8" v-if="param.FormType=== 4">
              处理人员：{{ v.Handler }}
            </el-col>
          </el-row>
          <el-row v-if="param.FormType !== 4">
            <el-col :span="16">
              运维人员：{{ v.MaintainerName }}
            </el-col>
            <el-col :span="8" v-if="v.FormType === 1">
              维修类型：{{ typFormatter(v.Typ) }}
            </el-col>
            <el-col :span="8" v-if="v.FormType !== 1 && v.Handler">
              处理人员：{{ v.Handler }}
            </el-col>
          </el-row>
          <!--          红码核实界面显示内容-->
          <el-row v-else>
            <el-col :span="16">
              超标天数：{{ v.ExcessDays }} 天
            </el-col>
            <el-col :span="8">
              不正常使用净化器：{{ v.FilterAbnormallyUsedDays }} 天
            </el-col>
            <el-col :span="8">
              店家原因离线：{{ v.OfflineCausedByShopDays }} 天
            </el-col>
          </el-row>
          <span v-if="param.FormType !== 4">备注：{{ v.Remark }}</span>
        </div>
        <div>
          <el-button plain size="mini" class="btn04" v-if="param.FormType!==4" @click="handleDeal(v, i)"
                     :disabled="v.Status === 1">处理
          </el-button>
          <el-button plain size="mini" class="btn04" v-else @click="handleRedDeal(v, i)"
                     :disabled="v.Status !== 'WAIT_TO_VERIFY'">核实
          </el-button>
          <el-button plain size="mini" class="btn04" @click="handleLook(v, i)"
                     :disabled="v.Status === 'WAIT_TO_VERIFY'">查看
          </el-button>
        </div>
      </li>
    </list-box>
<!--    搜索出来的弹框-->
    <el-drawer
        title="搜索"
        :visible.sync="drawer"
        direction="ttb"
        :with-header="false">
      <el-form class="formDrawer">
        <el-row type="flex" justify="space-between">
          <el-col :span="11">
            <el-form-item>
              <el-select
                  placeholder="操作状态"
                  v-model="filter.Status"
                  clearable
              >
                <el-option
                    v-for="item in param.FormType !==4?statusOptions:redOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item>
              <el-date-picker
                  v-model="filter.CreateAt"
                  type="date"
                  placeholder="选择日期"
                  format="yyyy-MM-dd"
                  value-format="timestamp"
                  clearable
                  style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-cascader
              style="width:100%"
              v-model="filter.Owner"
              placeholder="所属单位"
              :options="customerTree"
              :props="customerProps"
              clearable
              filterable
          />
        </el-form-item>
        <el-form-item>
          <el-button size="mini" class="btn04" style="width: 100%;" @click="handleSearch">
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
    <Foot></Foot>
    <div id="map" style="height: 0;"></div>
  </div>
</template>

<script>
import Head from '@/components/Head';
import Foot from '@/components/Foot';
import ListBox from '@/components/ListBox';
import {handleJump, formatDate, join, treeFilter} from "../utils/utils";
import {listBasicDataOfCustomer, listMaintenance} from "../utils/api";
import {mapState} from 'vuex'
import {Tab, Tabs, Cell, CellGroup, Popup, Icon} from 'vant'
import {Post} from "@/utils/http";

export default {
  components: {
    Head,
    Foot,
    ListBox,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Cell.name]: Cell,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [CellGroup.name]: CellGroup
  },
  data() {
    return {
      tabsData: [{
        Name: '请选择'
      }],
      active: 0,
      tabList: [],
      levelCollect: [],
      options: [
        {
          value: 1,
          label: '运维单处理'
        },
        {
          value: 2,
          label: '设备故障处理'
        },
        {
          value: 3,
          label: '异常离线及数据漂移处理'
        },
        {
          value: 4,
          label: '红码核实'
        }
      ],
      statusOptions: [
        {
          value: 1,
          label: '已完成'
        },
        {
          value: 2,
          label: '未完成'
        },
        {
          value: 3,
          label: '待处理'
        }
      ],
      redOptions: [
        {
          label: '待核实',
          value: 'WAIT_TO_VERIFY'
        },
        {
          label: '待处理',
          value: 'WAIT_TO_HANDLE'
        },
        {
          label: '待审核',
          value: 'WAIT_TO_CHECK'
        },
        {
          label: '已完成',
          value: 'FINISHED'
        }
      ],
      marker: [],
      param: {
        FormType: 1,
        StartAt: 0,
        Size: 10,
        Page: 1,
        Param: {}
      },
      filter: {
        Owner: [],
        OwnerName: null
      },
      list: [],
      dlg: {
        title: '处理',
        visible: false
      },
      changeState: false,
      scrollY: 0,
      formIndex: 0,
      drawer: false,
      customerTree: [],
      ownerTabs: false
    }
  },
  mounted() {
    if (!sessionStorage.getItem('customerTree')) {
      listBasicDataOfCustomer().then(res => {
        const customerTree = treeFilter(res.Customer);
        this.customerTree = customerTree
        sessionStorage.setItem('customerTree', JSON.stringify(customerTree));
      })
    } else {
      this.customerTree = JSON.parse(sessionStorage.getItem('customerTree'))
    }
    this.$store.commit('setLocale', JSON.parse(sessionStorage.getItem('locale')))
    this.getList(this.param)
  },
  activated() {
    if (Object.keys(this.pagePosition).length) {
      this.getList(this.pagePosition)
      let id = this.pagePosition.Id
      this.$nextTick(() => {
        this.$refs.listBox.length = this.list.length
        let element = document.getElementById(id)
        element.scrollIntoView({
          behavior: 'smooth'
        })
        element.className = 'bgc-anime'
        setTimeout(function () {
          element.className = ''
        }, 2500)
      })
      this.param = {
        FormType: this.pagePosition.FormType,
        Page: this.pagePosition.Page,
        Size: 10,
        StartAt: (this.param.Page - 1) * this.param.Size
      }
      this.$store.commit('clearPagePosition')
    } else {
      this.handleRefresh()
    }
  },
  filters: {
    short(v) {
      return v.length > 16 ? v.substr(16, 8) : v
    },
  },
  computed: {
    ...mapState(['pagePosition', 'locale']),
    ...mapState({
      customerProps: state => Object.assign({}, state.props, {label: 'Org', checkStrictly: true})
    })

  },
  methods: {
    handleJump,
    formatDate,
    handleMap(obj) {
      let arr = []
      arr[0] = parseFloat(obj.Locale.Lng);
      arr[1] = parseFloat(obj.Locale.Lat);
      let map = new AMap.Map('map', {
        resizeEnable: true,
        center: arr,
        zoom: 13
      });
      this.marker = new AMap.Marker({
        position: arr,
        content: '',
        map: map
      });
      this.marker.markOnAMAP({
        name: obj.LocaleName,
        position: arr
      })
      // let lnglat = new AMap.LngLat(arr[0], arr[1])
      // let map = new AMap.Map('map', {
      //   resizeEnable: true,
      //   center: arr,
      //   zoom: 13
      // });
      // this.marker = new AMap.Marker({
      //   map: map,
      //   position: lnglat
      // });
      // this.marker.markOnAMAP({
      //   name: obj.LocaleName,
      //   position: lnglat
      // })
    },

    handleTypChange(val) {
      this.param.FormType = val
      this.param.Page = 1
      this.param.StartAt = 0
      this.filter = {}
      this.getList(this.param)
    },
    getList(param) {
      let para = Object.assign({}, param)
      para.Param = {}
      if (this.param.FormType === 4) {
        para.FormType = 1
        if (this.filter.Owner && this.filter.Owner.length > 0) {
          let owner = join(this.filter.Owner)
          para.Param['health_code_maintenance.owner'] = {S: 4, V: owner}
        }
        if (this.filter.Status) {
          para.Param['health_code_maintenance.status'] = {S: 5, V: [this.filter.Status]}
        }

        if (this.filter.CreateAt) {
          para.Param['health_code_maintenance.create_at'] = []
          para.Param['health_code_maintenance.create_at'].push(0 | this.filter.CreateAt / 1000)
          para.Param['health_code_maintenance.create_at'].push((0 | this.filter.CreateAt / 1000) + 86400)
          para.Param['health_code_maintenance.create_at'] = {
            S: 11,
            V: para.Param['health_code_maintenance.create_at'].toString()
          }
        }
        Post('admin/listHealthCodeRecord', para).then(res => {
          this.list = para.Page === 1 ? res.content : this.list.concat(res.content);
          this.$refs.listBox.total = res.total;
        })
      } else {
        if (this.filter.Owner && this.filter.Owner.length > 0) {
          let owner = join(this.filter.Owner)
          para.Param['maintenance.owner'] = {S: 4, V: owner}
        }
        if (this.filter.CreateAt) {
          para.Param['maintenance.create_at'] = []
          para.Param['maintenance.create_at'].push(0 | this.filter.CreateAt / 1000)
          para.Param['maintenance.create_at'].push((0 | this.filter.CreateAt / 1000) + 86400)
          para.Param['maintenance.create_at'] = {S: 11, V: para.Param['maintenance.create_at'].toString()}
        }
        if (this.filter.Status || this.filter.Status === 0) {
          para.Param['maintenance.status'] = {S: 0, V: this.filter.Status.toString()}
        }
        para.Id = this.$store.state.maintainerId
        listMaintenance(JSON.stringify(para)).then(res => {
          this.list = para.Page === 1 ? res.content : this.list.concat(res.content);
          this.$refs.listBox.total = res.total;
        })
      }
    },
    load() {
      this.$refs.listBox.loading = true;
      setTimeout(() => {
        this.param.StartAt += this.param.Size;
        this.param.Page++;
        this.getList(this.param);
        this.$refs.listBox.loading = false
      }, 2000)
    },
    statusFormatter(status) {
      switch (status) {
        case 1:
          return '已完成'
        case 2:
          return '未完成'
        case 3:
          return '待处理'
        default:
          return '未知状态'
      }
    },
    AbnormalStatusFormatter(status) {
      switch (status) {
        case 1:
          return '风机监控异常'
        case 2:
          return '净化器监控异常'
        case 3:
          return '油烟检测传感异常'
        default:
          return ''
      }
    },
    verifyStatusFormatter(status) {
      switch (status) {
        case "WAIT_TO_VERIFY":
          return '待核实'
        case "WAIT_TO_HANDLE":
          return "待处理"
        case "WAIT_TO_CHECK":
          return "待审核"
        case "FINISHED":
          return "已完成"
      }
    },
    subtypeFormatter(subtype) {
      switch (subtype) {
        case 1:
          return '风机设备'
        case 2:
          return '净化设备'
        case 3:
          return '检测仪设备'
        default:
          return '未知类型'
      }
    },
    typFormatter(typ) {
      switch (typ) {
        case 1:
          return '维修'
        case 6:
          return '维保'
        case 2:
          return '移机'
        case 3:
          return '拆机'
        default:
          return '未知类型'
      }
    },
    handleRefresh() {
      this.param.Page = 1
      this.getList(this.param)
    },
    handleDeal(data, index) {
      let para = Object.assign({}, this.param)
      para.Page = 1
      this.$store.commit('setPagePosition', Object.assign({}, {formIndex: index, Id: data.Id}, para))
      data.MaintainAt = (data.Status === 3 || data.MaintainAt === 0) ? new Date().getTime() : data.MaintainAt
      this.$router.push({name: 'troubleTicketForm', params: {formData: data, isLook: 0, isDeal: 1}})
    },
    handleRedDeal(data, index) {
      let para = Object.assign({}, this.param)
      para.Page = 1
      this.$store.commit('setPagePosition', Object.assign({}, {formIndex: index, Id: data.Id}, para))
      data.MaintainAt = (data.Status === 3 || data.MaintainAt === 0) ? new Date().getTime() : data.MaintainAt
      this.$router.push({name: 'redCodeForm', params: {formData: data, isLook: 0, isDeal: 1}})
    },
    handleLook(data, index) {
      let para = Object.assign({}, this.param)
      para.Page = 1
      this.$store.commit('setPagePosition', Object.assign({}, {formIndex: index, Id: data.Id}, para))
      if (this.param.FormType !== 4) {
        this.$router.push({name: 'troubleTicketForm', params: {formData: data, isLook: 1, isDeal: 0}})
      } else {
        this.$router.push({name: 'redCodeForm', params: {formData: data, isLook: 1, isDeal: 0}})
      }
    },
    //搜索功能
    handleSearch() {
      this.param = Object.assign({}, {
        StartAt: 0,
        FormType: this.param.FormType,
        Size: 10,
        Page: 1
      })
      this.getList(this.param)
      this.drawer = false
    },
    //新增
    handleAdd(){
      this.$router.push({name: 'MaintenanceForm'})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.bgc-anime {
  animation-name: box-bgc;
  animation-duration: 2s;
  animation-timing-function: linear;
}

@keyframes box-bgc {
  0% {
    background-color: #fff;
  }
  25% {
    background-color: #D7D7D7;
  }
  50% {
    background-color: #fff;
  }
  75% {
    background-color: #D7D7D7;
  }
  100% {
    background-color: #fff;
  }
}

.tabs-wrapper {
  padding: 20px 10px;
}

.tabs-content {
  margin-top: 20px;
  height: 240px;
  overflow: scroll;

  .item {
    line-height: 40px;
    font-size: 14px;
  }

  .select {
    color: #009999;
    font-weight: bold;
  }
}
</style>
