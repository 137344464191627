<!--抱紧管理的油烟浓度图表-->
<template>
  <div>
    <el-dialog
        title="油烟浓度"
        :visible.sync="dialogVisible"
        v-if="dialogVisible"
        width="100%"
        top="0px"
        @close="handleClose"
        center>
      <el-row class="mod03">
        <el-col :span="24">
          <ul>
            <li class="h1">
              <el-date-picker
                  v-model="StartAt"
                  type="date"
                  @change="handleStartAt"
                  placeholder="选择日期">
              </el-date-picker>
              <div style="width: 40px; text-align: center;">至</div>
              <el-date-picker
                  v-model="EndAt"
                  type="date"
                  @change="handleEndAt"
                  placeholder="选择日期">
              </el-date-picker>
            </li>
            <li>
              <div id="myChart" :style="{width: '100%', height: 'calc(100vh - 135px)'}"></div>
            </li>
          </ul>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import Head from '@/components/Head';
import {queryDataDetectorDaily} from "../utils/api";
import {formatDate} from "../utils/utils";
import showDialog from '@/minxs/showDialog';

export default {
  components: {Head},
  data() {
    return {
      msg: 'Welcome to Your Vue.js App',
      StartAt: '',
      EndAt: '',
      param: {},
    }
  },
  props: ['chartData'],
  mixins: [showDialog],
  watch: {
    chartData: {
      handler(val) {
        this.StartAt = val.StartAt * 1000;
        this.EndAt = val.EndAt * 1000;
        this.param = val;
        this.getToday()
      }
    }
  },
  methods: {
    getToday() {
      let date = new Date();
      this.EndAt = date.getTime() - 24 * 60 * 60 * 1000;
      this.param.AcquitAtEnd = parseInt(this.EndAt / 1000);
      var year = date.getFullYear();//getYear()+1900=getFullYear()
      var month = date.getMonth() + 1;//0-11表示1-12月
      var day = date.getDate();
      var lastMonthDay;
      if (parseInt(month) === 1) {
        lastMonthDay = (parseInt(year) - 1) + '-12-' + day;
      }
      var preSize = new Date(year, parseInt(month) - 1, 0).getDate();
      if (preSize < parseInt(day)) {//上月总天数<本月日期，比如3月的30日，在2月中没有30
        lastMonthDay = year + '-' + month + '-01';
      }
      if (parseInt(month) <= 10) {
        lastMonthDay = year + '-0' + (parseInt(month) - 1) + '-' + day;
      } else {
        lastMonthDay = year + '-' + (parseInt(month) - 1) + '-' + day;
      }
      this.StartAt = new Date(lastMonthDay).getTime();
      this.param.AcquitAtBegin = parseInt(this.StartAt / 1000);
      this.getDate();
    },
    getDate() {
      queryDataDetectorDaily(this.param).then(res => {
        let arr = [], arr02 = [], arr03, arr04 = [], arr05, arr06 = [], arr07;
        arr03 = res[0].LocaleEmissionsSill;
        arr05 = res[0].LocaleGranuleSill;
        arr07 = res[0].LocaleHydrocarbonSill;
        res.forEach(v => {
          let date = formatDate(v.AcquitAt);
          date = date.slice(0, 10);
          arr.push(date);
          arr02.push(v.CEmissions);
          arr04.push(v.CGranule);
          arr06.push(v.CHydrocarbon);
        });
        this.drawLine(arr, arr02, arr03, arr04, arr05, arr06, arr07);
      }).catch(err => {
        console.log(err)
      });
    },
    drawLine(arr, arr02, arr03, arr04, arr05, arr06, arr07) {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['油烟浓度', '颗粒物', '非甲烷总烃']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'value',
          name: '油烟浓度',
        },
          {
            type: 'value',
            name: '油烟平均',
          }],
        yAxis: {
          type: 'category',
          data: arr,
          name: '',
        },
        series: [
          {
            name: '油烟浓度',
            type: 'bar',
            data: arr02,
            itemStyle: {
              normal: {
                color: '#00A1FF',
                lineStyle: {
                  // type: 'solid',
                  color: '#00A1FF'
                }
              }
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#00A1FF",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  xAxis: arr03,
                },
              ],
            },
          },
          {
            name: '颗粒物',
            type: 'bar',
            data: arr04,
            itemStyle: {
              normal: {
                color: '#F5B049',
                lineStyle: {
                  // type: 'solid',
                  color: '#F5B049'
                }
              }
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#F5B049",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  xAxis: arr05,
                },
              ],
            },
          },
          {
            name: '非甲烷总烃',
            type: 'bar',
            data: arr06,
            itemStyle: {
              normal: {
                color: '#009999',
                lineStyle: {
                  // type: 'solid',
                  color: '#009999'
                }
              }
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#009999",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  xAxis: arr07,
                },
              ],
            },
          },
        ]
      });
    },
    handleStartAt() {
      let d = this.StartAt;
      this.param.AcquitAtBegin = parseInt(d.getTime() / 1000);
      this.getDate();
    },
    handleEndAt() {
      let d = this.EndAt;
      this.param.AcquitAtEnd = parseInt(d.getTime() / 1000);
      this.getDate();
    },

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
