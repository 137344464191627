import router from "../router";

export function handleJump(url, data) {
  if (data) {
    url = url + "/" + JSON.stringify(data);
  }
  router.push(url);
}

export function formatDate(num, cube = true, more = true, char = "/") {
  if (num === 0) return "";
  if (cube) num *= 1000;
  let date = new Date(num);
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  if (more) {
    let h = date.getHours();
    h = h < 10 ? "0" + h : h;
    let min = date.getMinutes();
    min = min < 10 ? "0" + min : min;
    return `${y + char + m + char + d + char + h + char + min}`;
  } else {
    return `${y + char + m + char + d}`;
  }
}

export function dateParse(date) {
  var arr = date.split(/[/ :]/);
  return new Date(
    arr[0],
    arr[1] - 1,
    arr[2],
    arr[3] ? arr[3] : null,
    arr[4] ? arr[4] : null
  );
}

let chinaAreaData = {};

export function codeData(arr, key) {
  chinaAreaData[key] = {};
  if (arr.Nodes && arr.Nodes.length > 0) {
    arr.Nodes.forEach(v => {
      chinaAreaData[key][v.Id] = v.Name;
      codeData(v, v.Id);
    });
  }
  return chinaAreaData;
}

export const getUserInfo = () => {
  if (sessionStorage.getItem("role") === "") {
    throw "Login failed, please login again";
  }
  return JSON.parse(sessionStorage.getItem("user"));
};

export const join = (arr, sep = "/") => {
  let info = JSON.parse(sessionStorage.getItem("info"));
  var owner = sep
  if (info) {
    if (info.pid) {
      owner = info.pid
    }
    if (info.id) {
      owner = owner + info.id + sep
    }
  }
  return owner + arr.join(sep) + sep;
};

export const ifNull = (str, sep = "/") => {
  if (str && str !== "") {
    return str;
  }
  return sep;
};

// 获取某一省下的市
export const getCities = province => {
  let provinceCode;
  for (const i in chinaAreaData[0]) {
    if (province === chinaAreaData[0][i]) {
      provinceCode = i;
      break;
    }
  }
  return typeof chinaAreaData[provinceCode] === "object"
    ? Object.values(chinaAreaData[provinceCode])
    : [];
};

// 获取某一市下的区/县
export function getAreas(province, city) {
  let provinceCode, cityCode;
  for (const i in chinaAreaData[0]) {
    if (province === chinaAreaData[0][i]) {
      provinceCode = i;
      break;
    }
  }
  for (const i in chinaAreaData[provinceCode]) {
    if (city === chinaAreaData[provinceCode][i]) {
      cityCode = i;
      break;
    }
  }
  if (chinaAreaData[cityCode]) {
    return typeof chinaAreaData[cityCode] === "object"
      ? Object.values(chinaAreaData[cityCode])
      : [];
  } else {
    // 只有两级的情况
    return [];
  }
}

export const treeFilter = (data, id = "Id", pid = "Pid") => {
  const tree = [];
  if (data) {
    const tmp = {};
    for (let i = 0, len = data.length; i < len; i++) {
      tmp[`${data[i][id]}`] = data[i];
      // tmp[data[i].Id].children = []
    }
    let arr;
    data.forEach(item => {
      if (item[pid] !== "") {
        arr = item[pid].substring(1, item[pid].length - 1).split("/");
        const tid = arr[arr.length - 1];
        if (tmp[tid]) {
          if (tmp[tid].children === undefined) {
            tmp[tid].children = [];
          }
          tmp[tid].children.push(item);
        } else {
          tree.push(item);
        }
      } else {
        tree.push(item);
      }
    });
  }
  return tree;
};

export function lSetItem(name, param) {
  let obj = {
    value: {},
    expires: 604800000,
    created_at: new Date().getTime()
  };
  if (typeof param === "string") {
    obj.value = param;
  } else {
    Object.assign(obj.value, param);
  }
  localStorage.setItem(name, JSON.stringify(obj));
}

export function lGetItem(name) {
  console.log(1);
  let item = localStorage.getItem(name);
  item = JSON.parse(item);
  if (item && item.created_at) {
    let date = new Date().getTime();
    if (date - item.created_at > item.expires) {
      // 超时
      localStorage.removeItem(name);
      return false;
    } else {
      item.created_at = new Date().getTime(); // 更新时间
      localStorage.setItem(name, JSON.stringify(item));
      return item;
    }
  } else {
    return item;
  }
}

export function toIntArray(str) {
  if (typeof str === "string") {
    return str
      .substring(1, str.length - 1)
      .split("/")
      .map(data => {
        return +data;
      });
  }
}

export function getPname(data, pid, label = "Name") {
  if (data && pid && pid !== "") {
    const name = [];
    const ids = toIntArray(pid);
    let items = data;
    for (let i = 0, len = ids.length; i < len; i++) {
      for (let j = 0; j < items.length; j++) {
        if (ids[i] === items[j]["Id"]) {
          name.push(items[j][label]);
          if (i === len - 1) {
            return name;
          }
          if (items[j]["children"]) {
            items = items[j]["children"];
            j = 0;
          }
          continue;
        }
      }
    }
  }
  return [];
}

export const getSinglePname = (data, pid, label = "Name") => {
  if (pid && pid !== "") {
    const ids = toIntArray(pid);
    let items = data;
    const len1 = ids.length;
    let len2 = items.length;
    for (let i = 0; i < len1; i++) {
      for (let j = 0; j < len2; j++) {
        if (ids[i] === items[j]["Id"]) {
          if (i === len1 - 1) {
            return items[j][label];
          }
          items = items[j]["children"];
          len2 = items.length;
          j = 0;
          continue;
        }
      }
    }
  }
  return "";
};

export const deviceTreeFilter = (data, id = "Id", pid = "Pid") => {
  const tree = [];
  if (data) {
    const tmp = {};
    for (let i = 0, len = data.length; i < len; i++) {
      tmp[`${data[i][id]}`] = data[i];
      tmp[`${data[i][id]}`].disabled = false;
      // if (tmp[`${data[i][id]}`].Id[0] !== 'c') {
      //   tmp[`${data[i][id]}`].disabled = true
      // }
    }
    let arr;
    data.forEach(item => {
      if (item[pid] !== "") {
        arr = item[pid].substring(1, item[pid].length - 1).split("/");
        const tid = arr[arr.length - 1];
        if (tmp[tid]) {
          if (tmp[tid].children === undefined) {
            tmp[tid].children = [];
          }
          tmp[tid].children.push(item);
          tmp[tid].disabled = false;
        } else {
          tree.push(item);
        }
      } else {
        tree.push(item);
      }
    });
  }
  return tree;
};
