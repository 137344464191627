<!--报警处理里面的异常处理-->
<template>
  <div class="bg01">
    <el-dialog
        :title="formData.typ===1?'报警处理':'异常处理'"
        :visible.sync="dialogVisible"
        v-if="dialogVisible"
        width="100%"
        top="0px"
        @close="handleClose"
        center>
      <el-form :model="ruleForm" :rules="rules" :hide-required-asterisk="true" ref="ruleForm" label-width="100px"
               label-position="left" class="form02">
        <el-form-item label="报警日期">
          <el-input v-model="ruleForm.AcquitAt" disabled></el-input>
        </el-form-item>
        <el-form-item label="处理日期">
          <el-input v-model="ruleForm.ProcessAt" disabled></el-input>
        </el-form-item>
        <el-form-item label="处理人">
          <el-input v-model="ruleForm.ProcessUserName" disabled></el-input>
        </el-form-item>
        <el-form-item label="原因及处理结果" prop="ProcessReason" label-width="120px" v-if="type === 0">
          <el-select
              class="input-box"
              size="mini"
              v-model="ruleForm.ProcessReason"
          >
            <el-option
                label="店家歇业(装修)"
                value="ShopClosed"
            ></el-option>
            <el-option
                label="监测仪故障"
                value="DetectorFailure"
            ></el-option>
            <el-option
                label="店家原因导致离线"
                value="OfflineCausedByShop"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原因及处理结果" prop="Solution" label-width="120px" v-else>
          <el-input v-model="ruleForm.Solution"></el-input>
        </el-form-item>
        <el-form-item label="处理地点" prop="ProcessingAddress">
          <el-button type="text" icon="el-icon-location-outline" class="color999" @click="getLocation">
            <em style="padding-left: 10px;" v-if="ruleForm.ProcessingAddress">{{ ruleForm.ProcessingAddress }}</em>
            <em v-else>获取定位</em>
          </el-button>
        </el-form-item>
        <el-form-item label-width="0">
          <el-button class="btn01" type="primary" @click="submitForm('ruleForm')">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import Head from '@/components/Head';
import {listAlarmRecord, alarmProcess, updateOfflineAlarmRecordProcessReason} from "../utils/api";
import {formatDate} from "../utils/utils";
import showDialog from "@/minxs/showDialog";

export default {
  components: {Head},
  data() {
    return {
      type: undefined,
      ruleForm: {
        AcquitAt: undefined,
        Solution: undefined,
        ProcessAt: undefined,
        ProcessUserName: undefined,
        ProcessingAddress: undefined,
      },
      rules: {
        ProcessReason: [
          {required: true, message: '请输入原因及处理结果', trigger: 'blur'}
        ],
        Solution: [
          {required: true, message: '请输入原因及处理结果', trigger: 'blur'}
        ],
        ProcessingAddress: [
          {required: true, message: '请获取定位', trigger: 'blur'}
        ],
      },
      dialogImageUrl: '',
      token: undefined,
    }
  },
  props: ['formData'],
  mixins: [showDialog],
  watch: {
    formData: {
      handler: function (val) {
        this.token = sessionStorage.getItem('token');
        let obj = val
        this.type = obj.type;
        this.getData(obj.typ, obj.id);
      }
    }
  },

  methods: {
    getToDay() {
      let day = new Date();
      day.setTime(day.getTime());
      let m = day.getMonth() + 1;
      m = m < 10 ? '0' + m : m;
      let d = day.getDate();
      d = d < 10 ? '0' + d : d;
      return `${day.getFullYear()}-${m}-${d}`
    },
    getData(typ, id) {
      listAlarmRecord({StartAt: 0, Size: 1, Typ: typ, Param: {id: id}}).then(res => {
        if (res.content.length > 0) {
          this.ruleForm = res.content[0];
          this.ruleForm.AcquitAt = formatDate(this.ruleForm.AcquitAt);
          this.ruleForm.ProcessAt = this.ruleForm.ProcessAt === 0 ? this.getToDay() : formatDate(this.ruleForm.ProcessAt);
          this.ruleForm.ProcessUserName = this.ruleForm.ProcessUserName === '' ? sessionStorage.getItem('name') : this.ruleForm.ProcessUserName;
          this.ruleForm.ProcessingAddress = this.ruleForm.ProcessingAddress !== '' ? this.ruleForm.ProcessingAddress : undefined;
        }
      }).catch(err => {
        console.log(err)
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log(this.ruleForm, url[this.type])
          let param = {};
          param.Id = this.ruleForm.Id;
          if (this.type === 0) {
            param.Solution = this.ruleForm.Solution;
            alarmProcess(param).then(res => {
              this.$toast.text({
                duration: 1000,
                message: '操作成功'
              });
              this.$router.go(-1);
            }).catch(err => {
              console.log(err)
            });
          } else {
            param.ProcessReason = this.ruleForm.ProcessReason;
            updateOfflineAlarmRecordProcessReason(param).then(res => {
              this.$toast.text({
                duration: 1000,
                message: '操作成功'
              });
              this.$router.go(-1);
            }).catch(err => {
              console.log(err)
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    upload() {
      // return '/api/uploadToOss'
      return process.env.BASE_API + '/uploadToOss'
    },
    handleSuccess(res, file) {
      this.ruleForm.AlarmRecordPicS.push(res.Data);
      console.log(res.Data);
    },
    handleRemove(file, fileList) {
      let i = this.ruleForm.AlarmRecordPicS.indexOf(file.response.data);
      this.ruleForm.AlarmRecordPicS.splice(i, 1);
    },

    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    getLocation() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      const _this = this;
      AMap.plugin('AMap.Geolocation', function () {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
        })

        geolocation.getCurrentPosition()
        AMap.event.addListener(geolocation, 'complete', onComplete);
        AMap.event.addListener(geolocation, 'error', onError);

        function onComplete(data) {
          // data是具体的定位信息
          _this.ruleForm.ProcessingAddress = data.formattedAddress;
          console.log('定位成功信息：', _this.ruleForm.ProcessingAddress);
          loading.close();
        }

        function onError(data) {
          // 定位出错
          console.log('定位失败错误：', data);
          loading.close();
          // 调用ip定位
          _this.getLngLatLocation();
        }
      })
    },
    getLngLatLocation() {
      const _this = this
      AMap.plugin('AMap.CitySearch', function () {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity(function (status, result) {
          if (status === 'complete' && result.info === 'OK') {
            // 查询成功，result即为当前所在城市信息
            console.log('通过ip获取当前城市：', result);
            // let point = result.rectangle.split(';')[1].split(',');
            _this.ruleForm.ProcessingAddress = result.province + result.city;
            loading.close();
            // _this.ruleForm.position[0] = parseFloat(point[0]);
            // _this.ruleForm.position[1] = parseFloat(point[1]);
            //逆向地理编码
            AMap.plugin('AMap.Geocoder', function () {
              var geocoder = new AMap.Geocoder({
                // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                city: result.adcode
              })

              var lnglat = result.rectangle.split(';')[0].split(',');
              geocoder.getAddress(lnglat, function (status, data) {
                if (status === 'complete' && data.info === 'OK') {
                  // result为对应的地理位置详细信息
                  // console.log(data.regeocode.formattedAddress)
                }
              })
            })
          }
        })
      })
    },

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
