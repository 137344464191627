<template>
  <div class="bg01">
    <Head :title="obj ? obj.Name : '一点一档'"></Head>
    <el-row class="mod03" style="height: calc(100vh - 48px);">
      <el-col :span="24">
        <div>餐饮企业名称：{{obj.Name}}</div>
        <div>餐饮企业地址：{{obj.Address}}</div>
        <div>营业执照：{{obj.BlNo}}</div>
        <div>联系人：{{obj.Contact}}</div>
        <div>联系方式：{{obj.Mobile}}</div>
        <b>一、餐饮企业在线监测情况汇总：</b>
        <div>本餐饮场所基本信息及超标报警情况：</div>
        <div>{{ StartAt }}至{{ EndAt }}</div>
        <div id="myChartBar" :style="{width: '100%', height: 'calc(100vh - 135px)', marginTop: '20px'}"></div>
        <div id="myChartLine" :style="{width: '100%', height: 'calc(100vh - 135px)', marginTop: '20px'}"></div>
        <ul>
          <li>
            <el-row>
              <el-col :span="8">监测点 <div>{{table.localeName}}</div></el-col>
              <el-col :span="16">设备编号 <div>{{table.mn}}</div></el-col>
              <el-col :span="24"><el-divider></el-divider></el-col>
              <el-col :span="8">达标天数 <div>{{table.complianceDays}}</div></el-col>
              <el-col :span="8">超标天数 <div>{{table.excessDays}}</div></el-col>
              <el-col :span="8">净化器不正常天数 <div>{{table.filterAbnormallyUsedDays}}</div></el-col>
              <el-col :span="24"><el-divider></el-divider></el-col>
              <el-col :span="8">异常离线天数 <div>{{table.abnormalOfflineDays}}</div></el-col>
              <el-col :span="8">环保健康码 <div><em class="icon01" :class="table.healthCodeColor | dialogStatus"></em></div></el-col>
            </el-row>
          </li>
        </ul>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Head from '@/components/Head';
import { formatDate } from "../utils/utils";
import { listDataDetectorDailySumDaysByLocaleId, noAuthQueryDataDetectorDaily, listTenMinDataLastDays } from "../utils/api";

export default {
  components: { Head },
  data () {
    return {
      msg: 'Welcome to Your Vue.js App',
      obj: {},
      table: {},
      StartAt: undefined,
      EndAt: undefined,
      param: {},
      param01: {
        AcquitAtBegin: undefined,
        AcquitAtEnd: undefined,
        LocaleId: undefined,
      },
    }
  },
  mounted() {
    // console.log(JSON.parse(this.$route.params.data))
    this.param = JSON.parse(this.$route.params.data);
    this.param01.LocaleId = this.param.owner;
    this.getDate();
  },
  filters: {
    dialogStatus(v) {
      let obj = {
        Green: 'type01',
        Red: 'type03',
        Yellow: 'type04',
      };
      return obj[v]
    },
  },
  methods: {
    getDate() {
      let today = new Date();
      today.setDate(today.getDate() - 1);
      let EndAt = today.getTime();
      this.EndAt = formatDate(EndAt / 1000).slice(0, 10);
      this.param01.AcquitAtEnd = parseInt(EndAt / 1000);
      today.setDate(today.getDate() - 30);
      let StartAt = today.getTime();
      this.param01.AcquitAtBegin = parseInt(StartAt / 1000);
      this.StartAt = formatDate(StartAt / 1000).slice(0, 10);
      this.getData();
      this.getBar();
      this.getLine();
    },
    getData() {
      listDataDetectorDailySumDaysByLocaleId(this.param01).then(res => {
        this.obj = res.customer;
        this.table = res;
      }).catch(err => {
        console.log(err)
      });
    },
    getBar() {
      let arr03 = this.param.emissionsSill;
      let arr05 = this.param.granuleSill;
      let arr07 = this.param.hydrocarbonSill;
      let arr = [];
      let arr02 = [];
      let arr04 = [];
      let arr06 = [];
      noAuthQueryDataDetectorDaily(this.param01).then(res => {
        if (res && res.length > 0){
          res.forEach(v=>{
            let date = formatDate(v.AcquitAt);
            date = date.slice(0, 10);
            arr.push(date);
            arr02.push(v.CEmissions);
            arr04.push(v.CGranule);
            arr06.push(v.CHydrocarbon);
          });
          let myChartBar = this.$echarts.init(document.getElementById('myChartBar'));
          myChartBar.setOption({
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            legend: {
              data: ['油烟折算浓度', '颗粒物折算浓度', '非甲烷总烃折算浓度']
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [{
              type: 'value',
              name: '油烟折算浓度',
            },
              {
                type: 'value',
                name: '油烟平均',
              }],
            yAxis: {
              type: 'category',
              data: arr,
              name: '',
            },
            series: [
              {
                name: '油烟折算浓度',
                type: 'bar',
                data: arr02,
                itemStyle: {
                  normal: {
                    color: '#00A1FF',
                    lineStyle: {
                      // type: 'solid',
                      color: '#00A1FF'
                    }
                  }
                },
                markLine: {
                  symbol: "none",
                  itemStyle: {
                    normal: {
                      lineStyle: {
                        type: "solid",
                        color: "#00A1FF",
                      },
                      label: {
                        position: "start",
                        formatter: "{c} ",
                      },
                    },
                  },
                  data: [
                    {
                      name: "阈值",
                      xAxis: arr03,
                    },
                  ],
                },
              },
              {
                name: '颗粒物折算浓度',
                type: 'bar',
                data: arr04,
                itemStyle: {
                  normal: {
                    color: '#F5B049',
                    lineStyle: {
                      // type: 'solid',
                      color: '#F5B049'
                    }
                  }
                },
                markLine: {
                  symbol: "none",
                  itemStyle: {
                    normal: {
                      lineStyle: {
                        type: "solid",
                        color: "#F5B049",
                      },
                      label: {
                        position: "start",
                        formatter: "{c} ",
                      },
                    },
                  },
                  data: [
                    {
                      name: "阈值",
                      xAxis: arr05,
                    },
                  ],
                },
              },
              {
                name: '非甲烷总烃折算浓度',
                type: 'bar',
                data: arr06,
                itemStyle: {
                  normal: {
                    color: '#009999',
                    lineStyle: {
                      // type: 'solid',
                      color: '#009999'
                    }
                  }
                },
                markLine: {
                  symbol: "none",
                  itemStyle: {
                    normal: {
                      lineStyle: {
                        type: "solid",
                        color: "#009999",
                      },
                      label: {
                        position: "start",
                        formatter: "{c} ",
                      },
                    },
                  },
                  data: [
                    {
                      name: "阈值",
                      xAxis: arr07,
                    },
                  ],
                },
              },
            ]
          });
        } else {
          this.$message.error('暂无数据');
        }
      }).catch(err => {
        console.log(err)
      });
    },
    getLine() {
      let arr03 = this.param.emissionsSill;
      let arr05 = this.param.granuleSill;
      let arr07 = this.param.hydrocarbonSill;
      let arr = [];
      let arr02 = [];
      let arr04 = [];
      let arr06 = [];
      listTenMinDataLastDays({mn: this.param.mn, LastDays: 7}).then(res => {
        // console.log(res);
        if (res && res.length > 0){
          for (var i = 0; i <= 143 * 7; i++) {
            let obj = {
              emissions_conc: 0,
              granule_conc: 0,
              hydrocarbon_conc: 0,
              counter: 1,
              acquit_at: res[0].acquit_at - i,
            };
            let array = res.filter(v => {
              return v.acquit_at === res[0].acquit_at + i
            })
            if (array.length > 0) {
              obj = array[0]
            }
            let date = formatDate(obj.acquit_at * 600);
            arr02.unshift(date.slice(5, date.length));
            arr.unshift((obj.emissions_conc / obj.counter).toFixed(2));
            arr04.unshift((obj.granule_conc / obj.counter).toFixed(2));
            arr06.unshift((obj.hydrocarbon_conc / obj.counter).toFixed(2));
          }
          let myChartLine = this.$echarts.init(document.getElementById('myChartLine'));
          myChartLine.setOption({
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            legend: {
              data: ['油烟浓度', '颗粒物', '非甲烷总烃']
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [{
              type: 'value',
              name: '油烟浓度',
            },
              {
                type: 'value',
                name: '油烟平均',
              }],
            yAxis: {
              type: 'category',
              data: arr02,
            },
            series: [
              {
                name: '油烟浓度',
                type: 'line',
                data: arr,
                itemStyle: {
                  normal: {
                    color: '#00A1FF',
                    lineStyle: {
                      type: 'solid',
                      color: '#00A1FF'
                    }
                  }
                },
                markLine: {
                  symbol: "none",
                  itemStyle: {
                    normal: {
                      lineStyle: {
                        type: "solid",
                        color: "#00A1FF",
                      },
                      label: {
                        position: "start",
                        formatter: "{c} ",
                      },
                    },
                  },
                  data: [
                    {
                      name: "阈值",
                      xAxis: arr03,
                    },
                  ],
                },
              },
              {
                name: '颗粒物',
                type: 'line',
                data: arr04,
                itemStyle: {
                  normal: {
                    color: '#F5B049',
                    lineStyle: {
                      type: 'solid',
                      color: '#F5B049'
                    }
                  }
                },
                markLine: {
                  symbol: "none",
                  itemStyle: {
                    normal: {
                      lineStyle: {
                        type: "solid",
                        color: "#F5B049",
                      },
                      label: {
                        position: "start",
                        formatter: "{c} ",
                      },
                    },
                  },
                  data: [
                    {
                      name: "阈值",
                      xAxis: arr05,
                    },
                  ],
                },
              },
              {
                name: '非甲烷总烃',
                type: 'line',
                data: arr06,
                itemStyle: {
                  normal: {
                    color: '#009999',
                    lineStyle: {
                      type: 'solid',
                      color: '#009999'
                    }
                  }
                },
                markLine: {
                  symbol: "none",
                  itemStyle: {
                    normal: {
                      lineStyle: {
                        type: "solid",
                        color: "#009999",
                      },
                      label: {
                        position: "start",
                        formatter: "{c} ",
                      },
                    },
                  },
                  data: [
                    {
                      name: "阈值",
                      xAxis: arr07,
                    },
                  ],
                },
              },
            ]
          });
        } else {
          this.$message.error('暂无数据');
        }
      }).catch(err => {
        console.log(err)
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
