<!--整个页面的底部公共区域-->
<template>
  <div>
    <ul class="foot01">
      <li :class="{'on' : active01}" @click="handleJump('/index')">
        <span class="iconfont">&#xe63a;</span>
        <h1>综合管理</h1>
      </li>
      <li :class="{'on' : active02}" @click="handleJump('/realTime', {type: 0})">
        <span class="iconfont">&#xe638;</span>
        <h1>实时监测</h1>
      </li>
      <li :class="{'on' : active03}" @click="handleJump('/alarm')">
        <span class="iconfont">&#xe635;</span>
        <h1>报警管理</h1>
      </li>
<!--      <li :class="{'on' : active06}" @click="handleJump('/troubleTicket')" v-if="role === 'Maintainer' || indexRole === 'Maintainer'">-->
<!--        <span class="iconfont">&#xe63e;</span>-->
<!--        <h1>运维单</h1>-->
<!--      </li>-->
      <li :class="{'on' : active04}" @click="handleJump('/statistics')">
        <span class="iconfont">&#xe63b;</span>
        <h1>数据统计</h1>
      </li>
      <li :class="{'on' : active05}" @click="handleJump('/center')">
        <span class="iconfont">&#xe639;</span>
        <h1>我的</h1>
      </li>
    </ul>
  </div>
</template>

<script>
import { handleJump } from "../utils/utils";

export default {
  props: ['indexRole'],
  data () {
    return {
      msg: 'Welcome to Your Vue.js App',
      active01: true,
      active02: false,
      active03: false,
      active04: false,
      active05: false,
      active06: false,
      role: undefined,
    }
  },
  mounted() {
    // localStorage.setItem('role', 'Operator');
    this.role = sessionStorage.getItem('role');
    this.active02 = window.location.href.indexOf('realTime')>0;
    this.active03 = window.location.href.indexOf('alarm')>0;
    this.active04 = window.location.href.indexOf('statistics')>0;
    this.active05 = window.location.href.indexOf('center')>0;
    this.active06 = window.location.href.indexOf('troubleTicket')>0;
    this.active01 = !this.active02 && !this.active03 && !this.active04 && !this.active05 && !this.active06;
  },
  methods: {
    handleJump
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
