<!--数据统计-SIM卡变更-->
<template>
  <div class="bg01">
    <Head title="SIM卡变更" :notShowLeft="true"></Head>
    <list-box ref="listBox" class="fixed" @load="load" :contentLength="list.length">
      <li class="h1 fixed">
        <el-button size="mini" @click="drawer = true" class="btn04">
          搜索
        </el-button>
        <el-button size="mini" class="btn04" @click="handleJump('/SimForm', { type: 'ADD' })">
          新增
        </el-button>
      </li>
      <li v-for="(v, i) in list" :key="i" :id="v.Id">
        <div class="h1">
          <div>
            <b>设备编码：{{'...' + v.MN.slice(v.MN.length - 8, v.MN.length)}}</b>
          </div>
          <div>上传日期：{{formatDate(v.AuditAt, false, false)}}</div>
        </div>
        <div class="h2">
          <el-row><span>所属单位：{{customerFormatter(v.Owner)}}</span></el-row>
          <el-row><span>安装地址：{{v.Addr}}</span></el-row>
          <el-row><span>SIM卡号：{{v.Sim}}</span></el-row>
          <el-row>
            <el-col :span="12">
              设备类型：{{subtypeFormatter(v.Subtype)}}
            </el-col>
            <el-col :span="12">
              提交人员：{{(v.Handler)}}
            </el-col>
          </el-row>
        </div>
        <div class="h1">
          <el-button
            plain
            size="mini"
            class="btn04"
            @click="handleJump('/SimForm', { type: 'EDIT', MN: v.MN, AuditAt: v.AuditAt, Sim: v.Sim, Id: v.Id })"
          >修改</el-button>
        </div>
      </li>
    </list-box>
    <el-drawer
        title="搜索"
        :visible.sync="drawer"
        direction="ttb"
        :with-header="false">
      <el-form class="formDrawer">
        <el-row type="flex" justify="space-between">
          <el-col :span="8">
            <el-form-item>
              <el-select
                  placeholder="提交人"
                  v-model="filter.Param.handler"
                  clearable
              >
                <el-option
                    v-for="item in userList"
                    :key="item.Id"
                    :label="item.Name"
                    :value="item.Name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item>
              <el-date-picker
                  v-model="filter.Param.audit_at"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                  clearable
                  style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <van-cell-group inset>
            <van-cell title="所属单位" :label="filter.Param.OwnerName" @click="showOwnerTabs">
              <template #right-icon>
                <div style="width: 28px;text-align: center;line-height: 28px"><van-icon name="cross" @click.stop="clearOwnerTabs"></van-icon></div>
              </template>
            </van-cell>
          </van-cell-group>
          <van-popup class="tabs-wrapper" v-model="ownerTabs" position="bottom" closeable close-on-click-overlay @close="closeOwnerTabs">
            <van-tabs
                ref="tabs"
                v-model="active"
                color="#009999"
                @click="tabsClick"
            >
              <van-tab
                  v-for="(tabItem, index) in tabsData"
                  :key="index"
                  :title="tabItem.Name"
              ></van-tab>
            </van-tabs>
            <div class="tabs-content">
              <div
                  :class="['item', { select: item.select }]"
                  v-for="(item, index) in tabList"
                  @click="tabSelect(item, index)"
              >
                {{ item.Name }}
              </div>
            </div>
          </van-popup>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" class="btn04" style="width: 100%;" @click="handleSearch">
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
    <Foot></Foot>
  </div>
</template>

<script>
  import Head from '@/components/Head';
  import Foot from '@/components/Foot';
  import ListBox from '@/components/ListBox';
  import { formatDate, join, getPname, handleJump } from "../utils/utils";
  import { listSubmitter, listSimRecord } from "../utils/api";
  import { mapState } from 'vuex'
  import { Tab, Tabs, Cell, CellGroup, Popup, Icon } from 'vant'

  export default {
    name: 'Sim',
    components: {
      Head,
      Foot,
      ListBox,
      [Tab.name]: Tab,
      [Tabs.name]: Tabs,
      [Cell.name]: Cell,
      [Popup.name]: Popup,
      [Icon.name]: Icon,
      [CellGroup.name]: CellGroup
    },
    data () {
      return {
        tabsData: [{
          Name: '请选择'
        }],
        active: 0,
        tabList: [],
        levelCollect: [],
        options: [
          {
            value: 1,
            label: '运维单处理'
          },
          {
            value: 2,
            label: '设备故障处理'
          },
          {
            value: 3,
            label: '异常离线及数据漂移处理'
          }
        ],
        statusOptions: [
          {
            value: 1,
            label: '已完成'
          },
          {
            value: 2,
            label: '未完成'
          },
          {
            value: 3,
            label: '待处理'
          }
        ],
        marker: [],
        filter: {
          StartAt: 0,
          Size: 10,
          Page: 1,
          Param: {
            handler: '',
            Owner: [],
            audit_at: null,
            OwnerName: ''
          },
        },
        list: [],
        dlg: {
          title: '处理',
          visible: false
        },
        changeState: false,
        scrollY: 0,
        formIndex: 0,
        drawer: false,
        customerTree: [],
        ownerTabs: false,
        userList: []
      }
    },
    mounted() {
      this.customerTree = JSON.parse(sessionStorage.getItem('customerTree'));
      this.$store.commit('setLocale', JSON.parse(sessionStorage.getItem('locale')))
      this.getList();
      listSubmitter().then((res) => {
        this.userList = res.content.slice()
      })
    },
    filters: {
      short(v) {
        return v.length > 16 ? v.substr(16, 8) : v
      },
    },
    computed: {
      ...mapState(['locale']),
      ...mapState({
        customerProps: state => Object.assign({}, state.props, { label: 'Org', checkStrictly: true })
      })

    },
    methods: {
      handleJump,
      formatDate,
      tabSelect(item, index) {
        let len = this.tabsData.length

        // 设置当前选项
        this.tabsData[this.active] = {
          Name: item.Name,
          index: index
        }
        this.levelCollect[this.active] = item

        // 如果选中之前的选项，则删除其之后的选项
        if (this.active < len) {
          this.tabsData.splice(this.active + 1)
          len = this.tabsData.length
        }

        // 如果有children，则增加到下一个选项
        if (item.children) {
          this.tabsData.push({Name: '请选择'})
          this.$refs.tabs.scrollTo(len)
          this.tabList = item.children
        } else {
          this.tabsClick(this.active, item.Name)
        }
      },
      tabsClick(name, title) {
        if (name === 0) {
          this.tabList = this.customerTree.map(e => {
            return {
              ...e,
              select: e.Name === title
            }
          })
        } else {
          this.tabList = this.levelCollect[name - 1].children.map(e => {
            return {
              ...e,
              select: e.Name === title
            }
          })
        }
      },
      showOwnerTabs() {
        this.ownerTabs = true;
        this.active = 0;
        this.tabList = Object.assign([], this.customerTree)
      },
      closeOwnerTabs() {
        let arrId = [];
        let arrName = [];
        this.levelCollect.forEach(e => {
          arrId.push(e.Id);
          arrName.push(e.Name)
        });
        this.filter.Param.Owner = arrId
        this.filter.Param.OwnerName = arrName.join(' ')
      },
      clearOwnerTabs() {
        this.tabsData = [{
          Name: '请选择'
        }];
        this.active = 0;
        this.tabList = [];
        this.levelCollect = [];
        this.filter.Param.Owner = [];
        this.filter.Param.OwnerName = null
      },
      customerFormatter(val) {
        return getPname(this.customerTree, val, 'Org').join('')
      },
      //延迟函数调用加载  获取SIM卡记录列表
      getList() {
        // 创建请求对象，包括请求路径、请求方法、请求头、请求体等信息
        const payload = {
          StartAt: (this.filter.Page - 1) * this.filter.Size,
          Size: this.filter.Size,
          Param: {}
        }
        //根据查询条件设置查询参数，例如根据处理人、归属人、审核时间等进行查询
        if (this.filter.Param.handler) {
          payload.Param['handler'] = { S: 0, V: `'${this.filter.Param.handler}'` }
        }
        if (this.filter.Param.Owner.length !== 0) {
          payload.Param['locale.owner'] = { S: 4, V: join(this.filter.Param.Owner) }
        }
        if (this.filter.Param.audit_at) {
          payload.Param['audit_at'] = { S: 11, V: `'${this.filter.Param.audit_at}', '${this.filter.Param.audit_at}'` }
        }
        //发送HTTP GET请求获取SIM卡记录列
        listSimRecord(payload).then(res => {
          this.list = this.list.concat(res.content);
          this.$refs.listBox.total = res.total;
        }).catch(err => {
          console.log(err)
        });
      },
      //实现自动加载列表项
      load() {
        this.$refs.listBox.loading = true;
        setTimeout(() => {
          this.filter.StartAt += this.filter.Size;
          this.filter.Page++;
          this.getList();
          this.$refs.listBox.loading = false
        }, 2000)
      },
      subtypeFormatter(subtype) {
        switch (subtype) {
          case 1:
            return '风机设备'
          case 2:
            return '净化设备'
          case 3:
            return '检测仪设备'
          default:
            return '未知类型'
        }
      },
      handleRefresh() {
        this.filter.Page = 1
        this.getList()
      },
      handleSearch() {
        this.filter = {
          ...this.filter,
          StartAt: 0,
          Page: 1
        };
        this.getList();
        this.drawer = false
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
  .bgc-anime {
    animation-name: box-bgc;
    animation-duration: 2s;
    animation-timing-function: linear;
  }
  @keyframes box-bgc {
    0% {
      background-color: #fff;
    }
    25% {
      background-color: #D7D7D7;
    }
    50% {
      background-color: #fff;
    }
    75% {
      background-color: #D7D7D7;
    }
    100% {
      background-color: #fff;
    }
  }
  .tabs-wrapper {
    padding: 20px 10px;
  }
  .tabs-content {
    margin-top: 20px;
    height: 240px;
    overflow: scroll;
    .item {
      line-height: 40px;
      font-size: 14px;
    }
    .select {
      color: #009999;
      font-weight: bold;
    }
  }
</style>

