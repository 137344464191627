<!--数据统计-SIM卡变更-新增-->
<template>
  <div class="bg01">
    <Head :title="getTitle"></Head>
    <el-form
        :model="formData"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        label-position="left"
        class="form02">
      <el-form-item label="设备编号" prop="MN" :error="errorMsg">
        <el-input placeholder="输入MN自动获取设备信息" v-model="formData.MN" @change="handleMNChange"></el-input>
      </el-form-item>
      <el-form-item label="地址">
        <el-input :placeholder="formData.Addr" disabled></el-input>
      </el-form-item>
      <el-form-item label="所属单位">
        <el-input type="textarea" :placeholder="customerFormatter(formData.Owner)" disabled></el-input>
      </el-form-item>
      <el-form-item label="提交时间" v-if="type === 'EDIT'">
        <el-input :placeholder="formData.AuditAt.slice(0, 11)" disabled></el-input>
      </el-form-item>
      <el-form-item label="设备类型">
        <el-input :placeholder="subtypeFormatter(formData.Subtype)" disabled></el-input>
      </el-form-item>
      <el-form-item label="SIM卡号" prop="Sim">
        <el-input placeholder="输入SIM卡号" v-model="formData.Sim"></el-input>
      </el-form-item>
      <el-form-item label-width="0">
        <el-button class="btn01" type="primary" @click="submitForm('ruleForm')">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import Head from '@/components/Head';
  import { getPname } from '../utils/utils'
  import { listDeviceByMN, saveSimRecord } from '../utils/api';

  export default {
    name: 'SimForm',
    components: { Head },
    data () {
      return {
        type: '',
        formData: {
          Id: '',
          MN: '',
          Addr: '',
          Handler: '',
          Subtype: null,
          Sim: '',
          AuditAt: ''
        },
        //required: true,代表必填   message: '请输入设备编码'返回的错误信息  trigger: 'blur'当用户离开输入字段时触发验证
        rules: {
          MN: [
            { required: true, message: '请输入设备编码', trigger: 'blur' }
          ],
          Sim: [
            { required: true, message: '请输入SIM卡号', trigger: 'blur' }
          ]
        },
        errorMsg: '',
        customerTree: []
      }
    },
    created() {
      this.customerTree = JSON.parse(sessionStorage.getItem('customerTree'));
      let obj = JSON.parse(this.$route.params.data);
      this.type = obj.type;
      this.formData.MN = obj.MN;
      this.formData.AuditAt = obj.AuditAt;
      this.formData.Sim = obj.Sim;
      this.formData.Id = obj.Id;
      this.handleMNChange(this.formData.MN);
    },
    computed: {
      getTitle() {
        switch(this.type) {
          case 'ADD':
            return '新增SIM变更记录';
          case 'EDIT':
            return '修改SIM变更记录';
          default:
            return 'SIM变更记录表单'
        }
      }
    },
    methods: {
      handleMNChange(val) {
        if (!val) return;
        const payload = {
          Param: {
            mn: {
              S: 4,
              V: val
            }
          }
        };
        listDeviceByMN(payload).then((res) => {
          if (res.total !== 1) {
            this.errorMsg = 'MN有误，请重新输入';
            return
          }
          this.formData = {
            ...this.formData,
            Owner: res.content[0].Owner,
            Addr: res.content[0].Locale.Addr,
            Subtype: res.content[0].Subtype
          }
        }).catch(e => {
          this.errorMsg = e.Msg
        })
      },
      subtypeFormatter(subtype) {
        switch (subtype) {
          case 1:
            return '风机设备';
          case 2:
            return '净化设备';
          case 3:
            return '检测仪设备';
          default:
            return '未知类型'
        }
      },
      customerFormatter(val) {
        return getPname(this.customerTree, val, 'Org').join('')
      },
      //点击提交触发这个函数  处理表单提交
      submitForm(formName) {
        // 使用 $refs 获取到表单实例
        this.$refs[formName].validate((valid) => {
          // 判断表单是否通过验证
          if (valid) {
            // 组装提交的数据
            const payload = Object.assign({}, this.formData)
            // 保存模拟记录
            saveSimRecord(payload).then((res) => {
              // 如果保存成功，显示消息
              this.$message({
                type: 'success',
                message: res
              })
              //保存成功后跳转到上一个路由
              this.$router.back()
            }).catch(e => {
              // 如果保存失败，将错误消息保存到 errorMsg 中
              this.errorMsg = e
            })
          } else {
            // 如果表单未通过验证，显示警告消息
            this.$message({
              type: 'warning',
              message: '输入有误，请重新输入'
            });
            // 返回 false，阻止表单提交
            return false;
          }
        });
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
