<!--实时检测-->
<template>
  <div class="bg01">
    <Head :title="type | title" :notShowLeft="type === 0"></Head>
    <list-box ref="listBox" @load="load" class="fixed" :contentLength="list.length">
      <li class="h1 fixed" v-if="type === 0">
        <van-button size="mini" @click="drawer = true" class="btn04">
          搜索
        </van-button>
      </li>

      <li  v-for="(v, i) in list" :key="i">
        <div class="h1">
          <div style="width: 65%;"><b>{{ v.name }}</b></div>
<!--          v.time是一个时间戳，将它转换成'YYYY/MM/DD HH:mm:ss'格式的时间-->
          <div>{{ dayjs(v.time).format('YYYY/MM/DD HH:mm:ss') }}</div>
        </div>
        <div class="h1">
          <div style="width: 70%;">{{ v.Addr }}
<!--           电话-->
            <a :href="`tel:${v.CustomerMobile}`" style="display: inline-block; margin-left: 5px;" v-if="type === 0"><i
                class="el-icon-phone color009999"></i></a>
<!--            地图-->
            <a style="display: inline-block; margin-left: 5px;" @click="handleMap(v)" v-if="type === 0"><i
                class="el-icon-location color009999"></i></a>
          </div>
          <div>监测点状态 <em class="icon01" :class="v.Status | dialogStatus"></em></div>
        </div>
        <el-row class="h2">
          <el-col :span="8">油烟实时浓度
            <div>{{ v.EmissionsConc }}</div>
          </el-col>
          <el-col :span="8">净化器状态
<!--            return v === 1 ? '开' : '-'   1|1 为真-->
            <div>{{ v.FilterStatus | type }}</div>
          </el-col>
          <el-col :span="8">风机状态
            <div>{{ v.FanStatus | type }}</div>
          </el-col>
          <el-col :span="24">
<!--            分割线-->
            <el-divider></el-divider>

          </el-col>
          <el-col :span="8">油烟折算浓度
            <div>{{ v.CEmissions }}</div>
          </el-col>
          <el-col :span="8">颗粒物浓度
            <div>{{ v.GranuleConc }}</div>
          </el-col>
          <el-col :span="8">非甲烷总烃浓度
            <div>{{ v.HydrocarbonConc }}</div>
          </el-col>
          <el-col :span="24">
<!--            分割线-->
            <el-divider></el-divider>
          </el-col>
          <el-col :span="8">风速
<!--            v.Velocity === 0 ? "-" : v.Velocity -->
            <div>{{ v.Velocity === 0 ? "-" : v.Velocity }}</div>
          </el-col>
          <el-col :span="8">温度
            <div>{{ v.Temperature === 0 ? "-" : v.Temperature }}</div>
          </el-col>
          <el-col :span="8">湿度
            <div>{{ v.Moisture === 0 ? "-" : v.Moisture }}</div>
          </el-col>
        </el-row>
        <div class="btn-group" v-if="type === 0">
          <van-button plain size="mini" class="btn04"
                      @click="handleMapDialog('showDialogRealTime', {mn: v.Id, linkStatus: v.LinkStatus})">
            分钟
          </van-button>
          <van-button plain size="mini" class="btn04"
                      @click="handleMapDialog('showDialogEcharts', {mn: v.Id, at: 0, emissionsSill: v.EmissionsSill, granuleSill: v.GranuleSill, hydrocarbonSill: v.HydrocarbonSill, i: 2})">
            曲线
          </van-button>
          <van-button plain size="mini" class="btn04"
                      @click="handleComplaintClick(v)">
            投诉
          </van-button>
        </div>
      </li>
    </list-box>
<!--    搜索标题栏固定-->
    <el-drawer
        title="我是标题"
        :visible.sync="drawer"
        direction="ttb"
        :with-header="false">
<!--el-drawer 定义了一个抽屉组件  标题、隐藏和出现、抽屉的方向（ltr"（从左到右）、"rtl"（从右到左）或"ttb"（从上到下））、是否显示抽屉的标题  -->
      <el-form label-width="0px" class="formDrawer">
        <el-form-item>
          <el-cascader
              v-model="param.Param.ownerArr"
              placeholder="所属单位"
              :options="customerTree"
              :props="props"
              clearable
              filterable
              @change="handleCascaderChange"
          />
<!--          element的级联选择器-->
        </el-form-item>
        <el-form-item>
          <el-select
              style="width: 100%;"
              placeholder="设备状态"
              v-model="param.Param.Status"
              clearable
          >
            <el-option label="达标" value="NORMAL"/>
            <el-option label="超标" value="EXCESS"/>
            <el-option label="离线" value="DOWN"/>
            <el-option label="异常离线" value="OFF"/>
            <el-option label="超标预警" value="ALARM"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" class="btn04" style="width: 100%;" @click="handleSearch">
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
<!--    底部区域-->
    <Foot :indexRole="role" v-show="!loading"></Foot>
    <dialog-realTime :showDialog.sync="showDialogRealTime"></dialog-realTime>
    <dialog-echarts :showDialog.sync="showDialogEcharts"></dialog-echarts>
    <div id="map" style="height: 0;"></div>
  </div>
</template>

<script>
import Head from '@/components/Head';
import Foot from '@/components/Foot';
import ListBox from '@/components/ListBox';
import {handleJump, formatDate, treeFilter} from "../utils/utils";
import {
  listDataIntime,
  listMultiDimQuery,
  listBasicDataOfCustomer,
  login,
  listDeviceLocale,
  listBasicDataOfArea
} from "../utils/api";
import DialogRealTime from '@/components/DialogRealTime';
import DialogEcharts from '@/components/DialogEcharts';
import {
  minutePubSub,
  echartsPubSub,
} from "@/service/pubsub/index";
import dayjs from "dayjs";

export default {
  name: 'RealTime',
  components: {
    Head,
    Foot,
    ListBox,
    DialogRealTime,
    DialogEcharts,
  },
  data() {
    return {
      msg: 'Welcome to Your Vue.js App',
      type: undefined,
      param: {
        StartAt: 0,
        Size: 10,
        Typ: 2,
        SortBy: 'status',
        SortMode: 'asc',
        Param: {
          key: undefined,
          ownerArr: []
        }
      },
      list: [],
      page: 1,
      marker: [],
      drawer: false,
      customerTree: [],
      areaTree: [],
      props: {label: 'Org', checkStrictly: true, value: 'Id'},
      showDialogRealTime: false,
      showDialogEcharts: false,
      key: 0,
      role: undefined,
      loading: false
    }
  },
  mounted() {
    let obj = JSON.parse(this.$route.params.data);
    this.type = obj.type;
    if (sessionStorage.getItem('login') && !sessionStorage.length < 6) {
      login(sessionStorage.getItem('login')).then(res => {
        sessionStorage.setItem('token', res.Token);
        sessionStorage.setItem('role', res.Role);
        sessionStorage.setItem('name', res.Name);
        sessionStorage.setItem('Owner', res.Owner.replace(/\//ig, 'n'));
        this.role = res.Role
      }).catch(err => {
        console.log(err)
      });
    }
      listBasicDataOfCustomer().then(res => {
        const customerTree = treeFilter(res.Customer);
        this.customerTree = customerTree
        sessionStorage.setItem('this.customerTree', JSON.stringify(this.customerTree))
        this.$store.commit('setCustomerTree', customerTree)
      })
      listDeviceLocale().then(res => {
        sessionStorage.setItem('locale', JSON.stringify(res))
        this.$store.commit('setLocale', res)
      })
      listBasicDataOfArea().then(res => {
        const areaTree = treeFilter(res.Area);
        this.areaTree = areaTree
        this.$store.commit('setAreaTree', areaTree)
      })

    // this.customerTree = JSON.parse(sessionStorage.getItem('customerTree'));
    if (this.type === 0) {
      this.getList();
    } else {
      this.getList2();
    }
  },
  filters: {
    title(v) {
      let arr = ['实时监测', '实时浓度'];
      return arr[v]
    },
    dialogStatus(v) {
      let obj = {
        NORMAL: 'type01',
        EXCESS: 'type03',
        DOWN: 'type02',
        OFF: 'type02',
        ALARM: 'type04'
      };
      return obj[v]
    },
    type(type) {
      const op = {
        0: '关',
        1: '开',
        2: '-'
      }
      return op[type]
    },
  },
  methods: {
    dayjs,
    handleJump,
    formatDate,
    getList() {
      const payload = {
        ...this.param,
        Param: {
          Owner: this.param.Param.Owner,
          Status: this.param.Param.Status,
          StatusOfRecord: 'NORMAL'
        }
      };
      listDataIntime(payload).then(res => {
        let arr = res.content.map(v => {
          v.time = v.AcquitAt*1000;
          v.name = this.type === 0 ? v.Locale : v.Name;
          return v;
        });
        this.list = this.page === 1 ? arr : this.list.concat(arr);
        //console.log(this.list)
        this.$refs.listBox.total = res.total;
      }).catch(err => {
        console.log(err)
      });
    },
    getList2() {
      listMultiDimQuery(this.param).then(res => {
        let arr = res.content.map(v => {
          v.time = v.AcquitAt*1000;
          v.name = this.type === 0 ? v.Locale : v.Name;
          return v;
        });
        this.list = this.page === 1 ? arr : this.list.concat(arr);
        this.$refs.listBox.total = res.total;
      }).catch(err => {
        console.log(err)
      });
    },
    load() {
      this.$refs.listBox.loading = true;
      setTimeout(() => {
        this.page++;
        this.param.StartAt += this.param.Size;
        if (this.type === 0) {
          this.getList();
        } else {
          this.getList2();
        }
        this.$refs.listBox.loading = false
      }, 2000)
    },
    //跳转到高德地图标记
    handleMap(obj) {
      let arr = [];//声明一个数组，用于存储地图标记的经纬度
      arr[0] = parseFloat(obj.LocaleLng);//赋值经度
      arr[1] = parseFloat(obj.LocaleLat);//赋值纬度
      let map = new AMap.Map('map', {//使用AMap.Map构造个地图实例
        resizeEnable: true,//地图是否可以调节大小
        center: arr,//地图中心点
        zoom: 13//地图显示的缩放级别
      });
      this.marker = new AMap.Marker({//使用AMap.Marker构造标记点
        position: arr,//设置标记点的位置
        content: '',//设置标记点的内容
        map: map//设置标记点所属的地图实例
      });
      this.marker.markOnAMAP({//使用markOnAMAP将标记点添加到地图上
        name: obj.Locale,//设置标记点的名称
        position: arr//设置标记点的位置
      })
    },
    onSearch(val) {
      this.page = 1;
      this.param.StartAt = this.page * this.param.Size;
      if (this.type === 0) {
        this.getList();
      } else {
        this.getList2();
      }
    },
    onCancel() {
      this.param.Param.SearchWord = undefined;
      this.page = 1;
      this.param.StartAt = this.page * this.param.Size;
      if (this.type === 0) {
        this.getList();
      } else {
        this.getList2();
      }
    },
    //搜索
    handleSearch() {
      if (this.param.Param.ownerArr.length > 0) {
        this.param.Param.Owner = '/' + this.param.Param.ownerArr.join('/') + '/';
      } else {
        delete this.param.Param.Owner
      }
      if (this.param.Param.Status === '') {
        delete this.param.Param.Owner
      }
      this.page = 1;
      this.param.StartAt = 0;
      // this.param.StartAt = this.page * this.param.Size;
      // console.log(this.param)
      if (this.type === 0) {
        this.getList();
      } else {
        this.getList2();
      }
      this.drawer = false;
    },
    //分钟/曲线
    handleMapDialog(key, obj) {
       console.log(key)
      console.log(obj)
      switch (key) {
        case 'showDialogRealTime':
          minutePubSub.publish(obj);
          this[key] = true;
          break;
        case 'showDialogEcharts':
          echartsPubSub.publish(obj);
          this[key] = true;
          break;
      }
    },
    //el组联组件
    handleCascaderChange() {
      if (!this.param.Param.ownerArr || this.param.Param.ownerArr.length === 0) {
        this.key++;
      }
    },
    //投诉
    handleComplaintClick(val) {
      this.$router.push({
        name: "complaint-form",
        params: {locale: val}
      });
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.weui-search-bar {
  padding: 0 !important;
}

.formDrawer {
  padding: 15px;
}

.el-cascader__suggestion-item {
  height: auto !important;
  margin-bottom: 5px;
}
</style>
