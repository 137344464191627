//一些接口
export default {
  login: "/login",
  usermodify: "/admin/usermodify",
  changepasswd: "/admin/changepasswd",
  listMapDevice: "/admin/listMapDevice",
  listBasicData: "/admin/listBasicData",
  listBasicDataOfCustomer: "/admin/listBasicDataOfCustomer",
  listBasicDataOfLocale: "/admin/listBasicDataOfLocale",
  listDeviceByMN: "/admin/listDevice",
  listDeviceLocale: "/admin/listDeviceLocale",
  listMonitorDevice: "/admin/listMonitorDevice",
  listIntimeData: "/admin/listIntimeData",
  listDataIntime: "/admin/listDataIntime",
  listMultiDimQuery: "/admin/listMultiDimQuery",
  listTenMinData: "/admin/listTenMinData",
  listCurAlarm: "/admin/listCurAlarm",
  delAlarmRecord: "/admin/delAlarmRecord",
  listAlarmRecord: "/admin/listAlarmRecord",
  alarmProcess: "/admin/alarmProcess",
  updateOfflineAlarmRecordProcessReason:
    "/admin/updateOfflineAlarmRecordProcessReason",
  listDataDetectorDailyStats: "/admin/listDataDetectorDailyStats",
  listDetectorDaily: "/admin/listDetectorDaily",
  queryDataDetectorDaily: "/admin/queryDataDetectorDaily",
  listAlarmMsg: "/admin/listAlarmMsg",
  listMaintainOption: "/admin/listMaintainOption",
  listMaintenance: "/admin/listMaintenance",
  updateMaintenance: "/admin/updateMaintenance",
  noAuthListMapDevice: "/admin/noAuth/listMapDevice",
  noAuthQueryDataDetectorDaily: "/admin/noAuth/queryDataDetectorDaily",
  listDataDetectorDailySumDaysByLocaleId:
    "/admin/noAuth/listDataDetectorDailySumDaysByLocaleId",
  listTenMinDataLastDays: "/admin/noAuth/listTenMinDataLastDays",
  uploadImages: "/uploadToOss",
  listSimRecord: "/admin/listRecoedOfDevice",
  saveSimRecord: "/admin/saveSimRecord",
  deleteSimRecord: "/admin/delectRecordOfDevice",
  listSubmitter: "/admin/listSubmitter",
  listLocaleRecords: "/admin/listLocaleRecords",
  uploadLocaleInfo: "/admin/uploadLocaleInfo",
  saveCustomerDetail: "/admin/saveCustomerDetail",
  listCustomerDetail: "/admin/listCustomerDetail",
  listBasicDataOfArea: "/admin/listBasicDataOfArea",
  findDeviceOfLocale: "/admin/findDeviceOfLocale",
  listAllCuisine: "/admin/listAllCuisine",
  listApprovedBusiness: "/admin/listApprovedBusiness",
  listCascadeReferParam: "/admin/listCascadeReferParam",
  listMaintainer: "/admin/listMaintainer",
  updateHealthCodeRecord:'/admin/updateHealthCodeRecord'
};
