<!--实时监测-投诉-新增投诉-->
<template>
  <div>
    <Head title="投诉填报"></Head>
    <van-form @submit="onSubmit">
      <van-field
          v-model="formData.name"
          name="监测点名称"
          label="监测点名称"
      >
        <template #input>
          <div>{{ formData.name }}</div>
        </template>
      </van-field>
      <van-field
          v-model="formData.addr"
          name="监测点地址"
          label="监测点地址"
      >
        <template #input>
          <div>{{ formData.addr }}</div>
        </template>
      </van-field>
      <van-field
          v-model="formData.create_at"
          name="提交时间"
          label="提交时间"
      >
        <template #input>
          <div>{{ formData.create_at }}</div>
        </template>
      </van-field>
      <van-field
          v-model="formData.contact"
          name="投诉人姓名"
          label="投诉人姓名"
      />
      <van-field
          v-model="formData.mobile"
          type="tel"
          name="联系方式"
          label="联系方式"
      />
      <van-field
          v-model="formData.remark"
          autosize
          type="textarea"
          name="投诉内容"
          label="投诉内容"
          :rules="[{ required: true, message: '请填写投诉内容' }]"

      />
<!--      文件上传-->
      <van-field
          name="uploader"
          label="文件上传"
          :rules="[{ required: true, message: '请上传图片' }]"
      >
        <template #input>
          <van-uploader
              :after-read="afterRead"
              @delete="deleteFile"
              v-model="formData.files"/>
        </template>
      </van-field>
<!--      提交-->
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>

</template>
<script>
import Head from "@/components/Head.vue";
import {Get, Post} from "@/utils/http";
import dayjs from "dayjs";
import {Notify} from "vant";

export default {
  name: 'ComplaintForm',
  components: {Head},
  data() {
    return {
      formData: {
        name: '',
        addr: '',
        create_at: ''//提交时间
      },
      fileList: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let locale = this.$route.params.locale
      this.formData.create_at = dayjs().format("YYYY-MM-DD HH:mm:ss")//设置创建时间
      Get("admin/list_locale", {name: locale.Locale}).then(res => {
        this.formData.locale_id = res[0].id
        this.formData.name = res[0].name
        this.formData.addr = res[0].addr
        this.formData.device_mns = res[0].device_mns
      })
    },
    //提交表单
    onSubmit() {
      this.formData.pics = this.fileList
      let param = new URLSearchParams()
      Object.entries(this.formData).forEach(([key, val]) => {
        param.append(key, val)
      })
      Post('admin/complaint/add', param).then(res => {
        // 成功通知
        Notify({type: 'success', message: '投诉成功'});
        this.$router.go(-1)
      }).catch(e => {
        Notify({type: 'warning', message: e.data.Data});
      })
    },
    //文件上传
    afterRead(file) {
      const fileN = file.content;
      const blob = this.dataURLtoBlob(fileN)
      let formData = new FormData()
      formData.set('file', blob)
      Post('http://116.62.234.187:8088/uploadToOss', formData).then(res => {
        this.fileList.push(res)
      })
    },
    //删除上传的照片
    deleteFile(file, detail) {
      this.fileList.splice(detail.index, 1)
    },
    //将base64转换为blob，界面展示上传的图片
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {type: mime});
    }
  },

}
</script>
<style scoped>

</style>
