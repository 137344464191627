<!--我的-消息通知-展示消息-->
<template>
  <div class="bg01">
    <Head title="消息通知"></Head>
    <el-row class="mod08">
      <el-col :span="24">
        <h2 class="color999">{{formatDate(obj.AcquitAt)}}</h2>
        <div v-html="obj.Content"></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Head from '@/components/Head';
import {
  messagePubSub
} from "@/service/pubsub/index";
import { formatDate } from "../utils/utils";

export default {
  components: { Head },
  data () {
    return {
      obj: {},
    }
  },
  mounted() {
    messagePubSub.unsubscribe();
    messagePubSub.subscribe(this.getData);
  },
  methods: {
    formatDate,
    getData(data) {
      this.obj = data;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .mod08 img{
    display: block;
    margin: 10px auto;
  }
</style>
