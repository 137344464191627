<template>
  <div class="bg01">
    <Head title="一点一档" :notShowLeft="true"></Head>
    <el-row>
      <el-col :span="24">
        <wv-group>
          <wv-cell v-for="(v, i) in list" :key="i" :title="v.LocaleName" is-link @click.native="handleJump('/onePointToFiles', {mn: v.MN, owner: v.Owner, emissionsSill: v.EmissionsSill, granuleSill: v.GranuleSill, hydrocarbonSill: v.HydrocarbonSill})"></wv-cell>
        </wv-group>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Head from '@/components/Head';
import { handleJump } from "../utils/utils";
import { noAuthListMapDevice } from "../utils/api";

export default {
  components: { Head },
  data () {
    return {
      hasNew: false,
      list: [],
      param: {
        Owner: undefined
      }
    }
  },
  mounted() {
    console.log(this.$route.params.data)
    this.param.Owner = this.$route.params.data.replace(/n/ig, '/');
    this.getData();
  },
  methods: {
    handleJump(url, data) {
      let arr = data.owner.split('/');
      data.owner = arr[arr.length-2];
      handleJump(url, data);
    },
    getData() {
      noAuthListMapDevice(this.param).then(res => {
        this.list = res.Device;
      }).catch(err => {
        console.log(err)
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
