<template>
  <el-row class="mod02">
    <el-col :span="24" class="box">
      <i class="el-icon-close" @click="handleClose"></i>
      <div>店铺名称：{{dataObj.CustomerName}}</div>
      <div>环保健康码：<em class="icon01" :class="dataObj.HealthCodeColor | dialogStatusTwo"></em></div>
      <div>联系电话：{{dataObj.CustomerMobile}}</div>
      <div>监测点名称：{{dataObj.name}}</div>
      <div>设备编号：{{dataObj.MN | short}}</div>
      <div>餐馆位置：{{dataObj.Addr}}</div>
      <div>菜系：{{dataObj.CuisineName}}</div>
    </el-col>
    <el-col :span="24">
      <el-divider></el-divider>
      <el-button plain size="mini" class="btn04" @click="handleDialog('showDialogDetails', dataObj)">详情</el-button>
      <el-button plain size="mini" class="btn04" @click="handleDialog('showDialogRealTime', {mn: dataObj.MN, linkStatus: dataObj.LinkStatus})">实时</el-button>
      <el-button plain size="mini" class="btn04" @click="handleDialog('showDialogEcharts', {mn: dataObj.MN, at: 0, emissionsSill: dataObj.EmissionsSill, granuleSill: dataObj.GranuleSill, hydrocarbonSill: dataObj.HydrocarbonSill})">曲线</el-button>
      <el-button plain size="mini" class="btn04" @click="handleDialog('showDialogEcharts30', {mn: dataObj.MN, emissionsSill: dataObj.EmissionsSill, granuleSill: dataObj.GranuleSill, hydrocarbonSill: dataObj.HydrocarbonSill})">历史曲线</el-button>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    dataObj: {
      type: Object,
      default: {}
    },
  },
  data () {
    return {
      msg: 'Welcome to Your Vue.js App',
      value: ''
    }
  },
  mounted() {
  },
  filters: {
    short(v) {
      return v.substr(16, 8)
    },
    dialogStatusTwo(v) {
      let obj = {
        Green: 'type01',
        Red: 'type03',
        Yellow: 'type04',
      };
      return obj[v]
    },
  },
  methods: {
    handleClose() {
      this.$emit('handleMapClose');
    },
    handleDialog(key, data) {
      this.$emit('handleMapDialog', {key: key, data: data});
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
