<!--我的账户安全修改手机号-->
<template>
  <div class="bg01">
    <Head title="修改手机号"></Head>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-position="left" class="form02">
      <el-form-item label="用户名" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-input v-model="ruleForm.mobile"></el-input>
      </el-form-item>
      <el-form-item label-width="0" >
<!--        点击事件出发，拿到输入的ruleForm数据-->
        <el-button class="btn01" type="primary" @click="submitForm('ruleForm')">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Head from '@/components/Head';
import { usermodify } from "../utils/api";

export default {
  components: { Head },
  data () {
    return {
      msg: 'Welcome to Your Vue.js App',
      ruleForm: {
        name: '',
        mobile: '',
      },
      //绑定规则
      rules: {
        name: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
      },
    }
  },
  mounted() {},
  methods: {
    submitForm(formName) {
      //表单校验
      this.$refs[formName].validate((valid) => {
        if (valid) {
          usermodify(this.ruleForm, 'get').then(res => {
            this.$toast.text({
              duration: 1000,
              message: '操作成功'
            });
            this.$router.go(-1);
          }).catch(err => {
            console.log(err)
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
