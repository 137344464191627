<!--数据统计-监测点信息上传--->
<template>
  <div class="bg01">
    <Head
        title="监测点信息采集"
        :notShowLeft="false"
        :notshowUpload="false"
    ></Head>
    <list-box
        ref="listBox"
        @load="load"
        class="fixed"
        :contentLength="list.length"
    >
      <li class="h1 fixed">
        <van-button size="small" @click="drawer = true" class="btn04">
          搜索
        </van-button>
        <van-button size="small" class="btn04" @click="handleAdd">
          新增
        </van-button>
      </li>

      <li v-for="(v, i) in list" :key="i">
        <div class="h1">
          <div style="width: 100%;">
            <b>监测点名称：{{ v.name }}</b>
          </div>
        </div>
        <div class="h1">
          <div>监测点地址：{{ v.addr }}</div>
          <div>提交时间：{{ v.create_at }}</div>
        </div>
        <van-row class="h2">
          <van-col :span="8">提交人： {{ v.user_name }}</van-col>
          <van-col :span="16">所属地区： {{ v.area_name }}</van-col>
          <van-col :span="8">联系人： {{ v.maintainer_name }}</van-col>
          <van-col :span="16">联系电话： {{ v.mobile }}</van-col>
          <van-col :span="20">所属单位： {{ v.owner_name }}</van-col>
          <van-col :span="9">审核状态： {{ v.status === 0 ? '待审核' : '通过' }}</van-col>
          <van-col :span="10">审核备注： {{ v.remark }}</van-col>
        </van-row>

        <div class="h1">
          <van-button plain size="mini" class="btn04" @click="handleEdit(v, i)">修改</van-button>
          <van-button plain size="mini" class="btn04" @click="handleLook(v, i)">查看</van-button>
        </div>
      </li>
    </list-box>

<!--    当drawer为true时，抽屉展开-->
    <van-popup
        v-model="drawer"
        position="top"
        :overlay="true">
<!--  position="top"设配置弹出的位置  :overlay="true"用于控制是否显示遮罩层   -->
      <van-row>
        <van-col span="12">
          <van-cell is-link
                    @click="handleVerifyStatusClick"
                    :title="filter.Result=== null? '审核状态':filter.Result">
          </van-cell>
          <van-popup
              v-model="showVerifyStatus"
              position="bottom">
            <van-picker
                title="审核状态选择"
                show-toolbar
                :columns="statusOption"
                @confirm="verifyStatusConfirm"
                @cancel="showVerifyStatus = false"
            />
          </van-popup>
        </van-col>
        <van-col span="12">
          <van-cell is-link
                    @click="handleDateClick"
                    :title="filter.SubmitDate=== null? '选择日期':filter.SubmitDate">
          </van-cell>
          <van-calendar
              v-model="showDate"
              :min-date="minDate"
              @confirm="dateConfirm"/>
        </van-col>
        <van-col span="24">
          <van-cell is-link
                    @click="handleOwnerClick"
                    :title="filter.owner=== null? '所属单位':filter.ownerName">
          </van-cell>
          <van-popup
              v-model="showOwner"
              position="bottom">
            <van-cascader
                title="请选择所在地区"
                :options="customerTree"
                :field-names="{text:'Name',value:'Id',children:'children'}"
                @close="showOwner = false"
                @finish="verifyOwnerConfirm"
            />
          </van-popup>
        </van-col>
        <van-col span="24">
          <van-cell is-link
                    @click="handleUserClick"
                    :title="filter.Submitter=== null? '提交人':filter.Submitter.Name">
          </van-cell>
          <van-popup
              v-model="showUser"
              position="bottom">
            <van-picker
                title="提交人"
                show-toolbar
                :columns="userList"
                value-key="Name"
                @confirm="verifyUserConfirm"
                @cancel="showUser = false"
            />
          </van-popup>
        </van-col>
        <van-col span="24" style="padding: 20px">
          <van-button size="small"
                      class="btn04"
                      style="width: 100%;"
                      @click="handleSearch">确定
          </van-button>
        </van-col>
      </van-row>
    </van-popup>
    <Foot></Foot>
    <div id="map" style="height: 0;"></div>
  </div>
</template>

<script>
import Head from "@/components/Head";
import Foot from "@/components/Foot";
import ListBox from "@/components/ListBox";
import {formatDate, getPname, getSinglePname, handleJump, toIntArray} from "@/utils/utils";
import {findDeviceOfLocale, listLocaleRecords, listSubmitter} from "@/utils/api";
import {mapState} from "vuex";
import {Cell, CellGroup, Icon, Popup, Tab, Tabs} from "vant";
import dayjs from "dayjs";
import {Get} from "@/utils/http";

export default {
  name: "LocaleInfo",
  components: {
    Head,
    Foot,
    ListBox,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Cell.name]: Cell,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [CellGroup.name]: CellGroup
  },

  data() {
    return {
      drawer: false,
      list: [],
      filter: {
        StartAt: 0,
        Size: 10,
        Page: 1,
        Result: null,
        owner: null,
        ownerName: null,
        Submitter: null,
        SubmitterId: null,
        SubmitDate: null
      },
      userList: [], //提交人列表
      customerTree: [],
      props: {label: "Org", checkStrictly: true, value: "Id"},
      active: 0,
      tabsData: [
        {
          Name: "请选择"
        }
      ],
      tabList: [],
      levelCollect: [],
      changeState: false,
      scrollY: 0,
      formIndex: 0,
      ownerTabs: false,
      statusOption: [
        {text: '待审核', value: 'WAIT_TO_QUALIFY'},
        {text: '通过', value: 'QUALIFIED'},
        {text: '未通过', value: 'UNQUALIFIED'},
      ],
      showVerifyStatus: false,
      showDate: false,
      showUser: false,
      showOwner: false,
      minDate: new Date('2023-1-1')
    };
  },

  mounted() {
    listSubmitter().then(res => {
      this.userList = res.content
    });
    this.customerTree = JSON.parse(sessionStorage.getItem("this.customerTree"));
    this.$store.commit(
        "setLocale",
        JSON.parse(sessionStorage.getItem("locale"))
    );
    this.areaTree = JSON.parse(sessionStorage.getItem("areaTree"));
    this.getList();
  },

  computed: {
    ...mapState(["locale"]),
    ...mapState({
      customerProps: state =>
          Object.assign({}, state.props, {label: "Org", checkStrictly: true})
    })
  },

  methods: {
    handleJump,
    formatDate,
    getList(newSearch = false) {
      const payload = {
        page: this.filter.Page,
        size: this.filter.Size,
        Param: {}
      };
      if (this.filter.owner && this.filter.owner.length > 0) {
        payload.owner = this.filter.owner
      }
      if (this.filter.Result) {
        if (this.filter.Result === '待审核') {
          payload.status = 0
        } else if (this.filter.Result === '通过') {
          payload.status = 1
        } else {
          payload.status = 2
        }
      }
      if (this.filter.SubmitDate) {
        let timestamp = dayjs(this.filter.SubmitDate).valueOf()
        payload.submit_at = [timestamp / 1000, timestamp / 1000 + 24 * 3600]
      }
      if (this.filter.SubmitterId) {
        payload.name = this.filter.Submitter.Name
      }
      Get('admin/locale_exam', payload).then(res => {
        if (newSearch) {
          this.list = res.list;
        } else {
          this.list = this.list.concat(res.list);
        }
        this.$refs.listBox.total = res.count;
      })
    },
    qualifyStatusFormatter(status) {
      switch (status) {
        case "QUALIFIED":
          return "通过";
        case "UNQUALIFIED":
          return "未通过";
        case "WAIT_TO_QUALIFY":
          return "待审核";
        default:
          return "";
      }
    },
    handleSearch() {
      this.filter = {
        ...this.filter,
        timeAt: 0,
        Page: 1
      };
      this.getList(true);
      // this.list = this.list.filter((value) => value.Result === this.filter.Param.Result);
      // console.log(this.list)
      this.drawer = false;
    },
    handleAdd() {
      sessionStorage.setItem('type', 'ADD');
      this.$router.push({name: "locale-info-form", params: {type: "ADD"}});
    },
    toLocaleInfoForm(data, type) {
      sessionStorage.setItem('formData',JSON.stringify(data));
      this.$router.push({
        name: "locale-info-form",
        params: {formData: data, type: type}
      });
    },
    handleEdit(data, index) {
      sessionStorage.setItem('type', 'EDIT');
      this.toLocaleInfoForm(data, "EDIT");

    },
    handleLook(data, index) {
      sessionStorage.setItem('type', 'LOOK');
      this.toLocaleInfoForm(data, "LOOK");
    },
    load() {
      this.$refs.listBox.loading = true;
      setTimeout(() => {
        this.filter.StartAt += this.filter.Size;
        this.filter.Page++;
        this.getList();
        this.$refs.listBox.loading = false;
      }, 2000);
    },
    customerFormatter(val) {
      return getPname(this.customerTree, val, "Org").join("");
    },
    areaFormatter(v) {
      return getSinglePname(this.areaTree, v);
    },

    tabSelect(item, index) {
      let len = this.tabsData.length;

      // 设置当前选项
      this.tabsData[this.active] = {
        Name: item.Name,
        index: index
      };
      this.levelCollect[this.active] = item;

      // 如果选中之前的选项，则删除其之后的选项
      if (this.active < len) {
        this.tabsData.splice(this.active + 1);
        len = this.tabsData.length;
      }

      // 如果有children，则增加到下一个选项
      if (item.children) {
        this.tabsData.push({Name: "请选择"});
        this.$refs.tabs.scrollTo(len);
        this.tabList = item.children;
      } else {
        this.tabsClick(this.active, item.Name);
      }
    },

    tabsClick(name, title) {
      if (name === 0) {
        this.tabList = this.customerTree.map(e => {
          return {
            ...e,
            select: e.Name === title
          };
        });
      } else {
        this.tabList = this.levelCollect[name - 1].children.map(e => {
          return {
            ...e,
            select: e.Name === title
          };
        });
      }
    },

    showOwnerTabs() {
      this.ownerTabs = true;
      this.active = 0;
      this.tabList = Object.assign([], this.customerTree);
    },

    clearOwnerTabs() {
      this.tabsData = [
        {
          Name: "请选择"
        }
      ];
      this.active = 0;
      this.tabList = [];
      this.levelCollect = [];
      this.filter.Param.Owner = [];
      this.filter.Param.OwnerName = null;
    },

    closeOwnerTabs() {
      let arrId = [];
      let arrName = [];
      this.levelCollect.forEach(e => {
        arrId.push(e.Id);
        arrName.push(e.Name);
      });
      this.filter.Param.Owner = arrId;
      this.filter.Param.OwnerName = arrName.join(" ");
    },
    handleVerifyStatusClick() {
      this.showVerifyStatus = true
    },
    verifyStatusConfirm(val) {
      this.showVerifyStatus = false
      this.filter.Result = val.text
    },
    handleDateClick() {
      this.showDate = true
    },
    dateConfirm(val) {
      this.filter.SubmitDate = dayjs(val).format('YYYY-MM-DD')
      this.showDate = false
    },
    handleUserClick() {
      this.showUser = true
    },
    verifyUserConfirm(val) {
      this.filter.Submitter = val
      this.filter.SubmitterId = val.Id
      this.showUser = false
    },
    handleOwnerClick() {
      this.showOwner = true
    },
    verifyOwnerConfirm(val, selectedOptions) {
      let options = val.selectedOptions
      this.filter.ownerName = options.map(item => item.Name).join(' / ')
      this.filter.owner = '/' + options.map(item => item.Id).join('/') + '/'
      this.showOwner = false
    },
  }
};
</script>

<style lang="less" scoped>
.bgc-anime {
  animation-name: box-bgc;
  animation-duration: 2s;
  animation-timing-function: linear;
}

@keyframes box-bgc {
  0% {
    background-color: #fff;
  }
  25% {
    background-color: #d7d7d7;
  }
  50% {
    background-color: #fff;
  }
  75% {
    background-color: #d7d7d7;
  }
  100% {
    background-color: #fff;
  }
}

.tabs-wrapper {
  padding: 20px 10px;
}

.tabs-content {
  margin-top: 20px;
  height: 240px;
  overflow: scroll;

  .item {
    line-height: 40px;
    font-size: 14px;
  }

  .select {
    color: #009999;
    font-weight: bold;
  }
}
</style>
