<!--数据加载的展示-->
<template>
  <el-row class="mod03 infinite-list-wrappe" style="overflow-y:auto">
    <el-col :span="24">
      <el-backtop target=".mod03" :visibility-height="100" :right="20" :bottom="70"></el-backtop>
      <ul v-infinite-scroll="handleLoad" infinite-scroll-disabled="disabled">
        <slot></slot>
      </ul>
      <p v-if="loading">加载中...</p>
      <p v-if="noMore">没有更多了</p>
    </el-col>
  </el-row>
</template>
<script>

export default {
  name: 'ListBox',
  props: {
    contentLength: 0
  },
  data: () => ({
    loading: false,
    finished: false,
    total: 0,
    length: 0,
    height: 0,
  }),
  watch: {
    total (val) {
      this.length = 0;
    },
    contentLength (val) {
      this.length = val;
    }
  },
  computed: {
    noMore () {
      return this.length >= this.total
    },
    disabled () {
      return this.loading || this.noMore
    },
  },
  mounted() {
  },
  methods:{
    handleLoad() {
      this.$emit('load');
    }
  }
}
</script>
<style scoped>

</style>
