import http from "./http";
import api from "../api/config";
import {Post, Get} from "./http";

export function request(apiRoute, param, method) {
    return http({
        url: apiRoute,
        method: method ? method : "post",
        data: param
    });
}

//登录
export function login(data) {
    return Post(api.login, data);
}

//修改手机号
export function usermodify(data) {
    return Post(api.usermodify, data);
}

//修改密码
export function changepasswd(data) {
    return Get(api.changepasswd, data);
}

// 首页
export function listMapDevice(data) {
    return Post(api.listMapDevice, data);
}

export function listBasicData(data) {
    return Post(api.listBasicData, data);
}

export function listBasicDataOfCustomer(data) {
    return Get(api.listBasicDataOfCustomer, data);
}

export function listBasicDataOfLocale(data) {
    return Get(api.listBasicDataOfLocale, data);
}

export function listDeviceLocale(data) {
    return Get(api.listDeviceLocale, data);
}

export function listMonitorDevice(data) {
    return Post(api.listMonitorDevice, data);
}

//实时分钟
export function listIntimeData(data) {
    return Post(api.listIntimeData, data);
}

export function listDataIntime(data) {
    return Post(api.listDataIntime, data);
}

export function listMultiDimQuery(data) {
    return Post(api.listMultiDimQuery, data);
}

//实时曲线
export function listTenMinData(data) {
    return Post(api.listTenMinData, data);
}

//报警管理
export function listCurAlarm(data) {
    return Post(api.listCurAlarm, data);
}

//清空所有报警
export function delAlarmRecord(data) {
    return Post(api.delAlarmRecord, data);
}

//异常处理记录
export function listAlarmRecord(data) {
    return Post(api.listAlarmRecord, data);
}

//报警处理
export function alarmProcess(data) {
    return Post(api.alarmProcess, data);
}

//异常处理
export function updateOfflineAlarmRecordProcessReason(data) {
    return Post(api.updateOfflineAlarmRecordProcessReason, data);
}

//在线达标率统计
export function listDataDetectorDailyStats(data) {
    return Post(api.listDataDetectorDailyStats, data);
}

//油烟减排统计
export function listDetectorDaily(data) {
    return Post(api.listDetectorDaily, data);
}

//油烟浓度
export function queryDataDetectorDaily(data) {
    return Post(api.queryDataDetectorDaily, data);
}

//消息通知
export function listAlarmMsg(data) {
    return Post(api.listAlarmMsg, data);
}

//维护类型
export function listMaintainOption(data) {
    return Get(api.listMaintainOption, data);
}

//运维单列表
export function listMaintenance(data) {
    return Post(api.listMaintenance, data);
}

//监测超标报警处理
export function updateMaintenance(data) {
    return Post(api.updateMaintenance, data);
}

//一点一档
export function noAuthListMapDevice(data) {
    return Post(api.noAuthListMapDevice, data);
}

export function noAuthQueryDataDetectorDaily(data) {
    return Post(api.noAuthQueryDataDetectorDaily, data);
}

export function listDataDetectorDailySumDaysByLocaleId(data) {
    return Post(api.listDataDetectorDailySumDaysByLocaleId, data);
}

export function listTenMinDataLastDays(data) {
    return Post(api.listTenMinDataLastDays, data);
}

export function uploadImages(file) {
    return Post(api.uploadImages, file);
}

export function listSimRecord(data) {
    return Post(api.listSimRecord, data);
}

export function saveSimRecord(data) {
    return Post(api.saveSimRecord, data);
}

export function listDeviceByMN(data) {
    return Post(api.listDeviceByMN, data);
}

export function deleteSimRecord(data) {
    return Get(api.deleteSimRecord, data);
}

export function listSubmitter(data) {
    return Get(api.listSubmitter, data);
}

//监测点信息采集表(提交/修改)
export function uploadLocaleInfo(data) {
    return Post(api.uploadLocaleInfo, data);
}

//查询监测点信息采集表
export function listLocaleRecords(data) {
    return Post(api.listLocaleRecords, data);
}

//新增信息勘察审核
export function saveCustomerDetail(data) {
    return Post(api.saveCustomerDetail, data);
}

//展示信息勘察记录
export function listCustomerDetail(data) {
    return Post(api.listCustomerDetail, data);
}

//获取地区数据
export function listBasicDataOfArea(data) {
    return Get(api.listBasicDataOfArea, data);
}

export function findDeviceOfLocale(data) {
    return Get(api.findDeviceOfLocale, data);
}

export function listAllCuisine() {
    return Get(api.listAllCuisine);
}

export function listApprovedBusiness() {
    return Get(api.listApprovedBusiness);
}

export function listCascadeReferParam(data) {
    return Get(api.listCascadeReferParam, data);
}

export function listMaintainer(data) {
    return Get(api.listMaintainer, data);
}

//红码核实提交端口
export function updateHealthCodeRecord(data) {
    return Post(api.updateHealthCodeRecord, data)
}
