<!--公共的组件-->
<template>
  <div>
    <div class="h1">
      <div>{{formatDate(dataObj.acquit_at)}}</div>
    </div>
    <el-row class="h2">
      <el-col :span="8">油烟实时浓度 <div>{{dataObj.emissions_conc}}</div></el-col>
      <el-col :span="8">净化器状态 <div>{{dataObj.filter_status | type}}</div></el-col>
      <el-col :span="8">风机状态 <div>{{dataObj.fan_status | type}}</div></el-col>
    </el-row>
  </div>
</template>

<script>
import { formatDate } from "../utils/utils";

export default {
  props: {
    dataObj: {
      type: Object,
      default: {}
    },
    linkStatus: {
      type: Number,
      default: undefined
    }
  },
  data () {
    return {
      msg: 'Welcome to Your Vue.js App',
      value: ''
    }
  },
  filters: {
    type(type) {
      const op = {
        0: '关',
        1: '开',
        2: '-'
      }
      return op[type]
    },
  },
  mounted() {
  },
  methods: {
    formatDate

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
