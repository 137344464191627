<!--综合管理上方的组件-->
<template>
  <div>
    <el-row class="mod01" v-if="mapType === 2">
      <el-col :span="24" class="box">
        <el-row>
          <el-col :span="8">监测点数</el-col>
          <el-col :span="8">昨日在线</el-col>
          <el-col :span="8">昨日在线占比</el-col>
        </el-row>
        <el-row>
          <el-col :span="8">{{newData.LocaleNum}}</el-col>
          <el-col :span="8">{{newData.Daily.Dnormal}}</el-col>
          <el-col :span="8">{{dTotal===0?0:((newData.Daily.Dnormal)/dTotal*100).toFixed(2)+'%'}}</el-col>
        </el-row>
      </el-col>
      <!--      下面是收起和展开的数据-->
      <el-col :span="24" class="box" v-if="slideUp">
        <el-row>
          <el-col :span="8">昨日离线</el-col>
          <el-col :span="8">昨日离线占比</el-col>
          <el-col :span="8">昨日油烟减排量</el-col>
        </el-row>
        <el-row>
          <el-col :span="8">{{dTotal-newData.Daily.Dnormal}}</el-col>
          <el-col :span="8">{{dTotal===0?0:((dTotal-newData.Daily.Dnormal)/dTotal*100).toFixed(2)+'%'}}</el-col>
          <el-col :span="8">{{daily.RedEmissions}}kg</el-col>
        </el-row>
        <el-row>
          <el-col :span="8">昨日达标</el-col>
          <el-col :span="8">达标率</el-col>
          <el-col :span="8">昨日PM2.5减排量</el-col>
        </el-row>
        <el-row>
          <el-col :span="8">{{(newData.Daily.Dnormal+dTotal-newData.Daily.Dnormal)-newData.Daily.Dexcess}}</el-col>
          <el-col :span="8">{{dTotal===0?0:(((newData.Daily.Dnormal+dTotal-newData.Daily.Dnormal-newData.Daily.Dexcess)/(newData.Daily.Dnormal+dTotal-newData.Daily.Dnormal)*100).toFixed(2)+'%')}}</el-col>
          <el-col :span="8">{{daily.Pm25}}kg</el-col>
        </el-row>
        <el-row>
          <el-col :span="8">昨日超标</el-col>
          <el-col :span="8">超标率</el-col>
          <el-col :span="8">昨日VOCS减排量</el-col>
        </el-row>
        <el-row>
          <el-col :span="8">{{newData.Daily.Dexcess}}</el-col>
          <el-col :span="8">{{dTotal===0?0:(daily.Dexcess/dTotal*100).toFixed(2)+'%'}}</el-col>
          <el-col :span="8">{{daily.RedVocs}}kg</el-col>
        </el-row>
        <!--
        <el-row>
          <el-col :span="8">监控设备</el-col>
          <el-col :span="8">昨日在线</el-col>
          <el-col :span="8">昨日离线</el-col>
        </el-row>
        <el-row>
          <el-col :span="8">{{mTotal}}</el-col>
          <el-col :span="8">{{daily.Mnormal}}</el-col>
          <el-col :span="8">{{mTotal-daily.Mnormal}}</el-col>
        </el-row>
        <el-row>
          <el-col :span="8">昨日在线占比</el-col>
          <el-col :span="8">昨日离线占比</el-col>
        </el-row>-->
        <!--        <el-row>-->
        <!--          <el-col :span="8">{{mTotal===0?0:(daily.Mnormal/mTotal*100).toFixed(2)+'%'}}</el-col>-->
        <!--          <el-col :span="8">{{mTotal===0?0:((mTotal-daily.Mnormal)/mTotal*100).toFixed(2)+'%'}}</el-col>-->
        <!--        </el-row>-->
      </el-col>
      <!--      点击按钮查看更多数据-->
      <el-col :span="24" class="mod01-bot">
        <!--        动态绑定class，展示文字和icon-->
        <el-button type="text" @click="handleClick">{{slideUp ? '收起' : '查看更多'}}<i :class="{ 'el-icon-bottom': !slideUp, 'el-icon-top':slideUp}"></i></el-button>
      </el-col>

    </el-row>
    <!--    监控仪的数据-->
    <el-row class="mod01" v-else>
      <el-col :span="24" class="box">
        <el-row>
          <el-col :span="8">监控设备</el-col>
          <el-col :span="8">昨日在线</el-col>
          <el-col :span="8">昨日离线</el-col>
        </el-row>
        <el-row>
          <el-col :span="8">{{mTotal}}</el-col>
          <el-col :span="8">{{daily.onlineCount}}</el-col>
          <el-col :span="8">{{daily.offCount}}</el-col>
        </el-row>
      </el-col>
      <el-col :span="24" class="box" v-if="slideUp">
        <el-row>
          <el-col :span="8">占比</el-col>
          <el-col :span="8">占比</el-col>
        </el-row>
        <el-row>
          <el-col :span="8">{{mTotal===0 ? '0.00' : (daily.onPercent*100).toFixed(2)}}%</el-col>
          <el-col :span="8">{{mTotal===0 ? '0.00' : (daily.offPercent*100).toFixed(2)}}%</el-col>
        </el-row>
      </el-col>
      <el-col :span="24" class="mod01-bot">
        <el-button type="text" @click="handleClick">{{slideUp ? '收起' : '查看更多'}}<i :class="{ 'el-icon-bottom': !slideUp, 'el-icon-top':slideUp}"></i></el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  props: {
    newData: {
      type: Object,
      default: {},
    },
    mapType: {
      type: Number,
      default: 2,
    },
    locale: {
      type: Array,
      default: [],
    },
    dTotal: {
      type: Number,
      default: null,
    },
    mTotal: {
      type: Number,
      default: null,
    },
    daily: {
      type: Object,
      default: {},
    },
  },
  data () {
    return {
      slideUp: false,
    }
  },
  mounted() {
  },
  methods: {
    handleClick() {
      this.slideUp = !this.slideUp;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
