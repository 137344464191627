<!--我的里面的个人资料-->
<template>
  <div class="bg01">
    <Head title="个人资料"></Head>
    <wv-group>
      <wv-cell title="昵称" :value="name" is-link></wv-cell>
    </wv-group>
  </div>
</template>

<script>
  import Head from '@/components/Head';

  export default {
    components: { Head },
    data () {
      return {
        msg: 'Welcome to Your Vue.js App',
        name: sessionStorage.getItem('name'),
      }
    },
    mounted() {},
    methods: {}
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .weui-cells{
    margin-top: 5px;
  }
</style>
