<!--我的-消息通知-->
<template>
  <div class="bg01">
    <Head title="消息通知"></Head>
    <list-box ref="listBox" @load="load">
      <li v-for="(v, i) in list" :key="i" @click="handleJump('/show', v)">
        <div>
          <div>{{v.Content}}</div>
          <div class="color999">{{formatDate(v.AcquitAt)}}</div>
        </div>
      </li>
    </list-box>
  </div>
</template>

<script>
import Head from '@/components/Head';
import ListBox from '@/components/ListBox';
import { handleJump, formatDate } from "../utils/utils";
import { listAlarmMsg } from "../utils/api";
import {
  messagePubSub
} from "@/service/pubsub/index";

export default {
  components: { Head, ListBox },
  data () {
    return {
      msg: 'Welcome to Your Vue.js App',
      param: {
        StartAt: 0,
        Size: 20,
        Param: {},
      },
      page: 1,
      list: [],
    }
  },
  mounted() {
    this.getList();
  },
  filters: {
    short(v) {
      return v.length > 12 ? v.substr(0, 12) + '...' : v
    },
  },
  methods: {
    formatDate,
    handleJump(url, data) {
      messagePubSub.publish(data);
      handleJump(url)
    },
    getList() {
      listAlarmMsg(this.param).then(res => {
        this.list = this.page === 1 ? res.content : this.list.concat(res.content);
        this.$refs.listBox.total = res.total;
      }).catch(err => {
        console.log(err)
      });
    },
    load() {
      this.$refs.listBox.loading = true;
      setTimeout(() => {
        this.page++;
        this.param.StartAt = this.page * this.param.Size;
        this.getList();
        this.$refs.listBox.loading = false
      }, 2000)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
