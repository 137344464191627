<!--数据统计-在线达标率统计-->
<template>
  <div class="bg01">
    <Head title="在线达标率统计"></Head>
    <el-row class="mod03 infinite-list-wrapper">
      <el-col :span="24">
<!--        返回到最上面  target是触发滚动的对象  滚动高度达到100才出现 right控制其显示位置 bottom控制其到底部的位置 -->
          <el-backtop target=".mod03" :visibility-height="100" :right="20" :bottom="70"></el-backtop>
        <ul>
          <li class="h1">
<!--            日期组件-->
            <el-date-picker
              v-model="StartAt"
              type="date"
              @change="handleStartAt"
              placeholder="选择日期">
            </el-date-picker>
            <div style="width: 40px; text-align: center;">至</div>
            <el-date-picker
              v-model="EndAt"
              type="date"
              value-format="yyyy-MM-dd"
              @change="handleEndAt"
              placeholder="选择日期">
            </el-date-picker>
          </li>
          <li v-for="(v, i) in list" :key="i">
            <div class="h1">
<!--              请求出来的是时间的日期-->
              <div>{{v.AcquitDate}}</div>
            </div>
            <el-row class="h2">
              <el-col :span="8">达标数</el-col>
              <el-col :span="8">达标比例</el-col>
              <el-col :span="8">超标数</el-col>
              <el-col :span="8">{{v.ComplianceCount}}</el-col>
              <el-col :span="8">{{v.CompliancePercent}}</el-col>
              <el-col :span="8">{{v.ExceedStandardCount}}</el-col>
              <el-col :span="24">
<!--              el-divider是显示的分割线  -->
                <el-divider></el-divider>
              </el-col>
              <el-col :span="8">超标比例</el-col>
              <el-col :span="8">在线数</el-col>
              <el-col :span="8">在线比例</el-col>
              <el-col :span="8">{{v.ExceedStandardPercent}}</el-col>
              <el-col :span="8">{{v.OnlineCount}}</el-col>
              <el-col :span="8">{{v.OnlinePercent}}</el-col>
            </el-row>
          </li>
        </ul>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Head from '@/components/Head';
import { listDataDetectorDailyStats } from "../utils/api";
import { formatDate } from "../utils/utils";

export default {
  components: { Head },
  data () {
    return {
      msg: 'Welcome to Your Vue.js App',
      StartAt: '',
      EndAt: '',
      param: {
        StartAt: 0,
        Size: 10,
        Typ: 1,
        WithAreaLocaleReport: false,
        AcquitDateBegin: '',
        AcquitDateEnd: '',
      },
      list: [],
    }
  },
  mounted() {
    this.getDate();
  },
  methods: {
    formatDate,
    getDate() {
      let today = new Date();//当前的日期
      today.setDate(today.getDate() - 1);//给当前的日期设置减一天
      let EndAt = today.getTime();//设置结束的时期等于today 格式:2024-04-15
                                                                              // slice截取索引（star，end（不包含））
      this.EndAt = formatDate(EndAt / 1000, undefined, undefined, '-').slice(0, 10);//处理EndAt的时间的格式
      this.param.AcquitDateEnd = this.EndAt;//this.param.AcquitDateEnd设置结束的时间
      //给当前日期在减30天
      today.setDate(today.getDate() - 30);//相当于减了31天
      let StartAt = today.getTime();//设置开始的时间
      this.param.AcquitDateBegin = formatDate(StartAt / 1000, undefined, undefined, '-').slice(0, 10);
      this.StartAt = this.param.AcquitDateBegin;
      this.getList();
    },
    getList() {
      listDataDetectorDailyStats(this.param).then(res => {
        this.list = res.sumGroupByDates;//sumGroupByDates数组名称
      }).catch(err => {
        console.log(err)
      });
    },
    handleStartAt() {//每当时间控件改变时，都调用这个handleStartAt方法设置请求的开始时间
      this.param.AcquitDateBegin = this.StartAt;
      this.getList();
    },
    handleEndAt() {
      this.param.Param.endAt = this.EndAt;
      this.getList();
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .mod03{
    height: calc(100vh - 48px);
  }
</style>
