<template>
  <div>
    <div id="myChart" :style="{width: '100%', height: '80vh'}" v-if="!isWidth"></div>
    <div id="myChartTwo" :style="{width: '100%', height: '80vh'}" v-if="isWidth"></div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      isWidth: false,
    }
  },
  mounted() {
    // window.addEventListener("resize", this.renderResize, false);
  },
  beforeDestroy () {
    // window.removeEventListener("resize", this.renderResize, false);
  },
  watch: {
    isWidth (v) {
      if (!v) location.reload();
    }
  },
  methods: {
    drawLine(arr, arr02, arr03, arr04, arr05, arr06, arr07){
      // /console.log('drawLine', arr, arr02, arr03, arr04, arr05, arr06, arr07);
      let myChart = this.$echarts.init(document.getElementById('myChart'));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['油烟浓度', '颗粒物', '非甲烷总烃']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'value',
          name: '油烟浓度',
        },
          {
            type: 'value',
            name: '油烟平均',
          }],
        yAxis: {
          type: 'category',
          data: arr02,
        },
        series: [
          {
            name: '油烟浓度',
            type: 'line',
            data: arr,
            itemStyle: {
              normal: {
                color: '#00A1FF',
                lineStyle: {
                  type: 'solid',
                  color: '#00A1FF'
                }
              }
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#00A1FF",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  xAxis: arr03,
                },
              ],
            },
          },
          {
            name: '颗粒物',
            type: 'line',
            data: arr04,
            itemStyle: {
              normal: {
                color: '#F5B049',
                lineStyle: {
                  type: 'solid',
                  color: '#F5B049'
                }
              }
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#F5B049",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  xAxis: arr05,
                },
              ],
            },
          },
          {
            name: '非甲烷总烃',
            type: 'line',
            data: arr06,
            itemStyle: {
              normal: {
                color: '#009999',
                lineStyle: {
                  type: 'solid',
                  color: '#009999'
                }
              }
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#009999",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  xAxis: arr07,
                },
              ],
            },
          },
        ]
      });
    },
    renderResize(arr, arr02, arr03, arr04, arr05, arr06, arr07) {
      let width = document.documentElement.clientWidth;
      let height = document.documentElement.clientHeight;
      this.isWidth = width > height;
      console.log(this.isWidth);
      if (this.isWidth) {
        this.drawLineWidth(arr, arr02, arr03, arr04, arr05, arr06, arr07)
      } else {
        this.drawLine(arr, arr02, arr03, arr04, arr05, arr06, arr07);
      }
    },
    drawLineWidth(arr, arr02, arr03, arr04, arr05, arr06, arr07) {
      let myChart = this.$echarts.init(document.getElementById('myChartTwo'));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['油烟浓度', '颗粒物', '非甲烷总烃']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        yAxis: [{
          type: 'value',
          name: '油烟浓度',
        },
          {
            type: 'value',
            name: '油烟平均',
          }],
        xAxis: {
          type: 'category',
          data: arr02,
        },
        series: [
          {
            name: '油烟浓度',
            type: 'line',
            data: arr,
            itemStyle: {
              normal: {
                color: '#00A1FF',
                lineStyle: {
                  type: 'solid',
                  color: '#00A1FF'
                }
              }
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#00A1FF",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  xAxis: arr03,
                },
              ],
            },
          },
          {
            name: '颗粒物',
            type: 'line',
            data: arr04,
            itemStyle: {
              normal: {
                color: '#F5B049',
                lineStyle: {
                  type: 'solid',
                  color: '#F5B049'
                }
              }
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#F5B049",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  xAxis: arr05,
                },
              ],
            },
          },
          {
            name: '非甲烷总烃',
            type: 'line',
            data: arr06,
            itemStyle: {
              normal: {
                color: '#009999',
                lineStyle: {
                  type: 'solid',
                  color: '#009999'
                }
              }
            },
            markLine: {
              symbol: "none",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: "solid",
                    color: "#009999",
                  },
                  label: {
                    position: "start",
                    formatter: "{c} ",
                  },
                },
              },
              data: [
                {
                  name: "阈值",
                  xAxis: arr07,
                },
              ],
            },
          },
        ]
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .mod03{
    height: calc(80vh - 48px);
  }
</style>
