<!--实时监测-分钟-->
<template>
  <div class="bg01">
    <list-box ref="listBox" @load="load">
      <li>
        <div class="h3">时间：{{formatDate(titleDate)}}</div>
      </li>
      <li v-for="(v, i) in list" :key="i">
        <realTimeMinute-card :linkStatus="linkStatus" :dataObj="v"></realTimeMinute-card>
      </li>
    </list-box>
  </div>
</template>

<script>
import Head from '@/components/Head';
import ListBox from '@/components/ListBox';
import RealTimeMinuteCard from '@/components/RealTimeMinuteCard';
import { listIntimeData } from "../utils/api";
import { formatDate } from "../utils/utils";
import { minutePubSub } from "@/service/pubsub/index";

export default {
  components: {
    Head,
    ListBox,
    RealTimeMinuteCard,
  },
  data () {
    return {
      msg: 'Welcome to Your Vue.js App',
      param: {
        StartAt: 0,
        Size: 10,
        typ: 2,
        at: 0,
        Param: {}
      },
      page: 1,
      list: [],
      linkStatus: 0,
      titleDate: 0,
      isDialog: false,
      StartAt: 0,
    }
  },
  mounted() {
    this.$nextTick(()=>{
      minutePubSub.unsubscribe();
      minutePubSub.subscribe(this.getList);
    });
  },
  methods: {
    formatDate,
    getList(obj) {
      if (obj) {
        this.linkStatus = obj.linkStatus;
        this.param.mn = obj.mn;
        this.param.Size = 4;
        this.isDialog = obj.showDate;
      }
      listIntimeData(this.param).then(res => {
        this.list = this.page === 1 ? res.content : this.list.concat(res.content);
        this.$refs.listBox.total = res.total;
        this.titleDate = res.content[0]['acquit_at'];
        this.StartAt = this.formatDate(this.titleDate)
      }).catch(err => {
        console.log(err)
      });
    },
    load () {
      this.$refs.listBox.loading = true;
      setTimeout(() => {
        this.page++;
        this.param.StartAt = this.page * this.param.Size;
        this.getList();
        this.$refs.listBox.loading = false;
      }, 2000)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .mod03{
    height: calc(100vh - 48px);
  }
</style>
