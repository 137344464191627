import axios from "axios";

// let baseURL = process.env.NODE_ENV === 'development' ? '/api/' : '';
let baseURL = 'https://m.yhtianmu.cn/prod-api'
let uploadApi =  'http://116.62.234.187:8088'
// let baseURL = 'http://116.62.234.187:8088'
// let baseURL = "http://116.62.234.187:8080"; // 测试
// let baseURL = 'http://183.134.220.241:1180' // 正式 临平

const service = axios.create({
  baseURL: baseURL,
  timeout: 50000 // 请求超时时间
});
service.interceptors.request.use(
  config => {
    let authorization = sessionStorage.getItem("token");
    authorization && (config.headers.Auth = authorization);
    return config;
  },
  err => {
    return Promise.error(err);
  }
);


service.interceptors.response.use(
  function(response) {
    if (
      response.data.Status === 200 ||
      response.data.Status === 201 ||
      response.data.Status === 202 ||
      response.data.Status === 205
    ) {
      return Promise.resolve(response.data.Data);
    } else {
      return Promise.reject(response);
    }
  },
  function(error) {
    return Promise.reject(error);
  }
);

export function Post(url, data) {
  return new Promise((resolve, reject) => {
      if (url.startsWith(uploadApi)){
          service.defaults.baseURL = ''
      }else{
          service.defaults.baseURL = baseURL
      }
    service({
      url: url,
        method: "post",
      data: data,
    }).then(
      response => {
        resolve(response);
      },
      err => {
        reject(err);
      }
    );
  });
}

export function Get(url, data) {
  return new Promise((resolve, reject) => {
      if (url.startsWith(uploadApi)){
          service.defaults.baseURL = ''
      }else{
          service.defaults.baseURL = baseURL
      }
    service({
      url: url,
      method: "get",
      params: data
    }).then(
      response => {
        resolve(response);
      },
      err => {
        reject(err);
      }
    );
  });
}

export default service;
