<!--整个页面的头部区域-->
<template>
  <div>
    <ul class="head01">
      <li>
<!--     v-if="!notShowLeft"展示或隐藏这个回退按钮存不存在  默认是存在的    -->
        <el-button type="text" icon="el-icon-arrow-left" v-if="!notShowLeft" @click="handleBack"></el-button>
        <el-select placeholder="请选择" v-model="value" @change="handleChange" v-if="showLeftArr">
          <el-option
            v-for="item in showLeftArr"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </li>
      <li style="flex: 1">{{title}}</li>
      <li></li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['title', 'notShowLeft', 'showLeftArr'],
  data () {
    return {
      msg: 'Welcome to Your Vue.js App',
      value: ''
    }
  },
  mounted() {
    this.value = this.showLeftArr ? this.showLeftArr[0].value : '';
  },
  methods: {
    //路由回退到上一个
    handleBack() {
      this.$router.go(-1);
      // 2024/8/29加上这个代码是因为刷新监测点信息采集界面时数据会丢失，所以我暂时存sessionStorage里面
      sessionStorage.removeItem('type');
      sessionStorage.removeItem('formData');
    },
    handleChange(v) {
      this.$emit('handleHead',v)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
