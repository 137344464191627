import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// import ElementUI from 'ELEMENT';
// import 'element-ui/lib/theme-chalk/index.css';
import WeVue from 'we-vue';
import 'we-vue/lib/style.css'
import '@/assets/css/style.css'
// import { Popup, Image, Uploader } from 'vant'
// Vue.use(ElementUI);
// import Vconsole from 'vconsole'
// // 所有环境均使用
// new Vconsole()
// Vue.use(Popup)
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;

Vue.$cookies.config('1d');

import {
    Button,
    DropdownMenu,
    DropdownItem,
    Popup,
    Col,
    Row,
    Cell,
    CellGroup,
    Picker,
    Calendar,
    Cascader,
    Form,
    Field,
    RadioGroup,
    Radio,
    Uploader,
    Toast,
    Notify,
    Image as VanImage,
    Icon,
    ImagePreview
} from 'vant';
// 全局注册
Vue.use(ImagePreview);
Vue.use(VanImage);
Vue.use(Icon);
Vue.use(Notify);
Vue.use(Toast);
Vue.use(Uploader);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Form);
Vue.use(Field);
Vue.use(Cascader);
Vue.use(Picker);
Vue.use(Calendar);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Col);
Vue.use(Row);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Button)
Vue.use(Popup)

// 路由拦截器
// router.beforeEach((to, from, next) => {
//   if (Vue.$cookies.get('token')) {
//     if (to.path === '/board') {
//       if (stg().getItem('type') == 1) {
//         next()
//       } else {
//         next({
//           path: '/board_district',
//         })
//       }
//     } else {
//       next()
//     }
//   } else {
//     if (conf.whiteList.includes(to.path)) {
//       next()
//     } else {
//       next(`/login`)
//     }
//   }
// })

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
