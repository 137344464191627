export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dialogVisible: function() {
      return this.showDialog;
    },
  },
  data() {
    return {
    }
  },
  methods:{
    handleClose(){
      this.$emit('update:showDialog',false)
    },
  }
};
