<!--登录界面-->
<template>
  <div class="bg01">
<!--  :notShowLeft="true"  默认是false都展示回退按钮  -->
    <Head title="蓝天管家" :notShowLeft="true"></Head>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="form01">
      <el-form-item>
        <img src="../assets/images/logo.png" alt="">
      </el-form-item>
      <el-form-item prop="username">
        <el-input v-model="ruleForm.username" placeholder="账号"></el-input>
      </el-form-item>
      <el-form-item prop="password">
<!--      show-password设置密码后面有个小眼睛，点击即可展示密码  -->
        <el-input v-model="ruleForm.password" placeholder="密码" show-password></el-input>
      </el-form-item>
      <el-form-item>
<!--                                                       点击事件拿到ruleForm表单的数据-->
        <el-button class="btn01" type="primary" @click="submitForm('ruleForm')">登录</el-button>
        <el-button class="btn02" type="text">忘记密码</el-button>
        <!--        <span>v1.0</span>-->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Head from '@/components/Head';
import {handleJump, lGetItem, lSetItem} from "../utils/utils";
import {login} from "../utils/api";

export default {
  components: {Head},
  data() {
    return {
      msg: 'Welcome to Your Vue.js App',
      ruleForm: {
        username: '',//用户名
        password: '',//密码
      },
      //设置规则required: true, 必填  message: '账号',提示信息  trigger: 'blur'触发器失去焦点
      rules: {
        username: [
          {required: true, message: '账号', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '密码', trigger: 'blur'}
        ],
      },
    }
  },
  //挂载完成的请求
  mounted() {
    //请求后存在浏览器本地
    if (this.$cookies.get('login') && this.$cookies.get('token')) {
      sessionStorage.setItem('token', this.$cookies.get('token'))
      sessionStorage.setItem('login', JSON.stringify(this.$cookies.get('login')))
      // this.handleJump('/index');

      //存到本地后跳转路由
      this.handleJump('/realTime/{"type":0}');
    }
  },
  methods: {
    handleJump,
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          login(this.ruleForm).then(res => {
            //                     键token  值res.Token 对
            sessionStorage.setItem('token', res.Token);
            sessionStorage.setItem('login', JSON.stringify(this.ruleForm))
            if (res.Info){
              this.$store.commit('setMaintainerId',res.Info.maintainer_id)
            }
            sessionStorage.setItem('info', JSON.stringify(res.Info))
            //使用this.$cookies.set将Token和登录表单数据同样保存在浏览器cookie中，有效期为3天
            this.$cookies.set('token', res.Token, '3d');
            this.$cookies.set('login', JSON.stringify(this.ruleForm), '3d')
            // this.handleJump('/index');
            //跳转到实时监测的页面
            this.handleJump('/realTime/{"type":0}');
          }).catch(err => {
            console.error(err)
            this.$message.error(err.data.Msg)
          });
        } else {
          this.$message.error('请输入账号/密码');
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
